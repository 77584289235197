import { ModelBase } from "../../../../gyzmo-commons/models/base/model.base";
import { Attachment } from "../../attachment.model";

export class Damage extends ModelBase {
    static TABLENAME = "Damages_V2";

    public id: string = "";
    public inspectionId: string = "";
    public zoneId: string = "";
    public elementId: string = "";
    public value: string = "";
    public clientResponsibility: string;
    public xAxis: number;
    public yAxis: number;
    public rowNumber: string = "";
    public updateDate: string = "";

    public attachments: Attachment[] = [];

    // internal
    public isOld: number;
    public isDeactivate: number;

    constructor() {
        super(Damage.TABLENAME);
    }
}
