import { Injectable } from "@angular/core";
import { DateTime } from "luxon";
import { DATE_NODEJS_FORMAT } from "../../gyzmo-commons/interfaces/constants";
import { BestDealDto } from "../dto/bestDeal.dto";
import { HttpErrorHandler } from "../http/httpErrorHandler";
import { ServerConnection } from "../http/serverConnection";

@Injectable({
    providedIn: "root",
})
export class BestDealWs {
    static WS = "pricings/best-deal";

    constructor(
        private httpErrorHandler: HttpErrorHandler) {
    }

    public list(serverConnection: ServerConnection, startDate: DateTime, returnDate: DateTime, kilometers: number, category: string): Promise<BestDealDto> {
        return new Promise<BestDealDto>((resolve) => {
            let tokens = new Map<string, string>();
            tokens.set("startDate", startDate.toFormat(DATE_NODEJS_FORMAT));
            tokens.set("returnDate", returnDate.toFormat(DATE_NODEJS_FORMAT));
            tokens.set("kilometers", "" + kilometers);
            tokens.set("category", category);

            serverConnection.get(this.constructor.name, BestDealWs.WS + "?startDate=:startDate&returnDate=:returnDate&kilometers=:kilometers&category=:category", tokens)
                .then(response => {
                    resolve(BestDealDto.fromBody(response.body, serverConnection.getServerDto()));
                })
                .catch(reason => {
                    if (!this.httpErrorHandler.handleError(reason)) {
                        resolve(null);
                    }
                });
        });
    }
}
