import { Injectable } from "@angular/core";
import { Logger } from "ionic-logging-service";
import { DateTime } from "luxon";
import { isNullOrEmpty } from "../../gyzmo-commons/helpers/null.helper";
import { DateProvider } from "../../gyzmo-commons/interfaces/dateProvider";
import { CacheService } from "../../gyzmo-commons/services/cache.service";
import { AttachmentDbDao } from "../dao/db/attachment.db.dao";
import { AttachmentWsDao } from "../dao/ws/attachment.ws.dao";
import { AttachmentDto } from "../dto/attachment.dto";
import { AttachmentKinds } from "../interfaces/attachmentKinds";
import { Attachment } from "../models/attachment.model";
import { ServersConnectionsProvider } from "../providers/serversConnections.provider";

@Injectable({
    providedIn: "root",
})
export class AttachmentService {
    constructor(private attachmentWsDao: AttachmentWsDao,
                private attachmentDbDao: AttachmentDbDao,
                private logger: Logger,
                private dateProvider: DateProvider,
                private cacheService: CacheService,
                private serversConnectionsProvider: ServersConnectionsProvider) {
    }

    public async save(attachment: AttachmentDto): Promise<AttachmentDto> {
        let updatedAttachment: AttachmentDto = await this.attachmentWsDao.save(this.serversConnectionsProvider.getServerConnection(), attachment);
        await this.attachmentDbDao.save(updatedAttachment.toModel());
        return updatedAttachment;
    }

    public getByFaceId(id: any) {
        return this.attachmentWsDao.getByFaceId(this.serversConnectionsProvider.getServerConnection(), id);
    }

    public getUpToDateAttachmentsForThirdParty(thirdPartyId: string): Promise<AttachmentDto[]> {
        return new Promise<AttachmentDto[]>((resolve, reject) => {
            this.synchronizeAttachmentsForThirdParty(thirdPartyId)
                .then(() => {
                    this.attachmentDbDao.getByObjectAndKey("thirdParty", thirdPartyId)
                        .then(attachments => {
                            let results: AttachmentDto[] = [];

                            attachments.forEach(attachment => {
                                results.push(AttachmentDto.fromModel(attachment));
                            });

                            resolve(results);
                        });
                })
                .catch(reason => {
                        resolve([]);
                    },
                );
        });
    }

    public getByDamageId(damageId: string, attachmentKind?: AttachmentKinds): Promise<AttachmentDto[]> {
        return this.attachmentWsDao.getByDamageId(this.serversConnectionsProvider.getServerConnection(), damageId, attachmentKind);
    }

    public getByCompanyId(companyId: string, attachmentKind?: AttachmentKinds): Promise<AttachmentDto[]> {
        return this.attachmentWsDao.getByCompanyId(this.serversConnectionsProvider.getServerConnection(), companyId, attachmentKind);
    }

    private synchronizeAttachmentsForThirdParty(thirdPartyId: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            let promises = [];
            let remoteAttachments: AttachmentDto[] = [];
            let localAttachments: Attachment[] = [];

            promises.push(this.attachmentWsDao.getByThirdPartyId(this.serversConnectionsProvider.getServerConnection(),
                thirdPartyId)
                .then(value => {
                    remoteAttachments = value;
                }));
            promises.push(this.attachmentDbDao.getByObjectAndKey("thirdParty", thirdPartyId)
                .then(value => {
                    localAttachments = value;
                }));

            Promise.all(promises)
                .then(() => {
                    let updatePromises = [];

                    remoteAttachments.forEach(remoteAttachment => {
                        let correspondingLocalAttachment = localAttachments.find(value => {
                            return value.id == remoteAttachment.id;
                        });

                        if (correspondingLocalAttachment) {
                            if (DateTime.fromISO(correspondingLocalAttachment.date) != remoteAttachment.date) {
                                updatePromises.push(this.attachmentWsDao.getById(this.serversConnectionsProvider.getServerConnection(),
                                    remoteAttachment.id,
                                    "thirdParty",
                                    thirdPartyId));
                            }
                        } else {
                            updatePromises.push(this.attachmentWsDao.getById(this.serversConnectionsProvider.getServerConnection(),
                                remoteAttachment.id,
                                "thirdParty",
                                thirdPartyId));
                        }
                    });

                    Promise.all(updatePromises)
                        .then(updatedDateAttachments => {
                            let savePromises = [];

                            updatedDateAttachments.forEach((updatedDateAttachment: Attachment) => {
                                savePromises.push(this.attachmentDbDao.save(updatedDateAttachment));
                            });

                            Promise.all(savePromises)
                                .then(() => {
                                    resolve();
                                });
                        });
                })
                .catch(reason => {
                        reject();
                    },
                );
        });
    }

    public async getCGL(companyId: string): Promise<string> {
        let key = "CGL_" + companyId;
        if (await this.cacheService.isCached(key)) {
            let cache = await this.cacheService.getCached(key);
            return cache.value;
        } else {
            let attachments = await this.attachmentWsDao.getByCompanyId(this.serversConnectionsProvider.getPrimaryServerConnection(), companyId, AttachmentKinds.CGU);

            if (attachments.length > 0) {
                try {
                    let content = "";

                    if (!isNullOrEmpty(attachments[0].file)) {
                        content = attachments[0].file;
                    } else if (attachments[0].attachedDocuments.length > 0) {
                        content = attachments[0].attachedDocuments[0].file;
                    }

                    await this.cacheService.cache(key, content, this.dateProvider.now().plus({ years: 10 }));

                    return content;
                } catch (e) {
                    this.logger.error(this.constructor.name, e);
                    return "";
                }
            } else {
                return "";
            }
        }
    }
}
