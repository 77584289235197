import { Injectable } from "@angular/core";
import { GeneralPhotoTypeDto } from "../../dto/generalPhotoType.dto";
import { ServerConnection } from "../../http/serverConnection";
import { WsDao } from "../../http/wsDao";

@Injectable({
    providedIn: "root",
})
export class GeneralPhotoTypeWsDao extends WsDao<GeneralPhotoTypeDto> {
    static WS = "technical/ft";

    public getById(serverConnection: ServerConnection, id: string): Promise<GeneralPhotoTypeDto> {
        throw new Error("Not implemented !");
    }

    public save(serverConnection: ServerConnection, inspection: GeneralPhotoTypeDto): Promise<GeneralPhotoTypeDto> {
        throw new Error("Not implemented !");
    }

    public getList(serverConnection: ServerConnection): Promise<GeneralPhotoTypeDto[]> {
        return new Promise<GeneralPhotoTypeDto[]>((resolve, reject) => {
            let tokens = new Map<string, string>();

            serverConnection.get(this.constructor.name, GeneralPhotoTypeWsDao.WS + "?_limit=NOLIMIT&object=attachmentKind&filter=PHOTO&active=true", tokens)
                .then(response => {
                    if (response.status == 204) {
                        resolve([]);
                    } else {
                        let result = [];
                        (response.body as Array<any>).forEach(value => {
                            let generalPhotoTypeDto = GeneralPhotoTypeDto.fromBody(value);
                            result.push(generalPhotoTypeDto);
                        });
                        resolve(result);
                    }
                })
                .catch(reason => {
                    reject(reason);
                });
        });
    }
}
