import { Component, Input } from "@angular/core";
import { EquipmentDto } from "../../../core/dto/equipment.dto";

@Component({
    selector: "vehicle-details-equipments",
    templateUrl: "vehicle-details-equipments.html",
    styleUrls: ["vehicle-details-equipments.scss"],
})
export class VehicleDetailsEquipmentsComponent {
    @Input() equipment: EquipmentDto;
}
