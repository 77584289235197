import { ModelBase } from "../../gyzmo-commons/models/base/model.base";
import { StcStatuses } from "../dto/stcStatuses";
import { Location } from "./location.model";
import { Movement } from "./movement.model";
import { ThirdParty } from "./thirdParty.model";

export class Stc extends ModelBase {
    static TABLENAME = "ShortTermContracts";

    id: string = "";
    startDate: string = "";
    plannedReturnDate: string = "";
    returnDate: string = "";
    returnMileage: number = 0;
    wantedMileage: number = 0;
    customerInvoice: { excludingTaxAmount: number, includingTaxAmount: number, vatAmount: number, } = { excludingTaxAmount: 0, includingTaxAmount: 0, vatAmount: 0 };
    contractNumber: string = "";
    bookingCode: string = "";

    customer: ThirdParty;
    driver: ThirdParty;
    status: StcStatuses = StcStatuses.CANCELLED;
    startLocation: Location;
    plannedReturnLocation: Location;
    returnLocation: Location;
    isClosed: number = 0;
    deposit: number = 0;

    pricing: any = {};
    pricingMethod: any = {};
    movement: Movement;
    category: any = {};

    constructor() {
        super(Stc.TABLENAME);

        this.customer = new ThirdParty();
        this.driver = new ThirdParty();
        this.startLocation = new Location();
        this.plannedReturnLocation = new Location();
        this.returnLocation = new Location();
        this.movement = new Movement();
    }
}
