import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { DateHelper } from "../../gyzmo-commons/helpers/date.helper";
import { DATE_NODEJS_FORMAT } from "../../gyzmo-commons/interfaces/constants";
import { StcDbDao } from "../dao/db/stc.db.dao";
import { StcWsDao } from "../dao/ws/stc.ws.dao";
import { StcDto } from "../dto/stc.dto";
import { StcStatuses } from "../dto/stcStatuses";
import { Stc } from "../models/stc.model";
import { ServersConnectionsProvider } from "../providers/serversConnections.provider";

@Injectable({
    providedIn: "root",
})
export class StcService {
    private stcsMockCache = new Map<string, StcDto>();

    constructor(private stcDbDao: StcDbDao,
                private stcWsDao: StcWsDao,
                private serversConnectionsProvider: ServersConnectionsProvider) {
    }

    public save(stc: StcDto): Promise<StcDto> {
        return new Promise<StcDto>((resolve, reject) => {
            this.stcWsDao.save(this.serversConnectionsProvider.getServerConnection(), stc)
                .then((updatedStcDto: StcDto) => {
                    this.stcDbDao.save(updatedStcDto.toModel())
                        .then(id => {
                            resolve(updatedStcDto);
                        });
                })
                .catch(reason => {
                        reject(reason);
                    },
                );
        });
    }

    public getStcsByThirdParty(thirdPartyId: string, hydrate: boolean = false): Promise<StcDto[]> {
        return new Promise<StcDto[]>((resolve, reject) => {
            this.synchronizeStcs(thirdPartyId)
                .then(() => {
                    this.stcDbDao.getAll(hydrate)
                        .then(stcs => {
                            let filteredStcs = stcs.filter(stc => {
                                return stc.status != StcStatuses.CANCELLED;
                            });

                            filteredStcs = filteredStcs.sort((a, b) => {
                                // reverse order
                                let dateA = DateHelper.tryFromFormat(a.returnDate, DATE_NODEJS_FORMAT);
                                let dateB = DateHelper.tryFromFormat(b.returnDate, DATE_NODEJS_FORMAT);

                                if (dateA < dateB) {
                                    return 1;
                                }
                                if (dateA > dateB) {
                                    return -1;
                                }

                                return 0;
                            });

                            let result = [];

                            filteredStcs.forEach(filteredStc => {
                                let stcDto = StcDto.fromModel(filteredStc);
                                stcDto = this.updateMockCache(stcDto);

                                result.push(stcDto);
                            });

                            resolve(result);
                        });
                })
                .catch(reason => {
                        reject();
                    },
                );
        });
    }

    public async getByIdOnline(stcDto: StcDto): Promise<StcDto> {
        let stc = await this.stcWsDao.getById(this.serversConnectionsProvider.getServerConnection(), stcDto.id);
        return this.updateMockCache(stc);
    }

    public synchronizeLocalStc(stc: StcDto): Promise<StcDto> {
        // Resynchroniser le stc
        return this.getByIdOnline(stc)
            .then(remoteStc => {
                return this.stcDbDao.save(remoteStc.toModel())
                    .then(updatedStc => {
                        return StcDto.fromModel(updatedStc);
                    });
            });
    }

    private synchronizeStcs(thirdPartyId: string): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            let remoteStcs: StcDto[] = await this.stcWsDao.getListByThirdPartyId(this.serversConnectionsProvider.getServerConnection(), thirdPartyId);
            let localStcs: Stc[] = await this.stcDbDao.getAll();

            let updatePromises = [];
            remoteStcs.forEach(remoteStc => {
                let localStc = localStcs.find(value => {
                    return value.id == remoteStc.id;
                });

                if ((!localStc && remoteStc.status != StcStatuses.CANCELLED)
                    || (localStc && localStc.status != remoteStc.status)) {
                    updatePromises.push(this.getByIdOnline(remoteStc)
                        .then(value => {
                            let stc = value.toModel();
                            return this.stcDbDao.save(stc);
                        }));
                }
            });

            Promise.all(updatePromises)
                .then(() => {
                    resolve();
                });
        });
    }

    private updateMockCache(stcDto: StcDto): StcDto {
        if (!environment.testConfig) {
            return stcDto;
        }

        if (this.stcsMockCache.has(stcDto.id)) {
            return this.stcsMockCache.get(stcDto.id);
        }

        return stcDto;
    }

    public saveToMockCache(stcDto: StcDto) {
        this.stcsMockCache.set(stcDto.id, stcDto);
    }

    public clearMockCache() {
        this.stcsMockCache = new Map<string, StcDto>();
    }
}
