import { Injectable } from "@angular/core";
import { EquipmentWsDao } from "../dao/ws/equipment.ws.dao";
import { EquipmentDto } from "../dto/equipment.dto";
import { ServersConnectionsProvider } from "../providers/serversConnections.provider";

@Injectable({
    providedIn: "root",
})
export class EquipmentService {
    constructor(private equipmentWsDao: EquipmentWsDao,
                private serversConnectionsProvider: ServersConnectionsProvider) {
    }

    public getById(equipmentDto: EquipmentDto, exitBeforeAttachments = false) {
        return this.equipmentWsDao.getById(this.serversConnectionsProvider.getServerConnection(), equipmentDto.id, exitBeforeAttachments);
    }

    public getUpdatedMileage(equipmentDto: EquipmentDto) {
        return this.equipmentWsDao.getUpdatedMileage(this.serversConnectionsProvider.getServerConnection(), equipmentDto.id);
    }
}
