import { HttpErrorResponse } from "@angular/common/http";
import { HttpClientProvider } from "../../gyzmo-commons/providers/httpClientProvider";

export class DirectConnectionHttpClient {
    public constructor(private httpClientProvider: HttpClientProvider) {
    }

    public static replaceTokens(tokens: { accessToken: string, refreshToken: string }, headers: any): any {
        let newHeaders = headers;
        newHeaders["Authorization"] = "Bearer " + tokens.accessToken;

        return newHeaders;
    }

    public get(url: string, headers: any): Promise<boolean> {
        return this.httpClientProvider.getHttpClient().get(url, headers)
            .then(httpResponse => {
                return true;
            })
            .catch((reason: HttpErrorResponse) => {
                if (reason.status == 409) {
                    return true;
                } else {
                    return false;
                }
            });
    }

    public post(url: string, headers: any, data: any): Promise<boolean> {
        return this.httpClientProvider.getHttpClient().post(url, headers, data)
            .then(httpResponse => {
                return true;
            })
            .catch((reason: HttpErrorResponse) => {
                if (reason.status == 409) {
                    return true;
                } else {
                    return false;
                }
            });
    }

    public put(url: string, headers: any, data: any): Promise<boolean> {
        return this.httpClientProvider.getHttpClient().put(url, headers, data)
            .then(httpResponse => {
                return true;
            })
            .catch((reason: HttpErrorResponse) => {
                if (reason.status == 409) {
                    return true;
                } else {
                    return false;
                }
            });
    }

    public delete(url: string, headers: any): Promise<boolean> {
        return this.httpClientProvider.getHttpClient().delete(url, headers)
            .then(httpResponse => {
                return true;
            })
            .catch((reason: HttpErrorResponse) => {
                if (reason.status == 409) {
                    return true;
                } else {
                    return false;
                }
            });
    }
}
