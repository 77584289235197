import { Injectable } from "@angular/core";
import { ContactDetailsDbDao } from "../dao/db/contactDetails.db.dao";
import { ContactDetailsWsDao } from "../dao/ws/contactDetails.ws.dao";
import { ContactDetailDto } from "../dto/contactDetail.dto";
import { ServersConnectionsProvider } from "../providers/serversConnections.provider";

@Injectable({
    providedIn: "root",
})
export class ContactDetailsService {
    constructor(private contactDetailsWsDao: ContactDetailsWsDao,
                private contactDetailsDbDao: ContactDetailsDbDao,
                private serversConnectionsProvider: ServersConnectionsProvider) {
    }

    public async save(contactDetail: ContactDetailDto): Promise<ContactDetailDto> {
        let updatedDto = await this.contactDetailsWsDao.save(this.serversConnectionsProvider.getServerConnection(), contactDetail);
        await this.contactDetailsDbDao.save(updatedDto.toModel());

        return updatedDto;
    }
}
