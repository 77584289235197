import { Injectable } from "@angular/core";
import { Logger } from "ionic-logging-service";
import { isNullOrEmpty } from "../../../gyzmo-commons/helpers/null.helper";
import { XVEGAID } from "../../../gyzmo-commons/http/header.constant";
import { HttpResponse } from "../../../gyzmo-commons/http/httpResponse";
import { DateProvider } from "../../../gyzmo-commons/interfaces/dateProvider";
import { CacheService } from "../../../gyzmo-commons/services/cache.service";
import { AttachmentDto } from "../../dto/attachment.dto";
import { ServerConnection } from "../../http/serverConnection";
import { WsDao } from "../../http/wsDao";
import { AttachmentKinds } from "../../interfaces/attachmentKinds";

@Injectable({
    providedIn: "root",
})
export class AttachmentWsDao extends WsDao<AttachmentDto> {
    static WS = "technical/attachments";
    static EQUIPMENTS_WS = "equipments";
    static COMPANIES_WS = "companies";
    static THIRD_PARTIES_WS = "third-parties";
    static MODELS_WS = "models";

    constructor(private imgCacheService: CacheService,
                private logger: Logger,
                private dateProvider: DateProvider) {
        super();
    }

    public getById(serverConnection: ServerConnection, id: string, object?: string, key?: string): Promise<AttachmentDto> {
        if (!object || !key) {
            throw new Error("Args error : object and key have to be passed.");
        }

        return new Promise<AttachmentDto>((resolve, reject) => {
            let tokens = new Map<string, string>();
            tokens.set("id", id);

            serverConnection.get(this.constructor.name, AttachmentWsDao.WS + "/:id", tokens)
                .then(response => {
                    let attachment = AttachmentDto.fromBody(response.body, object, key);
                    resolve(attachment);
                })
                .catch(reason => {
                    reject(reason);
                });
        });
    }

    public getByCompanyId(serverConnection: ServerConnection, companyId: string, attachmentKind?: AttachmentKinds): Promise<AttachmentDto[]> {
        return new Promise<AttachmentDto[]>((resolve, reject) => {
            let tokens = new Map<string, string>();
            tokens.set("id", companyId);

            serverConnection.get(this.constructor.name, AttachmentWsDao.COMPANIES_WS + "/:id/attachments?_limit=NOLIMIT", tokens)
                .then(response => {
                    this.getAttachmentsFromResponse(serverConnection, response, "company", companyId, attachmentKind)
                        .then(attachments => {
                            resolve(attachments);
                        });
                })
                .catch(reason => {
                    resolve([]);
                });
        });
    }

    public getByThirdPartyId(serverConnection: ServerConnection, thirdPartyId: string, attachmentKind?: AttachmentKinds): Promise<AttachmentDto[]> {
        return new Promise<AttachmentDto[]>((resolve, reject) => {
            let tokens = new Map<string, string>();
            tokens.set("id", thirdPartyId);

            serverConnection.get(this.constructor.name, AttachmentWsDao.THIRD_PARTIES_WS + "/:id/attachments", tokens)
                .then(response => {
                    this.getAttachmentsFromResponse(serverConnection, response, "thirdParty", thirdPartyId, attachmentKind)
                        .then(attachments => {
                            resolve(attachments);
                        });
                })
                .catch(reason => {
                    resolve([]);
                });
        });
    }

    public getByEquipmentId(serverConnection: ServerConnection, equipmentId: string, attachmentKind?: AttachmentKinds): Promise<AttachmentDto[]> {
        return new Promise<AttachmentDto[]>((resolve, reject) => {
            let tokens = new Map<string, string>();
            tokens.set("id", equipmentId);

            serverConnection.get(this.constructor.name, AttachmentWsDao.EQUIPMENTS_WS + "/:id/attachments", tokens)
                .then(response => {
                    this.getAttachmentsFromResponse(serverConnection, response, "equipment", equipmentId, attachmentKind)
                        .then(attachments => {
                            resolve(attachments);
                        });
                })
                .catch(reason => {
                    resolve([]);
                });
        });
    }

    public getByModelId(serverConnection: ServerConnection, modelId: string, attachmentKind?: AttachmentKinds): Promise<AttachmentDto[]> {
        return new Promise<AttachmentDto[]>((resolve, reject) => {
            let tokens = new Map<string, string>();
            tokens.set("id", modelId);

            serverConnection.get(this.constructor.name, AttachmentWsDao.MODELS_WS + "/:id/attachments", tokens)
                .then(response => {
                    this.getAttachmentsFromResponse(serverConnection, response, "model", modelId, attachmentKind)
                        .then(attachments => {
                            resolve(attachments);
                        });
                })
                .catch(reason => {
                    resolve([]);
                });
        });
    }

    public getByChecklistId(serverConnection: ServerConnection, checklistId: string, attachmentKind?: AttachmentKinds): Promise<AttachmentDto[]> {
        return new Promise<AttachmentDto[]>((resolve, reject) => {
            let tokens = new Map<string, string>();
            tokens.set("id", checklistId);

            serverConnection.get(this.constructor.name, AttachmentWsDao.WS + "?object=inspection&key=:id", tokens)
                .then(response => {
                    this.getAttachmentsFromResponse(serverConnection, response, "checklist", checklistId, attachmentKind)
                        .then(attachments => {
                            resolve(attachments);
                        });
                })
                .catch(reason => {
                    resolve([]);
                });
        });
    }

    public getByDamageId(serverConnection: ServerConnection, damageId: string, attachmentKind?: AttachmentKinds): Promise<AttachmentDto[]> {
        return new Promise<AttachmentDto[]>((resolve, reject) => {
            let tokens = new Map<string, string>();
            tokens.set("id", damageId);

            serverConnection.get(this.constructor.name, AttachmentWsDao.WS + "?object=inspection&key=:id", tokens)
                .then(response => {
                    this.getAttachmentsFromResponse(serverConnection, response, "damage", damageId, attachmentKind)
                        .then(attachments => {
                            resolve(attachments);
                        });
                })
                .catch(reason => {
                    resolve([]);
                });
        });
    }

    public getByFaceId(serverConnection: ServerConnection, faceId: string): Promise<string> {
        return new Promise<string>((resolve) => {
            this.imgCacheService.isCached("face/" + faceId)
                .then(isCached => {
                    if (isCached) {
                        this.imgCacheService.getCached("face/" + faceId)
                            .then(content => {
                                resolve(content.value);
                            });
                    } else {
                        let tokens = new Map<string, string>();
                        tokens.set("id", faceId);
                        serverConnection.get(this.constructor.name, "inspection-faces/" + encodeURIComponent(faceId) + "/attachments", tokens)
                            .then(response => {
                                if (response.body) {
                                    let attachments: Array<any> = (response.body as Array<any>);
                                    let content = attachments[0].file;
                                    this.imgCacheService.cache("face/" + faceId, content, this.dateProvider.now().plus({ years: 10 }))
                                        .then(() => {
                                            resolve(content);
                                        });
                                }
                            })
                            .catch(reason => {
                                this.logger.error(this.constructor.name, reason);

                                resolve("");
                            });
                    }
                });
        });
    }

    public getForAllFaces(serverConnection: ServerConnection): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            let tokens = new Map<string, string>();

            serverConnection.get(this.constructor.name, "inspection-faces/parameters", tokens)
                .then(async response => {
                    if (response.body) {
                        let faces: Array<any> = (response.body as Array<any>);
                        for (const face of faces) {
                            let cached = await this.imgCacheService.isCached("face/" + face.id);

                            if (!cached) {
                                let content = face.attachments[0].file;
                                await this.imgCacheService.cache("face/" + face.id, content, this.dateProvider.now().plus({ years: 10 }));
                            }
                        }

                        resolve();
                    } else {
                        reject();
                    }
                })
                .catch(reason => {
                    this.logger.error(this.constructor.name, reason);

                    reject();
                });
        });
    }

    public save(serverConnection: ServerConnection, attachmentDto: AttachmentDto): Promise<AttachmentDto> {
        return new Promise<AttachmentDto>((resolve, reject) => {
            let tokens = new Map<string, string>();

            tokens.set("object", attachmentDto.object);
            tokens.set("key", attachmentDto.key);

            if (!isNullOrEmpty(attachmentDto.id)) {
                tokens.set("id", attachmentDto.id);

                serverConnection.put(this.constructor.name, AttachmentWsDao.WS + "/:id/?object=:object&key=:key", tokens, attachmentDto.toBody())
                    .then(response => {
                        resolve(attachmentDto);
                    })
                    .catch(reason => {
                        reject(reason);
                    });
            } else {
                delete attachmentDto.id;

                serverConnection.post(this.constructor.name, AttachmentWsDao.WS + "?object=:object&key=:key", tokens, attachmentDto.toBody())
                    .then(response => {
                        attachmentDto.id = response.headers.get(XVEGAID.toLowerCase());
                        resolve(attachmentDto);
                    })
                    .catch(reason => {
                        reject(reason);
                    });
            }
        });
    }

    private async getAttachmentsFromResponse(serverConnection: ServerConnection,
                                             response: HttpResponse,
                                             object: string,
                                             key: string,
                                             attachmentKindFilter?: AttachmentKinds): Promise<AttachmentDto[]> {
        if (!response.body) {
            return [];
        }

        let promises = [];
        let attachments: AttachmentDto[] = [];

        (response.body as Array<any>).forEach(value => {
            if (!attachmentKindFilter || (attachmentKindFilter && value.attachmentKind.id == attachmentKindFilter)) {
                    promises.push(new Promise<void>(async (resolve) => {
                        let isCached = await this.imgCacheService.isCached(object + "/" + key);

                        if (isCached) {
                            let content = await this.imgCacheService.getCached(object + "/" + key);

                            let attachment = AttachmentDto.fromBody(value, object, key);
                            attachment.file = content.value;

                            attachments.push(attachment);
                            resolve();
                        } else {
                            let attachmentDto = await this.getById(serverConnection, value.id, object, key);
                            attachments.push(attachmentDto);

                            if (!isNullOrEmpty(attachmentDto.file)) {
                                await this.imgCacheService.cache(object + "/" + key, attachmentDto.file, this.dateProvider.now().plus({ years: 10 }));
                                resolve();
                            } else if (attachmentDto.attachedDocuments && attachmentDto.attachedDocuments.length > 0) {
                                await this.imgCacheService.cache(object + "/" + key, attachmentDto.attachedDocuments[0].file, this.dateProvider.now().plus({ years: 10 }));
                                resolve();
                            } else {
                                resolve();
                            }
                        }
                    }));
            }
        });

        await Promise.all(promises);
        return attachments;
    }
}
