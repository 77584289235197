import { Injectable } from "@angular/core";
import { DateTime } from "luxon";
import { AvailabilityDto } from "../dto/availability.dto";
import { BestDealDto } from "../dto/bestDeal.dto";
import { ServersConnectionsProvider } from "../providers/serversConnections.provider";
import { AvailabilitiesWs } from "../ws/availabilities.ws";
import { BestDealWs } from "../ws/bestDeal.ws";

@Injectable({
    providedIn: "root",
})
export class AvailabilitiesService {
    constructor(private serversConnectionsProvider: ServersConnectionsProvider,
                private availabilityWs: AvailabilitiesWs,
                private bestDealWs: BestDealWs) {
    }

    public async list(startDate: DateTime, returnDate: DateTime, latitude: number, longitude: number, kilometers: number, maxDistance: number): Promise<AvailabilityDto[]> {
        let availabilityDtos: AvailabilityDto[] = await this.availabilityWs.list(this.serversConnectionsProvider.getServerConnection(), startDate, returnDate, latitude, longitude, kilometers, maxDistance);

        let categories = [];
        availabilityDtos.forEach(value => {
            if (categories.indexOf(value.category.id) < 0) {
                categories.push(value.category.id);
            }
        });

        const categoriesBestDeals = new Map<string, BestDealDto>();
        for (const category of categories) {
            let bestDeal: BestDealDto = await this.bestDealWs.list(this.serversConnectionsProvider.getServerConnection(),
                startDate,
                returnDate,
                kilometers,
                category);

            categoriesBestDeals.set(category, bestDeal);
        }

        availabilityDtos.forEach(value => {
            value.bestDeal = categoriesBestDeals.get(value.category.id);
        });

        return availabilityDtos;
    }
}
