import { Injectable } from "@angular/core";
import { DateTime } from "luxon";
import { AssetsHelper } from "../../../../../gyzmo-commons/helpers/assets.helper";
import { isNullOrEmpty } from "../../../../../gyzmo-commons/helpers/null.helper";
import { DATE_NODEJS_FORMAT } from "../../../../../gyzmo-commons/interfaces/constants";
import { DateProvider } from "../../../../../gyzmo-commons/interfaces/dateProvider";
import { AttachmentDto } from "../../../../dto/attachment.dto";
import { EquipmentDto } from "../../../../dto/equipment.dto";
import { ChecklistDto } from "../../../../dto/inspection/v2/checklist.dto";
import { InspectionDto } from "../../../../dto/inspection/v2/inspection.dto";
import { ZoneElementDto } from "../../../../dto/inspection/v2/zoneElement.dto";
import { HttpErrorHandler } from "../../../../http/httpErrorHandler";
import { ServerConnection } from "../../../../http/serverConnection";
import { WsDao } from "../../../../http/wsDao";
import { AttachmentKinds } from "../../../../interfaces/attachmentKinds";
import { ZoneElementDbDaoV2 } from "../../../db/inspection/v2/zoneElement.db.dao";
import { AttachmentWsDao } from "../../attachment.ws.dao";
import { EquipmentWsDao } from "../../equipment.ws.dao";

@Injectable({
    providedIn: "root",
})
export class InspectionWsDaoV2 extends WsDao<InspectionDto> {
    static WS = "inspections";

    constructor(private httpErrorHandler: HttpErrorHandler,
                private assetsHelper: AssetsHelper,
                private dateProvider: DateProvider,
                private equipmentWsDao: EquipmentWsDao,
                private attachmentWsDao: AttachmentWsDao,
                private zoneElementDbDaoV2: ZoneElementDbDaoV2) {
        super();
    }

    public async getById(serverConnection: ServerConnection, id: string, downloadChecklistAttachment: boolean = false): Promise<InspectionDto> {
        return new Promise<InspectionDto>((resolve, reject) => {
            let tokens = new Map<string, string>();
            tokens.set("id", id);

            serverConnection.setApiVersion(2);

            serverConnection.get(this.constructor.name, InspectionWsDaoV2.WS + "/:id", tokens)
                .then(response => {
                    let inspectionDto = InspectionDto.fromBody(response.body, serverConnection.getServerDto());
                    let promises = [];

                    response.body.faces.forEach(face => {
                        face.zones.forEach(zone => {
                            zone.elements.forEach(element => {
                                let zoneElementDto = ZoneElementDto.fromBody(element, zone.id);
                                promises.push(this.zoneElementDbDaoV2.save(zoneElementDto.toModel()));
                            });
                        });
                    });

                    if (!isNullOrEmpty(inspectionDto.equipment.id)) {
                        promises.push(this.equipmentWsDao.getById(serverConnection, inspectionDto.equipment.id)
                            .then((equipmentDto: EquipmentDto) => {
                                inspectionDto.equipment = equipmentDto;
                            }));
                    }

                    if (downloadChecklistAttachment) {
                        inspectionDto.checklists.forEach((checklist: ChecklistDto) => {
                            checklist.elements.forEach(element => {
                                promises.push(this.attachmentWsDao.getByChecklistId(serverConnection, element.id, AttachmentKinds.PHOTO)
                                    .then((attachmentDtos: AttachmentDto[]) => {
                                        attachmentDtos.forEach(attachmentDto => {
                                            element.attachments.push(attachmentDto);
                                        });
                                    }));
                            });
                        });
                    }

                    Promise.all(promises)
                        .then(() => {
                            resolve(inspectionDto);
                        });
                })
                .catch(reason => {
                    if (!this.httpErrorHandler.handleError(reason)) {
                        reject();
                    }
                });
        });
    }

    public getList(serverConnection: ServerConnection, startDate?: DateTime, endDate?: DateTime): Promise<InspectionDto[]> {
        return new Promise<InspectionDto[]>((resolve, reject) => {
            let tokens = new Map<string, string>();
            let url = InspectionWsDaoV2.WS + "/?onlyHeaders=1";

            if (startDate && endDate) {
                url += "&_limit=NOLIMIT&startDate=" + startDate.toFormat(DATE_NODEJS_FORMAT) + "&endDate=" + endDate.toFormat(DATE_NODEJS_FORMAT);
            }

            serverConnection.setApiVersion(2);

            serverConnection.get(this.constructor.name, url, tokens)
                .then(response => {
                    let inspectionDtos: InspectionDto[] = [];

                    if (response.body instanceof Array) {
                        const array: Array<any> = (response.body as Array<any>);
                        array.forEach(value => {
                            let inspectionDto = InspectionDto.fromBody(value, serverConnection.getServerDto());
                            inspectionDtos.push(inspectionDto);
                        });
                    }

                    resolve(inspectionDtos);
                })
                .catch(reason => {
                    reject(reason);
                });
        });
    }

    public save(serverConnection: ServerConnection, inspection: InspectionDto): Promise<InspectionDto> {
        return new Promise<InspectionDto>(async (resolve, reject) => {
            let tokens = new Map<string, string>();
            tokens.set("id", inspection.id);

            serverConnection.setApiVersion(2);

            serverConnection.put(this.constructor.name,
                InspectionWsDaoV2.WS + "/:id?updateShortTermContract=true&generateDamage=false&updateMovement=true",
                tokens,
                await inspection.toBody(this.dateProvider, this.assetsHelper))
                .then(response => {
                    resolve(inspection);
                })
                .catch(reason => {
                    if (!this.httpErrorHandler.handleError(reason)) {
                        reject();
                    }
                });
        });
    }
}
