import { ModelBase } from "../../gyzmo-commons/models/base/model.base";
import { Equipment } from "./equipment.model";

export class Movement extends ModelBase {
    static TABLENAME = "Movements";

    id: string = "";
    documentNumber: string = "";
    equipment: Equipment;
    startDate: string = "";
    returnDate: string = "";
    returnFuel: number = 0;
    returnFuel2: number = 0;
    returnMileage: number = NaN;
    startFuel: number = 0;
    startFuel2: number = 0;
    startMileage: number = NaN;
    returnLocation: Location;

    constructor() {
        super(Movement.TABLENAME);

        this.equipment = new Equipment();
    }
}
