import { Injectable } from "@angular/core";
import { CacheDbDao } from "../../gyzmo-commons/dao/db/cache.db.dao";
import { KeyValueDbDao } from "../../gyzmo-commons/dao/db/keyValue.db.dao";
import { VersionDbDao } from "../../gyzmo-commons/dao/db/version.db.dao";
import { AddressDbDao } from "../dao/db/address.db.dao";
import { AttachmentDbDao } from "../dao/db/attachment.db.dao";
import { CompanyDbDao } from "../dao/db/company.db.dao";
import { ContactDetailsDbDao } from "../dao/db/contactDetails.db.dao";
import { DriverInfoDbDao } from "../dao/db/driverInfo.db.dao";
import { EquipmentDbDao } from "../dao/db/equipment.db.dao";
import { GeneralPhotoTypeDbDao } from "../dao/db/generalPhotoType.db.dao";
import { DamageCategoryDbDao } from "../dao/db/inspection/common/damageCategory.db.dao";
import { DamageValuesDbDao } from "../dao/db/inspection/common/damageValues.db.dao";
import { GroupCategoryDbDao } from "../dao/db/inspection/common/groupCategory.db.dao";
import { ChecklistDbDaoV2 } from "../dao/db/inspection/v2/checklist.db.dao";
import { ChecklistElementDbDaoV2 } from "../dao/db/inspection/v2/checklistElement.db.dao";
import { DamageDbDaoV2 } from "../dao/db/inspection/v2/damage.db.dao";
import { FaceDbDaoV2 } from "../dao/db/inspection/v2/face.db.dao";
import { InspectionDbDaoV2 } from "../dao/db/inspection/v2/inspection.db.dao";
import { ZoneDbDaoV2 } from "../dao/db/inspection/v2/zone.db.dao";
import { ZoneElementDbDaoV2 } from "../dao/db/inspection/v2/zoneElement.db.dao";
import { LocationDbDao } from "../dao/db/location.db.dao";
import { MovementDbDao } from "../dao/db/movement.db.dao";
import { OfflineModeDbDao } from "../dao/db/offlineMode.db.dao";
import { ServerDbDao } from "../dao/db/server.db.dao";
import { StcDbDao } from "../dao/db/stc.db.dao";
import { ThirdPartyDbDao } from "../dao/db/thirdParty.db.dao";
import { UserDbDao } from "../dao/db/user.db.dao";

@Injectable({
    providedIn: "root",
})
export class DaoProvider {

    constructor(private addressDbDao: AddressDbDao,
                private stcDbDao: StcDbDao,
                private contactDetailsDbDao: ContactDetailsDbDao,
                private driverInfoDbDao: DriverInfoDbDao,
                private thirdPartyDbDao: ThirdPartyDbDao,
                private userDbDao: UserDbDao,
                private attachmentDbDao: AttachmentDbDao,
                private equipmentDbDao: EquipmentDbDao,
                private companyDbDao: CompanyDbDao,
                private locationDbDao: LocationDbDao,
                private movementDbDao: MovementDbDao,
                private keyValueDbDao: KeyValueDbDao,
                private versionDbDao: VersionDbDao,
                private damageCategoryDbDao: DamageCategoryDbDao,
                private damageValuesDbDao: DamageValuesDbDao,
                private groupCategoryDbDao: GroupCategoryDbDao,
                private generalPhotoTypeDbDao: GeneralPhotoTypeDbDao,
                private offlineModeDbDao: OfflineModeDbDao,
                private serverDbDao: ServerDbDao,
                private cacheDbDao: CacheDbDao,
                private inspectionDbDaoV2: InspectionDbDaoV2,
                private damageDbDaoV2: DamageDbDaoV2,
                private checklistDbDaoV2: ChecklistDbDaoV2,
                private checklistElementDbDaoV2: ChecklistElementDbDaoV2,
                private faceDbDaoV2: FaceDbDaoV2,
                private zoneDbDaoV2: ZoneDbDaoV2,
                private zoneElementDbDaoV2: ZoneElementDbDaoV2) {
    }

    public getAllDaoList() {
        return [
            this.keyValueDbDao,
            this.versionDbDao,
            this.addressDbDao,
            this.contactDetailsDbDao,
            this.driverInfoDbDao,
            this.thirdPartyDbDao,
            this.userDbDao,
            this.attachmentDbDao,
            this.equipmentDbDao,
            this.companyDbDao,
            this.locationDbDao,
            this.movementDbDao,
            this.groupCategoryDbDao,
            this.stcDbDao,
            this.damageCategoryDbDao,
            this.offlineModeDbDao,
            this.damageValuesDbDao,
            this.generalPhotoTypeDbDao,
            this.serverDbDao,
            this.cacheDbDao,
            this.inspectionDbDaoV2,
            this.damageDbDaoV2,
            this.checklistDbDaoV2,
            this.checklistElementDbDaoV2,
            this.faceDbDaoV2,
            this.zoneDbDaoV2,
            this.zoneElementDbDaoV2];
    }

    public getOnDisconnectDaoList() {
        return [
            this.addressDbDao,
            this.contactDetailsDbDao,
            this.driverInfoDbDao,
            this.thirdPartyDbDao,
            this.userDbDao,
            this.attachmentDbDao,
            this.equipmentDbDao,
            this.companyDbDao,
            this.locationDbDao,
            this.movementDbDao,
            this.groupCategoryDbDao,
            this.stcDbDao,
            this.damageCategoryDbDao,
            this.offlineModeDbDao,
            this.damageValuesDbDao,
            this.generalPhotoTypeDbDao,
            this.serverDbDao,
            this.cacheDbDao,
            this.inspectionDbDaoV2,
            this.damageDbDaoV2,
            this.checklistDbDaoV2,
            this.checklistElementDbDaoV2,
            this.faceDbDaoV2,
            this.zoneDbDaoV2,
            this.zoneElementDbDaoV2];
    }
}
