import { Injectable } from "@angular/core";
import { isNullOrEmpty } from "../../../gyzmo-commons/helpers/null.helper";
import { PictureHelper } from "../../../gyzmo-commons/helpers/picture.helper";
import { AttachmentDto } from "../../dto/attachment.dto";
import { EquipmentDto } from "../../dto/equipment.dto";
import { HttpErrorHandler } from "../../http/httpErrorHandler";
import { ServerConnection } from "../../http/serverConnection";
import { WsDao } from "../../http/wsDao";
import { AttachmentKinds } from "../../interfaces/attachmentKinds";
import { AttachmentWsDao } from "./attachment.ws.dao";

@Injectable({
    providedIn: "root",
})
export class EquipmentWsDao extends WsDao<EquipmentDto> {
    static WS = "equipments";
    static HISTORICAL_MILEAGES_WS = "historical-mileages";

    constructor(
        private httpErrorHandler: HttpErrorHandler,
        private attachmentWsDao: AttachmentWsDao) {
        super();
    }

    public getById(serverConnection: ServerConnection, id: string, exitBeforeAttachments = false): Promise<EquipmentDto> {
        return new Promise<EquipmentDto>((resolve, reject) => {
            let tokens = new Map<string, string>();

            serverConnection.get(this.constructor.name, EquipmentWsDao.WS + "/" + id, tokens)
                .then(response => {
                    if (response.status == 204) {
                        resolve(new EquipmentDto());
                    } else {
                        let equipment = EquipmentDto.fromBody(response.body, serverConnection.getServerDto());

                        // Récuperer les photos et infos véhicule
                        this.getEquipmentAttachments(serverConnection, equipment)
                            .then(() => {
                                if (!exitBeforeAttachments) {
                                    resolve(equipment);
                                }
                            });

                        if (exitBeforeAttachments) {
                            resolve(equipment);
                        }
                    }
                })
                .catch(reason => {
                    if (!this.httpErrorHandler.handleError(reason)) {
                        reject();
                    }
                });
        });
    }

    public save(serverConnection: ServerConnection, equipment: EquipmentDto): Promise<EquipmentDto> {
        throw new Error("Not implemented");
    }

    public getUpdatedMileage(serverConnection: ServerConnection, id: string): Promise<number> {
        return new Promise<number>((resolve, reject) => {
            let tokens = new Map<string, string>();

            serverConnection.get(this.constructor.name, EquipmentWsDao.WS + "/" + id + "/" + EquipmentWsDao.HISTORICAL_MILEAGES_WS + "?_order=F09XKMDT DESC&_limit=1", tokens)
                .then(response => {
                    if (response.status == 204) {
                        resolve(0);
                    } else {
                        if (response.body.length > 0) {
                            resolve(Math.floor(Number(response.body[0].mileage)));
                        } else {
                            resolve(0);
                        }
                    }
                })
                .catch(reason => {
                    if (!this.httpErrorHandler.handleError(reason)) {
                        reject();
                    }
                });
        });
    }

    private async getEquipmentAttachments(serverConnection: ServerConnection, equipment: EquipmentDto): Promise<EquipmentDto> {
        let equipmentAttachments: AttachmentDto[] = await this.attachmentWsDao.getByEquipmentId(serverConnection, equipment.id)
            .catch(reason => {
                return [];
            });
        await this.updateEquipmentFromAttachments(equipment, equipmentAttachments);
        let modelAttachments: AttachmentDto[] = await this.attachmentWsDao.getByModelId(serverConnection, equipment.model)
            .catch(reason => {
                return [];
            });
        await this.updateEquipmentFromAttachments(equipment, modelAttachments);

        if (equipment.picture == null) {
            equipment.picture = "";
        }

        return equipment;
    }

    private async updateEquipmentFromAttachments(equipment: EquipmentDto, attachments: AttachmentDto[]): Promise<EquipmentDto> {
        let promises = [];

        attachments.forEach((attachment: AttachmentDto) => {
            switch (attachment.attachmentKind.id) {
                case AttachmentKinds.DESCRIPTION_VEHICULE:
                    if (isNullOrEmpty(equipment.description)) {
                        equipment.description = attachment.txt;
                    }
                    break;
                case AttachmentKinds.OPTIONS_VEHICULE:
                    if (isNullOrEmpty(equipment.options)) {
                        equipment.options = attachment.txt;
                    }
                    break;
                case AttachmentKinds.PHOTO_VEHICULE:
                case AttachmentKinds.PHOTO:
                    if (isNullOrEmpty(equipment.picture)) {
                        if (attachment.file) {
                            promises.push(PictureHelper.resize(attachment.file, 500, 340, 85)
                                .then(value => {
                                    equipment.picture = value;
                                }));
                        } else if (attachment.attachedDocuments && attachment.attachedDocuments.length > 0) {
                            promises.push(PictureHelper.resize(attachment.attachedDocuments[0].file, 500, 340, 85)
                                .then(value => {
                                    equipment.picture = value;
                                }));
                        }
                    }
                    break;
            }
        });

        await Promise.all(promises);
        return equipment;
    }
}
