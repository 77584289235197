import { Component } from "@angular/core";
import { ModalController, Platform } from "@ionic/angular";
import { ZoneElementDbDaoV2 } from "../../../../../core/dao/db/inspection/v2/zoneElement.db.dao";
import { DamageDto } from "../../../../../core/dto/inspection/v2/damage.dto";
import { InspectionDto } from "../../../../../core/dto/inspection/v2/inspection.dto";
import { DamageValue } from "../../../../../core/models/inspection/common/damageValue.model";
import { ZoneElement } from "../../../../../core/models/inspection/v2/zoneElement.model";
import { NavControllerExtended } from "../../../../../gyzmo-commons/helpers/navControllerExtended";

@Component({
    selector: "modal-view-damage",
    templateUrl: "view-damages.modal.html",
    styleUrls: ["view-damages.modal.scss"],
})
export class ViewDamagesModal {
    inspection: InspectionDto;
    zoneElements: ZoneElement[];

    constructor(private navCtrl: NavControllerExtended,
                private platform: Platform,
                private viewCtrl: ModalController,
                private elementDbDao: ZoneElementDbDaoV2) {
        this.inspection = <InspectionDto>this.navCtrl.params.getParam("inspection");
    }

    ionViewWillEnter() {
        this.platform.backButton.subscribeWithPriority(999, () => {
            this.dismiss();
        });

        this.elementDbDao.getList()
            .then(values => {
                this.zoneElements = values;
            });
    }

    public dismiss() {
        this.viewCtrl.dismiss();
    }

    getDamageCount() {
        let count = 0;

        this.inspection.faces.forEach(face => {
            face.zones.forEach(zone => {
                count += zone.damages.length;
            });
        });

        return count;
    }

    getDamageValue(damageDto: DamageDto): DamageValue {
        if (!this.zoneElements) {
            return null;
        }

        let zoneElement = this.zoneElements.find(element => {
            return element.id == damageDto.elementId;
        });
        if (!zoneElement) {
            return null;
        }

        let damageValue = zoneElement.damageCategory.possibleValues.find(element => {
            return element.categoryId == zoneElement.damageCategory.id && element.id == damageDto.value;
        });

        return damageValue;
    }

    getElementValue(damageDto: DamageDto): ZoneElement {
        if (!this.zoneElements) {
            return null;
        }

        let zoneElement = this.zoneElements.find(element => {
            return element.id == damageDto.elementId;
        });

        return zoneElement;
    }

    getDamageResponsibility(damage: DamageDto) {
        let result = "";

        if (damage.clientResponsibility === "1") {
            result = "Client";
        } else if (damage.clientResponsibility === "2") {
            result = "Loueur";
        } else if (damage.clientResponsibility === "0") {
            result = "Non défini";
        }

        return result;
    }
}
