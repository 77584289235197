import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { IonicModule } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";
import { PipesModule } from "../../../gyzmo-commons/helpers/pipes/pipes.module";
import { VehicleDetailsEquipmentsComponent } from "./vehicle-details-equipments";

@NgModule(
    {
        declarations: [
            VehicleDetailsEquipmentsComponent,
        ],
        imports: [
            CommonModule,
            IonicModule,
            TranslateModule,
            PipesModule,
        ],
        exports: [
            VehicleDetailsEquipmentsComponent,
        ],
        entryComponents: [
            VehicleDetailsEquipmentsComponent,
        ],
        providers: [],
    })
export class VehicleDetailsEquipmentsModule {
}
