import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { IonicModule } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";
import { PipesModule } from "../../../gyzmo-commons/helpers/pipes/pipes.module";
import { VehicleDetailsEquipmentsModule } from "../vehicle-details-equipments/vehicle-details-equipments.module";
import { AvailabilityLineComponent } from "./availability-line";

@NgModule(
    {
        declarations: [
            AvailabilityLineComponent,
        ],
        imports: [
            CommonModule,
            IonicModule,
            TranslateModule,
            PipesModule,
            VehicleDetailsEquipmentsModule,
        ],
        exports: [
            AvailabilityLineComponent,
        ],
        entryComponents: [
            AvailabilityLineComponent,
        ],
    })

export class AvailabilityLineModule {
}
