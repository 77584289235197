import { Injectable } from "@angular/core";
import { DateTime } from "luxon";
import { DateHelper } from "../../gyzmo-commons/helpers/date.helper";
import { DateProvider } from "../../gyzmo-commons/interfaces/dateProvider";
import { ServersConnectionsProvider } from "../providers/serversConnections.provider";

@Injectable({
    providedIn: "root",
})
export class DateWs {
    static WS = "technical/date";

    constructor(private serversConnectionsProvider: ServersConnectionsProvider,
                private dateProvider: DateProvider) {
    }

    public getDate(): Promise<DateTime> {
        return new Promise<DateTime>((resolve, reject) => {
            let tokens = new Map<string, string>();
            this.serversConnectionsProvider.getServerConnection().get(this.constructor.name, DateWs.WS, tokens)
                .then(response => {
                    let date = this.responseBodyToModel(response.body);

                    if (!date.isValid) {
                        resolve(this.dateProvider.now());
                    } else {
                        resolve(date);
                    }
                })
                .catch(reason => {
                    resolve(this.dateProvider.now());
                });
        });
    }

    responseBodyToModel(body: any): DateTime {
        const utcDate = body.utcDate;

        return DateHelper.tryFromFormat(utcDate, "yyyy-MM-dd[T]HH:mm:ss").toUTC();
    }
}
