import { Injectable } from "@angular/core";
import { NotImplementedError } from "../../../../../gyzmo-commons/helpers/NotImplementedError";
import { DamageCategoryDto } from "../../../../dto/inspection/common/damageCategory.dto";
import { ServerConnection } from "../../../../http/serverConnection";
import { WsDao } from "../../../../http/wsDao";

@Injectable({
    providedIn: "root",
})
export class DamageCategoryWsDao extends WsDao<DamageCategoryDto> {
    static WS = "inspection-damage-categories";

    public getById(serverConnection: ServerConnection, id: string): Promise<DamageCategoryDto> {
        throw new NotImplementedError();
    }

    public save(serverConnection: ServerConnection, inspection: DamageCategoryDto): Promise<DamageCategoryDto> {
        throw new NotImplementedError();
    }

    public getList(serverConnection: ServerConnection): Promise<DamageCategoryDto[]> {
        return new Promise<DamageCategoryDto[]>((resolve, reject) => {
            let tokens = new Map<string, string>();

            serverConnection.get(this.constructor.name, DamageCategoryWsDao.WS + "/?_limit=NOLIMIT", tokens)
                .then(response => {
                    let damageCategoryDtoList: DamageCategoryDto[] = [];

                    if (response.body instanceof Array) {
                        const array: Array<any> = (response.body as Array<any>);
                        array.forEach(value => {
                            damageCategoryDtoList.push(DamageCategoryDto.fromBody(value));
                        });
                    }

                    resolve(damageCategoryDtoList);
                })
                .catch(reason => {
                    reject(reason);
                });
        });
    }
}
