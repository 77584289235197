import { EventEmitter, Injectable } from "@angular/core";
import { REFRESHER_MAX } from "../../app/app.constants";
import { ThirdPartyDbDao } from "../dao/db/thirdParty.db.dao";
import { ThirdPartyWsDao } from "../dao/ws/thirdParty.ws.dao";
import { ThirdPartyDto } from "../dto/thirdParty.dto";
import { ThirdParty } from "../models/thirdParty.model";
import { ServersConnectionsProvider } from "../providers/serversConnections.provider";
import { ThirdPartyTypesWs } from "../ws/thirdPartyTypes.ws";

@Injectable({
    providedIn: "root",
})
export class ThirdPartyService {
    timerId: any;

    private accountBecameActiveSubject = new EventEmitter<void>();

    constructor(private thirdPartyWsDao: ThirdPartyWsDao,
                private thirdPartyDbDao: ThirdPartyDbDao,
                private serversConnectionsProvider: ServersConnectionsProvider,
                private thirdPartyTypesWs: ThirdPartyTypesWs) {
    }

    public getAccountBecameActiveObservable(): EventEmitter<void> {
        return this.accountBecameActiveSubject;
    }

    public async create(thirdParty: ThirdParty): Promise<ThirdParty> {
        let primaryServerConnection = this.serversConnectionsProvider.getServerConnection();

        let thirdPartyDto = ThirdPartyDto.fromModel(thirdParty);
        let updatedThirdParty = await this.thirdPartyWsDao.save(primaryServerConnection, thirdPartyDto);
        thirdPartyDto.id = updatedThirdParty.id;

        // Ajout type conducteur
        await this.thirdPartyTypesWs.addTypeToThirdParty(primaryServerConnection, thirdPartyDto.id, "3");

        await this.thirdPartyDbDao.save(updatedThirdParty.toModel());

        return updatedThirdParty.toModel();
    }

    public async save(thirdParty: ThirdPartyDto): Promise<ThirdPartyDto> {
        let updatedThirdParty = await this.thirdPartyWsDao.save(this.serversConnectionsProvider.getServerConnection(), thirdParty);
        await this.thirdPartyDbDao.save(updatedThirdParty.toModel());
        return updatedThirdParty;
    }

    startAccountIsActiveScanning(thirdPartyId: string) {
        this.timerId = setInterval(() => {
            // noinspection JSIgnoredPromiseFromCall
            this.refreshAccountIsActive(thirdPartyId);
        }, REFRESHER_MAX);
    }

    public async refreshAccountIsActive(thirdPartyId: string) {
        let thirdPartyDto: ThirdPartyDto = await this.thirdPartyWsDao.getById(this.serversConnectionsProvider.getServerConnection(), thirdPartyId);
        if (thirdPartyDto.isActive) {
            await this.thirdPartyDbDao.save(thirdPartyDto.toModel())
                .then(value => {
                    clearInterval(this.timerId);
                    this.accountBecameActiveSubject.emit();
                });
        }
    }
}
