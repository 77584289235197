import { Component, Input } from "@angular/core";
import { DateTime } from "luxon";
import { StcDto } from "../../../core/dto/stc.dto";
import { StcStatuses } from "../../../core/dto/stcStatuses";

@Component({
    selector: "stc-info",
    templateUrl: "stc-info.html",
})
export class StcInfoComponent {
    @Input() stc: StcDto;
    StcStatuses = StcStatuses;

    getReturnDate(): DateTime {
        if (this.stc.status == StcStatuses.CONTRACT_WAITING_FOR_BILLING
            || this.stc.isClosed) {
            return this.stc.returnDate;
        } else {
            return this.stc.plannedReturnDate;
        }
    }

    getRealMileage() {
        if (this.stc.status == StcStatuses.CONTRACT_WAITING_FOR_BILLING
            || this.stc.isClosed) {
            return (this.stc.movement.returnMileage - this.stc.movement.startMileage);
        } else {
            return this.stc.wantedMileage;
        }
    }
}
