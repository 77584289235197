import { Injectable } from "@angular/core";
import { InAppBrowser } from "@awesome-cordova-plugins/in-app-browser/ngx";
import { TranslateService } from "@ngx-translate/core";
import { DeviceHelper } from "../../gyzmo-commons/helpers/device.helper";
import { LoadingHelper } from "../../gyzmo-commons/helpers/loading.helper";
import { DateProvider } from "../../gyzmo-commons/interfaces/dateProvider";
import { SwiklyService } from "./base/swikly.service";

@Injectable({
    providedIn: "root",
})
export class SandBoxSwiklyService extends SwiklyService {
    private _SWIKLY_URL = "https://sandbox.swikly.com/checkout";
    private _USER_ID = "ec5beab30651135111a3519e25a200fa";
    private _SECRET = "de99b96d1e3beaf1";
    private _LINK_ID = "45l8d87scqtq3qwj8yx0a2kepllldfod";

    constructor(deviceHelper: DeviceHelper,
                loadingHelper: LoadingHelper,
                translateService: TranslateService,
                dateProvider: DateProvider,
                inAppBrowser: InAppBrowser) {
        super(deviceHelper, loadingHelper, translateService, dateProvider, inAppBrowser);
    }

    get SWIKLY_URL(): string {
        return this._SWIKLY_URL;
    }

    get USER_ID(): string {
        return this._USER_ID;
    }

    get SECRET(): string {
        return this._SECRET;
    }

    get LINK_ID(): string {
        return this._LINK_ID;
    }
}
