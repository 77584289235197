import { NgModule } from "@angular/core";
import { AttachmentWsDao } from "./attachment.ws.dao";
import { CompanyWsDao } from "./company.ws.dao";
import { ContactDetailsWsDao } from "./contactDetails.ws.dao";
import { LocationWsDao } from "./location.ws.dao";
import { OfflineModeWsDao } from "./offlineMode.ws.dao";
import { ThirdPartyWsDao } from "./thirdParty.ws.dao";
import { UserWsDao } from "./user.ws.dao";

@NgModule({
    providers: [
        CompanyWsDao,
        AttachmentWsDao,
        ContactDetailsWsDao,
        LocationWsDao,
        ThirdPartyWsDao,
        UserWsDao,
        OfflineModeWsDao,
    ],
})
export class WsModule {
}
