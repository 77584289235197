import { ModelBase } from "../../../../gyzmo-commons/models/base/model.base";
import { ChecklistElement } from "./checklistElement.model";

export class Checklist extends ModelBase {
    static TABLENAME = "Checklists_V2";

    public id: string = "";
    public wording: string = "";
    public order: string = "";

    public elements: ChecklistElement[] = [];

    constructor() {
        super(Checklist.TABLENAME);
    }
}
