import { ModelBase } from "../../gyzmo-commons/models/base/model.base";
import { Location } from "./location.model";

export class Equipment extends ModelBase {
    static TABLENAME = "Equipments";

    public id: string = "";
    public title: string = "";
    public category: any = {};
    public options: string = "";
    public description: string = "";
    public model: string = "";
    public doors: number = 0;
    public seatings: number = 0;
    public motorization: string = "";
    public color: string = "";
    public latitude: number = 0;
    public longitude: number = 0;
    public trackerVersion: number = 0;
    public registrationNumber: string = "";
    public maxFuel: number = 0;
    public maxFuel2: number = 0;

    public mileage: number = 0;
    public mileageDate: string = "";
    public inspectionModel: { id?: string } = null;
    public serialNumber: string = "";

    public location: Location = null;

    // volatile
    public picture: string = "";

    constructor() {
        super(Equipment.TABLENAME);

        this.location = new Location();
        this.inspectionModel = {};
    }
}

