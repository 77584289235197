import { Injectable } from "@angular/core";
import { AttachmentWsDao } from "../../../dao/ws/attachment.ws.dao";
import { InspectionFacesWsDaoV2 } from "../../../dao/ws/inspection/v2/face.ws.dao";
import { ServersConnectionsProvider } from "../../../providers/serversConnections.provider";

@Injectable({
    providedIn: "root",
})
export class InspectionFacesServiceV2 {
    constructor(private inspectionFacesWsDao: InspectionFacesWsDaoV2,
                private attachmentWsDao: AttachmentWsDao,
                private serversConnectionsProvider: ServersConnectionsProvider) {
    }

    public async synchronizeAttachments(): Promise<void> {
        await this.attachmentWsDao.getForAllFaces(this.serversConnectionsProvider.getServerConnection())
            .catch(async reason => {
                await this.synchronizeAttachmentsOld();
            });
    }

    public synchronizeAttachmentsOld(): Promise<void> {
        return this.inspectionFacesWsDao.getList(this.serversConnectionsProvider.getServerConnection())
            .then(faces => {
                let promises = [];

                faces.forEach(face => {
                    promises.push(this.attachmentWsDao.getByFaceId(this.serversConnectionsProvider.getServerConnection(), face.id));
                });

                return Promise.all(promises)
                    .then(value => {
                        return;
                    });
            });
    }
}
