import { DateTime } from "luxon";
import { DEFAULT_COMPANY, DEFAULT_LOCATION } from "../../app/app.constants";
import { DateHelper } from "../../gyzmo-commons/helpers/date.helper";
import { DATE_NODEJS_FORMAT } from "../../gyzmo-commons/interfaces/constants";
import { Stc } from "../models/stc.model";
import { AvailabilityDto } from "./availability.dto";
import { BaseDto } from "./base/base.dto";
import { LocationDto } from "./location.dto";
import { MovementDto } from "./movement.dto";
import { RentParamsDto } from "./rentParams.dto";
import { ServerDto } from "./server.dto";
import { StcStatuses } from "./stcStatuses";
import { ThirdPartyDto } from "./thirdParty.dto";
import { UserDto } from "./user.dto";

export class StcDto extends BaseDto<Stc> {
    id = "";
    bookingCode = "";
    contractNumber = "";
    startDate: DateTime = null;
    plannedReturnDate: DateTime = null;
    returnDate: DateTime = null;
    customer: ThirdPartyDto;
    driver: ThirdPartyDto;
    wantedDays = 0;
    wantedMileage = 0;
    deposit = 0;
    customerInvoice: { excludingTaxAmount: number, includingTaxAmount: number, vatAmount: number, } = { excludingTaxAmount: 0, includingTaxAmount: 0, vatAmount: 0 };
    pricing: any = {};
    pricingMethod: any = {};
    movement: MovementDto;
    startLocation: LocationDto;
    plannedReturnLocation: LocationDto;
    returnLocation: LocationDto;
    category: any = {};
    status: StcStatuses;
    isClosed: boolean = false;

    constructor() {
        super();

        this.status = StcStatuses.RESERVATION;

        this.customer = new ThirdPartyDto();
        this.driver = new ThirdPartyDto();
        this.startLocation = new LocationDto();
        this.plannedReturnLocation = new LocationDto();
        this.returnLocation = new LocationDto();
        this.movement = new MovementDto();
    }

    public static fromBody(body: any, serverDto: ServerDto): StcDto {
        let stcDto = new StcDto();

        stcDto.id = body.id;
        stcDto.bookingCode = body.bookingCode;
        stcDto.contractNumber = body.contractNumber;
        stcDto.startDate = DateHelper.tryFromFormat(body.startDate, DATE_NODEJS_FORMAT);
        stcDto.plannedReturnDate = DateHelper.tryFromFormat(body.plannedReturnDate, DATE_NODEJS_FORMAT);
        stcDto.returnDate = DateHelper.tryFromFormat(body.returnDate, DATE_NODEJS_FORMAT);
        if (body.customer) {
            stcDto.customer = ThirdPartyDto.fromBody(body.customer);
        }
        if (body.driver) {
            stcDto.driver = ThirdPartyDto.fromBody(body.driver);
        }
        stcDto.wantedDays = body.wantedDays;
        stcDto.wantedMileage = body.wantedMileage;
        stcDto.deposit = body.deposit;

        // Stockés tel quel
        if (body.pricing) {
            stcDto.pricing = { id: BaseDto.getId(body.pricing) };
        }

        if (body.pricingMethod) {
            stcDto.pricingMethod = { id: BaseDto.getId(body.pricingMethod) };
        }

        stcDto.customerInvoice = body.customerInvoice;

        if (body.movement) {
            stcDto.movement = MovementDto.fromBody(body.movement, serverDto);
        }

        if (body.startLocation) {
            stcDto.startLocation = LocationDto.fromBody(body.startLocation);
        }
        if (body.plannedReturnLocation) {
            stcDto.plannedReturnLocation = LocationDto.fromBody(body.plannedReturnLocation);
        }
        if (body.returnLocation) {
            stcDto.returnLocation = LocationDto.fromBody(body.returnLocation);
        }

        if (body.category) {
            stcDto.category = { id: BaseDto.getId(body.category) };
        }

        stcDto.status = body.status.id;

        stcDto.isClosed = false;
        if (body.closed) {
            stcDto.isClosed = body.closed;
        }

        return stcDto;
    }

    static fromModel(stc: Stc): StcDto {
        let stcDto = new StcDto();

        stcDto.id = stc.id;
        stcDto.startDate = DateTime.fromISO(stc.startDate);
        stcDto.plannedReturnDate = DateTime.fromISO(stc.plannedReturnDate);
        stcDto.returnDate = DateTime.fromISO(stc.returnDate);
        stcDto.wantedMileage = stc.wantedMileage;

        stcDto.deposit = stc.deposit;
        stcDto.contractNumber = stc.contractNumber;
        stcDto.bookingCode = stc.bookingCode;

        stcDto.customer = ThirdPartyDto.fromModel(stc.customer);
        stcDto.driver = ThirdPartyDto.fromModel(stc.driver);
        stcDto.status = stc.status;
        stcDto.isClosed = (stc.isClosed == 1);

        // Stockés tel quel
        stcDto.customerInvoice = stc.customerInvoice;
        stcDto.pricing = stc.pricing;
        stcDto.pricingMethod = stc.pricingMethod;

        stcDto.movement = MovementDto.fromModel(stc.movement);

        stcDto.category = stc.category;

        stcDto.wantedDays = DateHelper.tryFromFormat(stc.plannedReturnDate, DATE_NODEJS_FORMAT).diff(DateHelper.tryFromFormat(stc.startDate, DATE_NODEJS_FORMAT), "days").days;
        stcDto.startLocation = LocationDto.fromModel(stc.startLocation);
        stcDto.plannedReturnLocation = LocationDto.fromModel(stc.plannedReturnLocation);
        stcDto.returnLocation = LocationDto.fromModel(stc.returnLocation);

        return stcDto;
    }

    static fromRentParamsAndAvailability(rentParams: RentParamsDto, availability: AvailabilityDto, user: UserDto): StcDto {
        let stc = new StcDto();

        stc.id = null;
        stc.bookingCode = "";
        stc.contractNumber = null;
        stc.startDate = rentParams.startDate;
        stc.plannedReturnDate = rentParams.plannedReturnDate;
        stc.wantedMileage = rentParams.wantedMileage;
        stc.status = StcStatuses.RESERVATION;
        stc.isClosed = false;
        stc.deposit = availability.bestDeal.deposit;

        stc.customer = user.thirdParty;
        stc.driver = user.thirdParty;

        stc.movement = new MovementDto();
        stc.movement.id = null;
        if (availability.equipment) {
            stc.movement.equipment = availability.equipment;
        }

        stc.category = availability.category;
        stc.customerInvoice = {
            excludingTaxAmount: availability.bestDeal.excludingTaxAmount,
            includingTaxAmount: availability.bestDeal.includingTaxAmount,
            vatAmount: availability.bestDeal.vatAmount,
        };
        stc.pricing = availability.bestDeal.pricing;
        stc.pricingMethod = availability.bestDeal.pricingMethod;

        stc.customer = user.thirdParty;
        stc.driver = user.thirdParty;

        stc.startLocation = new LocationDto();
        stc.plannedReturnLocation = new LocationDto();
        stc.returnLocation = new LocationDto();
        if (availability.equipment.location && availability.equipment.location.id) {
            stc.startLocation = availability.equipment.location;
            stc.plannedReturnLocation = availability.equipment.location;
            stc.returnLocation = availability.equipment.location;
        } else {
            stc.startLocation.id = DEFAULT_LOCATION;
            stc.startLocation.company.id = DEFAULT_COMPANY;

            stc.plannedReturnLocation.id = DEFAULT_LOCATION;
            stc.plannedReturnLocation.company.id = DEFAULT_COMPANY;

            stc.returnLocation.id = DEFAULT_LOCATION;
            stc.returnLocation.company.id = DEFAULT_COMPANY;
        }

        return stc;
    }

    public toModel(): Stc {
        let stc = new Stc();

        stc.id = this.id;
        stc.startDate = DateHelper.tryToISO(this.startDate);
        stc.plannedReturnDate = DateHelper.tryToISO(this.plannedReturnDate);
        stc.returnDate = DateHelper.tryToISO(this.returnDate);
        stc.wantedMileage = this.wantedMileage;

        stc.deposit = this.deposit;
        stc.customerInvoice = this.customerInvoice;
        stc.contractNumber = this.contractNumber;
        stc.bookingCode = this.bookingCode;
        stc.status = this.status;
        stc.isClosed = (this.isClosed ? 1 : 0);

        if (this.customer) {
            stc.customer = this.customer.toModel();
        }

        if (this.driver) {
            stc.driver = this.driver.toModel();
        }

        if (this.movement) {
            stc.movement = this.movement.toModel();
        }

        if (this.startLocation) {
            stc.startLocation = this.startLocation.toModel();
        }
        if (this.plannedReturnLocation) {
            stc.plannedReturnLocation = this.plannedReturnLocation.toModel();
        }
        if (this.returnLocation) {
            stc.returnLocation = this.returnLocation.toModel();
        }

        // Stockés tel quel en JSON
        stc.pricing = this.pricing;
        stc.pricingMethod = this.pricingMethod;
        stc.category = this.category;

        return stc;
    }

    public toBody(): any {
        const clone: any = { ...this };

        clone.status = { id: clone.status };
        clone.rentalType = { id: 1 };

        clone.startDate = DateHelper.tryToFormat(this.startDate, DATE_NODEJS_FORMAT);
        clone.plannedReturnDate = DateHelper.tryToFormat(this.plannedReturnDate, DATE_NODEJS_FORMAT);
        clone.returnDate = DateHelper.tryToFormat(this.returnDate, DATE_NODEJS_FORMAT);

        clone.customer = this.customer.toBody();
        clone.driver = this.driver.toBody();
        clone.movement = this.movement.toBody();
        clone.startLocation = this.startLocation.toBody();
        clone.plannedReturnLocation = this.plannedReturnLocation.toBody();
        clone.returnLocation = this.returnLocation.toBody();

        // Nettoyer l'object avant envoi à lpservice

        return clone;
    }
}
