import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";

const routes: Routes = [
    {
        path: "about",
        loadChildren: () => import("../gyzmo-commons/pages/about/about.module").then(m => m.AboutPageModule),
    },
    {
        path: "cgu", loadChildren: () => import("./account/cgu/cgu.module").then(m => m.CguPageModule),
    },
    {
        path: "connection",
        loadChildren: () => import("./account/connection/connection.module").then(m => m.ConnectionPageModule),
    },
    {
        path: "account-created",
        loadChildren: () => import("./account/creation/account-created/account-created.module").then(m => m.AccountCreatedModule),
    },
    {
        path: "fill-documents",
        loadChildren: () => import("./account/creation/fill-documents/fill-documents.module").then(m => m.FillDocumentsModule),
    },
    {
        path: "fill-driver-license",
        loadChildren: () => import("./account/creation/fill-driver-license/fill-driver-license.module").then(m => m.FillDriverLicenceModule),
    },
    {
        path: "fill-information",
        loadChildren: () => import("./account/creation/fill-information/fill-information.module").then(m => m.FillInformationModule),
    },
    {
        path: "fill-login",
        loadChildren: () => import("./account/creation/fill-login/fill-login.module").then(m => m.FillLoginModule),
    },
    {
        path: "fill-phone-number",
        loadChildren: () => import("./account/creation/fill-phone-number/fill-phone-number.module").then(m => m.FillPhoneNumberModule),
    },
    {
        path: "verif-phone-number",
        loadChildren: () => import("./account/creation/verif-phone-number/verif-phone-number.module").then(m => m.VerifPhoneNumberModule),
    },
    {
        path: "forgot-password",
        loadChildren: () => import("./account/lost-password/reinitialize-password/reinitialize-password.module").then(m => m.ReinitializePasswordModule),
    },
    {
        path: "my-account",
        loadChildren: () => import("./account/my-account/my-account.module").then(m => m.MyAccountModule),
    },
    {
        path: "update-documents",
        loadChildren: () => import("./account/update/update-documents/update-documents.module").then(m => m.UpdateDocumentsModule),
    },
    {
        path: "update-driver-license",
        loadChildren: () => import("./account/update/update-driver-license/update-driver-license.module").then(m => m.UpdateDriverLicenseModule),
    },
    {
        path: "update-information",
        loadChildren: () => import("./account/update/update-information/update-information.module").then(m => m.UpdateInformationModule),
    },
    {
        path: "update-password",
        loadChildren: () => import("./account/update/update-password/update-password.module").then(m => m.UpdatePasswordModule),
    },
    {
        path: "update-phone",
        loadChildren: () => import("./account/update/update-phone/update-phone.module").then(m => m.UpdatePhoneModule),
    },
    {
        path: "availability-search",
        loadChildren: () => import("./availability-search/availability-search/availability-search.module").then(m => m.AvailabilitySearchModule),
    },
    {
        path: "availabilities-list",
        loadChildren: () => import("./availability-search/availabilities-list/availabilities-list.module").then(m => m.AvailabilitiesListModule),
    },
    {
        path: "rent-summary",
        loadChildren: () => import("./availability-search/rent-summary/rent-summary.module").then(m => m.RentSummaryModule),
    },
    {
        path: "payment-simulation",
        loadChildren: () => import("./availability-search/payment-simulation/payment-simulation.module").then(m => m.PaymentSimulationModule),
    },
    {
        path: "rent-done",
        loadChildren: () => import("./availability-search/rent-done/rent-done.module").then(m => m.RentDoneModule),
    },
    {
        path: "vehicle-details",
        loadChildren: () => import("./availability-search/vehicle-details/vehicle-details.module").then(m => m.VehicleDetailsModule),
    },
    {
        path: "my-stc-list",
        loadChildren: () => import("./my-stcs/my-stc-list/my-stc-list.module").then(m => m.MyStcListModule),
    },
    {
        path: "pending-stc",
        loadChildren: () => import("./my-stcs/pending-stc/pending-stc.module").then(m => m.PendingStcModule),
    },
    {
        path: "available-stc",
        loadChildren: () => import("./my-stcs/available-stc/available-stc.module").then(m => m.AvailableStcModule),
    },
    {
        path: "my-stcs-historic-list",
        loadChildren: () => import("./my-stcs-historic/my-stcs-historic-list/my-stcs-historic-list.module").then(m => m.MyStcsHistoricListModule),
    },
    {
        path: "stc-historic-details",
        loadChildren: () => import("./my-stcs-historic/stc-historic-details/stc-historic-details.module").then(m => m.StcHistoricDetailsModule),
    },
    {
        path: "more",
        loadChildren: () => import("./more/more.module").then(m => m.MoreModule),
    },
    {
        path: "face-v2/:iterator",
        loadChildren: () => import("./inspection/V2/face/face.module").then(m => m.FacePageModule),
    },
    {
        path: "face-general-photo-v2/:iterator",
        loadChildren: () => import("./inspection/V2/face-general-photo/face-general-photo.module").then(m => m.FaceGeneralPhotoPageModule),
    },
    {
        path: "inspection-summary-v2",
        loadChildren: () => import("./inspection/V2/inspection-summary/inspection-summary.module").then(m => m.InspectionSummaryPageModule),
    },
    {
        path: "inspection-done",
        loadChildren: () => import("./inspection/inspection-done/inspection-done.module").then(m => m.InspectionDoneModule),
    },
    {
        path: "movement",
        loadChildren: () => import("./inspection/common/movement/movement.module").then(m => m.MovementPageModule),
    },
    {
        path: "wait-for-keys",
        loadChildren: () => import("./inspection/wait-for-keys/wait-for-keys.module").then(m => m.WaitForKeysModule),
    },
    {
        path: "open-equipment",
        loadChildren: () => import("./inspection/open-equipment/open-equipment.module").then(m => m.OpenEquipmentModule),
    },
    {
        path: "lock-equipment",
        loadChildren: () => import("./inspection/lock-equipment/lock-equipment.module").then(m => m.LockEquipmentModule),
    },
    {
        path: "checklist-v2",
        loadChildren: () => import("./inspection/V2/checklist/checklist.module").then(m => m.ChecklistPageModule),
    },
    {
        path: "rent-finished",
        loadChildren: () => import("./inspection/rent-finished/rent-finished.module").then(m => m.RentFinishedModule),
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules,
    onSameUrlNavigation: "reload",
    scrollPositionRestoration: "enabled",
    useHash: true,
    relativeLinkResolution: 'legacy'
}),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
