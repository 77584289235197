import { Component, Input } from "@angular/core";
import { Logger } from "ionic-logging-service";
import { AttachmentDto } from "../../../core/dto/attachment.dto";
import { DamageValueDto } from "../../../core/dto/inspection/common/damageValue.dto";
import { ChecklistElementDto } from "../../../core/dto/inspection/v2/checklistElement.dto";
import { AttachmentHelper } from "../../../core/helpers/attachment.helper";
import { PictureHelper } from "../../../gyzmo-commons/helpers/picture.helper";
import { DateProvider } from "../../../gyzmo-commons/interfaces/dateProvider";
import { CameraService } from "../../../gyzmo-commons/services/camera.service";

@Component({
    selector: "checklist-line",
    templateUrl: "checklist-line.component.html",
    styleUrls: ["checklist-line.component.scss"],
})
export class ChecklistLineComponent {
    @Input() checklist: ChecklistElementDto;

    constructor(private cameraService: CameraService,
                private logger: Logger,
                private pictureHelper: PictureHelper,
                private dateProvider: DateProvider) {
    }

    public addPicture() {
        this.cameraService.getPicture()
            .then(async (imageData) => {
                // Si on est dans le browser et qu'on fait un "back" sur la prise de photo, data64 undefined
                if (imageData) {
                    let attachment: AttachmentDto = await AttachmentHelper.createPhotoWithLegend(null, imageData, "", this.dateProvider);
                    this.checklist.attachments.push(attachment);
                }
            })
            .catch(reason => {
                this.logger.error(this.constructor.name, reason);
            });
    }

    public deletePicture(attachment: AttachmentDto) {
        if (!this.checklist.attachments || this.checklist.attachments.length <= 0) {
            return;
        }

        this.checklist.attachments = this.checklist.attachments.filter((object: AttachmentDto) => {
            return object != attachment;
        });
    }

    public showPicture(attachment: AttachmentDto) {
        this.pictureHelper.viewImage(attachment.file, true)
            .then((result: string) => {
                if (result) {
                    attachment.file = result;
                }
            });
    }

    isBooleanChecklist() {
        return ((this.checklist.checklistModelId.indexOf("CHECKAP") >= 0) ||
                (this.checklist.checklistModelId.indexOf("PRESENCE") >= 0) ||
                (this.checklist.checklistModelId.indexOf("CHECKON") >= 0));
    }

    isPositiveValue(possibleValue: DamageValueDto) {
        return (possibleValue.id.indexOf("OUI") >= 0) || (possibleValue.id.indexOf("PRESENT") >= 0);
    }
}
