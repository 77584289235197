import { HttpErrorResponse } from "@angular/common/http";
import { API_VERSION, MODULE } from "../../app/app.constants";
import { DeviceHelper } from "../../gyzmo-commons/helpers/device.helper";
import { NetworkHelper } from "../../gyzmo-commons/helpers/network.helper";
import { HttpHeadersProvider } from "../../gyzmo-commons/http/httpHeaders.provider";
import { HttpResponse } from "../../gyzmo-commons/http/httpResponse";
import { IServerConnection } from "../../gyzmo-commons/interfaces/IServerConnection";
import { HttpClientProvider } from "../../gyzmo-commons/providers/httpClientProvider";
import { AppVersionService } from "../../gyzmo-commons/services/appVersion.service";
import { LanguageService } from "../../gyzmo-commons/services/language.service";
import { ServerDbDao } from "../dao/db/server.db.dao";
import { ServerDto } from "../dto/server.dto";
import { RequestMode } from "../interfaces/requestMode";
import { OfflineModeService } from "../services/offlineMode.service";
import { HttpErrorHandler } from "./httpErrorHandler";

export class ServerConnection implements IServerConnection {
    private httpHeadersProvider: HttpHeadersProvider;

    public constructor(deviceHelper: DeviceHelper,
                       languageService: LanguageService,
                       appVersionService: AppVersionService,
                       private networkHelper: NetworkHelper,
                       private serverDto: ServerDto,
                       private serverDbDao: ServerDbDao,
                       private httpClientProvider: HttpClientProvider,
                       private httpErrorHandler: HttpErrorHandler,
                       private offlineModeService: OfflineModeService) {
        this.httpHeadersProvider = new HttpHeadersProvider(MODULE, API_VERSION, deviceHelper, appVersionService, languageService);

        this.httpHeadersProvider.setApiKey(serverDto.apiKey);
        this.httpHeadersProvider.setContext(serverDto.context);
        this.httpHeadersProvider.setAuthentication(serverDto.token);
    }

    public getApiVersion(): number {
        return this.httpHeadersProvider.getApiVersion();
    }

    public setApiVersion(version: number) {
        return this.httpHeadersProvider.setApiVersion(version);
    }

    public getServerDto(): ServerDto {
        return this.serverDto;
    }

    public async setAuthentication(token: string) {
        this.serverDto.token = token;
        await this.serverDbDao.save(this.serverDto.toModel());

        this.httpHeadersProvider.setAuthentication(token);
    }

    public get(api: string, urlPattern: string, tokens: Map<string, string>, supportOffline = false, showErrorToast = true): Promise<HttpResponse> {
        const url = this.formatUrl(urlPattern, tokens);

        return this.httpClientProvider.getHttpClient().get(url, this.httpHeadersProvider.Headers)
            .then(value => {
                this.setApiVersion(API_VERSION);

                this.networkHelper.publishForceConnectivityStateChanged(true);
                return value;
            })
            .catch(async (reason: HttpErrorResponse) => {
                if (reason.status <= 0) {
                    if (supportOffline) {
                        await this.offlineModeService.queueRequest(RequestMode.GET, api, url, this.httpHeadersProvider.Headers, null);
                    }
                    this.networkHelper.publishForceConnectivityStateChanged(false);
                } else {
                    this.httpErrorHandler.handleError(reason, showErrorToast);
                }

                this.setApiVersion(API_VERSION);

                throw reason;
            });
    }

    public post(api: string, urlPattern: string, tokens: Map<string, string>, data: any, supportOffline = true, showErrorToast = true): Promise<HttpResponse> {
        const url = this.formatUrl(urlPattern, tokens);

        return this.httpClientProvider.getHttpClient().post(url, this.httpHeadersProvider.Headers, data)
            .then(value => {
                this.setApiVersion(API_VERSION);

                this.networkHelper.publishForceConnectivityStateChanged(true);
                return value;
            })
            .catch(async (reason: HttpErrorResponse) => {
                if (reason.status <= 0) {
                    if (supportOffline) {
                        await this.offlineModeService.queueRequest(RequestMode.POST, api, url, this.httpHeadersProvider.Headers, data);
                    }
                    this.networkHelper.publishForceConnectivityStateChanged(false);
                } else {
                    this.httpErrorHandler.handleError(reason, showErrorToast);
                }

                this.setApiVersion(API_VERSION);

                throw reason;
            });
    }

    public put(api: string, urlPattern: string, tokens: Map<string, string>, data: any, supportOffline = true): Promise<HttpResponse> {
        const url = this.formatUrl(urlPattern, tokens);

        return this.httpClientProvider.getHttpClient().put(url, this.httpHeadersProvider.Headers, data)
            .then(value => {
                this.setApiVersion(API_VERSION);

                this.networkHelper.publishForceConnectivityStateChanged(true);
                return value;
            })
            .catch(async (reason: HttpErrorResponse) => {
                if (reason.status <= 0) {
                    if (supportOffline) {
                        await this.offlineModeService.queueRequest(RequestMode.PUT, api, url, this.httpHeadersProvider.Headers, data);
                    }
                    this.networkHelper.publishForceConnectivityStateChanged(false);
                } else {
                    this.httpErrorHandler.handleError(reason);
                }

                this.setApiVersion(API_VERSION);

                throw reason;
            });
    }

    public delete(api: string, urlPattern: string, tokens: Map<string, string>, supportOffline = true): Promise<HttpResponse> {
        const url = this.formatUrl(urlPattern, tokens);

        return this.httpClientProvider.getHttpClient().delete(url, this.httpHeadersProvider.Headers)
            .then(value => {
                this.setApiVersion(API_VERSION);

                this.networkHelper.publishForceConnectivityStateChanged(true);
                return value;
            })
            .catch(async (reason: HttpErrorResponse) => {
                if (reason.status <= 0) {
                    if (supportOffline) {
                        await this.offlineModeService.queueRequest(RequestMode.DELETE, api, url, this.httpHeadersProvider.Headers, null);
                    }
                    this.networkHelper.publishForceConnectivityStateChanged(false);
                } else {
                    this.httpErrorHandler.handleError(reason);
                }

                this.setApiVersion(API_VERSION);

                throw reason;
            });
    }

    private formatUrl(urlPattern: string, tokens: Map<string, string>) {
        if (!tokens) {
            return this.serverDto.url + "/" + urlPattern;
        }

        let url = this.serverDto.url + "/" + urlPattern.slice(); // clone

        tokens.forEach((value, key) => {
            url = url.replace(":" + key, value);
        });
        return url;
    }
}
