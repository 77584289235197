import { Injectable } from "@angular/core";
import { NotImplementedError } from "../../../../../gyzmo-commons/helpers/NotImplementedError";
import { XVEGAID } from "../../../../../gyzmo-commons/http/header.constant";
import { ServerConnection } from "../../../../http/serverConnection";
import { WsDao } from "../../../../http/wsDao";
import { INSPECTION_KINDS } from "../../../../interfaces/INSPECTION_KINDS";

@Injectable({
    providedIn: "root",
})
export class InspectionWsDaoV1 extends WsDao<void> {
    static WS = "inspections";

    public getById(serverConnection: ServerConnection, id: string): Promise<void> {
        throw new NotImplementedError();
    }

    public save(serverConnection: ServerConnection, inspection: void): Promise<void> {
        throw new NotImplementedError();
    }

    public initializeByMoveId(serverConnection: ServerConnection, moveId: string, inspectionType: INSPECTION_KINDS): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            let savedApiVersion = serverConnection.getApiVersion();
            serverConnection.setApiVersion(1);

            let tokens = new Map<string, string>();
            tokens.set("movement", moveId);

            let body = {
                relativeKey: moveId,
                object: {
                    id: "F570MVT",
                },
                kind: {
                    id: "",
                },
            };

            if (inspectionType == INSPECTION_KINDS.DEPARTURE) {
                body.kind.id = "K575TI8OPE-DEP";
            } else {
                body.kind.id = "K575TI8OPE-ARR";
            }

            serverConnection.post(this.constructor.name, InspectionWsDaoV1.WS + "?movement=:movement", tokens, body)
                .then(response => {
                    serverConnection.setApiVersion(savedApiVersion);

                    let groupId = response.headers.get(XVEGAID.toLowerCase());
                    resolve(groupId);
                })
                .catch(reason => {
                    serverConnection.setApiVersion(savedApiVersion);

                    reject(reason);
                });
        });
    }
}
