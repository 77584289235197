import { Injectable } from "@angular/core";
import { MovementDto } from "../../dto/movement.dto";
import { ServerConnection } from "../../http/serverConnection";
import { WsDao } from "../../http/wsDao";

@Injectable({
    providedIn: "root",
})
export class MovementWsDao extends WsDao<MovementDto> {
    static WS = "movements";

    public getById(serverConnection: ServerConnection, id: string): Promise<MovementDto> {
        return new Promise<MovementDto>((resolve, reject) => {
            let tokens = new Map<string, string>();
            tokens.set("id", id);

            serverConnection.get(this.constructor.name, MovementWsDao.WS + "/:id", tokens)
                .then(response => {
                    let movement = MovementDto.fromBody(response.body, serverConnection.getServerDto());
                    let promises = [];

                    Promise.all(promises)
                        .then(() => {
                            resolve(movement);
                        });
                })
                .catch(reason => {
                    reject(reason);
                });
        });
    }

    public save(serverConnection: ServerConnection, movement: MovementDto): Promise<MovementDto> {
        return new Promise<MovementDto>((resolve, reject) => {
            let tokens = new Map<string, string>();

            serverConnection.put(this.constructor.name, MovementWsDao.WS, tokens, movement.toBody())
                .then(response => {
                    resolve(movement);
                })
                .catch(reason => {
                    reject(reason);
                });
        });
    }
}
