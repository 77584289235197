import { Injectable } from "@angular/core";
import { Logger } from "ionic-logging-service";
import { DbDaoBase } from "../../../../../gyzmo-commons/dao/db/base/db.dao.base";
import { AppSqlProvider } from "../../../../../gyzmo-commons/persistence/app.sql.provider";
import { DamageCategory } from "../../../../models/inspection/common/damageCategory.model";
import { DamageValuesDbDao } from "./damageValues.db.dao";

@Injectable({
    providedIn: "root",
})
export class DamageCategoryDbDao extends DbDaoBase<DamageCategory> {

    constructor(
        logger: Logger,
        private sqlProvider: AppSqlProvider,
        private damageValuesDbDao: DamageValuesDbDao) {
        super(logger);
    }

    public async createIndexes(): Promise<void> {
        let query = "CREATE INDEX IF NOT EXISTS idx_" + DamageCategory.TABLENAME + "_id"
                    + " ON " + DamageCategory.TABLENAME + "(id);";

        await this.sqlProvider.query(query)
            .catch(reason => {
                this.logSqlError(reason);
            });
    }

    public createTable(): Promise<void> {
        let query = "CREATE TABLE IF NOT EXISTS " + DamageCategory.TABLENAME
                    + " ("
                    + "id TEXT PRIMARY KEY,"
                    + "wording TEXT"
                    + ");";

        return this.sqlProvider.query(query)
            .then(async () => {
                await this.createIndexes();
                return;
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    delete(id: string): Promise<any> {
        let selectQuery = "DELETE FROM " + DamageCategory.TABLENAME + " WHERE id='" + id + "';";
        return this.sqlProvider.query(selectQuery);
    }

    deleteAll(): Promise<any> {
        let selectQuery = "DELETE FROM " + DamageCategory.TABLENAME + ";";
        return this.sqlProvider.query(selectQuery);
    }

    public get(id: string, hydrate: boolean = false): Promise<DamageCategory> {
        let selectQuery = "SELECT * FROM " + DamageCategory.TABLENAME + " WHERE id = '" + id + "';";

        return this.sqlProvider.query(selectQuery)
            .then(
                data => {
                    if (data.rows.length <= 0) {
                        return null;
                    }

                    let damageCategory = this.rowToModel(data.rows[0]);

                    let hydratationPromises = [];

                    if (hydrate) {
                        hydratationPromises.push(this.damageValuesDbDao.getByCategoryId("" + damageCategory.id)
                            .then(possibleValues => {
                                possibleValues.forEach(possibleValue => {
                                    damageCategory.possibleValues.push(possibleValue);
                                });
                            }));
                    }

                    return Promise.all(hydratationPromises)
                        .then(() => {
                            return damageCategory;
                        });
                })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    public getTableName(): string {
        return DamageCategory.TABLENAME;
    }

    protected rowToModel(row: any): DamageCategory {
        let damageCategory = new DamageCategory();

        damageCategory.id = row.id;
        damageCategory.wording = row.wording;

        return damageCategory;
    }

    public save(damageCategory: DamageCategory): Promise<DamageCategory> {
        let promises = [];
        damageCategory.possibleValues.forEach(possibleValue => {
            promises.push(this.damageValuesDbDao.save(possibleValue));
        });

        return Promise.all(promises).then(value => {
            let query = "INSERT OR REPLACE INTO " + DamageCategory.TABLENAME + " ("
                        + "id, wording"
                        + ") VALUES ("
                        + this.getValue(damageCategory.id)
                        + this.getValue(damageCategory.wording, true)
                        + ");";

            return this.sqlProvider.query(query)
                .then(response => {
                    return damageCategory;
                })
                .catch(reason => {
                    this.logSqlError(reason);
                    return null;
                });
        });
    }
}
