import { Injectable } from "@angular/core";
import { FaceDto } from "../../../../dto/inspection/v2/face.dto";
import { ServerConnection } from "../../../../http/serverConnection";

@Injectable({
    providedIn: "root",
})
export class InspectionFacesWsDaoV2 {
    static WS = "inspection-faces";

    public getList(serverConnection: ServerConnection): Promise<FaceDto[]> {
        return new Promise<FaceDto[]>((resolve, reject) => {
            let tokens = new Map<string, string>();

            serverConnection.get(this.constructor.name, InspectionFacesWsDaoV2.WS + "?active=true&_limit=NOLIMIT", tokens)
                .then(response => {
                    let faceDtos: FaceDto[] = [];

                    if (response.body instanceof Array) {
                        const array = (response.body as Array<any>);
                        array.forEach(value => {
                            let faceDto = FaceDto.fromBody(value, "");
                            faceDtos.push(faceDto);
                        });
                    }

                    resolve(faceDtos);
                })
                .catch(reason => {
                    reject(reason);
                });
        });
    }
}
