import { InAppBrowser, InAppBrowserEvent, InAppBrowserObject } from "@awesome-cordova-plugins/in-app-browser/ngx";
import { TranslateService } from "@ngx-translate/core";
import { sha256 } from "js-sha256";
import { DateHelper } from "../../../gyzmo-commons/helpers/date.helper";
import { DeviceHelper } from "../../../gyzmo-commons/helpers/device.helper";
import { KyHelper } from "../../../gyzmo-commons/helpers/ky.helper";
import { LoadingHelper } from "../../../gyzmo-commons/helpers/loading.helper";
import { DateProvider } from "../../../gyzmo-commons/interfaces/dateProvider";
import { AvailabilityDto } from "../../dto/availability.dto";
import { RentParamsDto } from "../../dto/rentParams.dto";
import { UserDto } from "../../dto/user.dto";

export abstract class SwiklyService {
    protected constructor(private deviceHelper: DeviceHelper,
                          private loadingHelper: LoadingHelper,
                          private translateService: TranslateService,
                          private dateProvider: DateProvider,
                          private inAppBrowser: InAppBrowser) {
    }

    abstract get SWIKLY_URL(): string;

    abstract get USER_ID(): string;

    abstract get SECRET(): string;

    abstract get LINK_ID(): string;

    public async checkout(availability: AvailabilityDto, rentParams: RentParamsDto, user: UserDto): Promise<any> {
        if (this.deviceHelper.isRunningOnDevice()) {
            await this.loadingHelper.showLoading();
        }

        return new Promise<boolean>(async (resolve, reject) => {
            let ky = KyHelper.getNewKy(Number(user.id), 1, this.dateProvider.now());

            let tokens = new Map<string, string>();
            tokens.set("overviewDisplay", "0");
            tokens.set("startDate", DateHelper.tryToISO(rentParams.startDate));
            tokens.set("endDate", DateHelper.tryToISO(rentParams.plannedReturnDate));
            tokens.set("description", this.translateService.instant("Merci de finaliser votre réservation"));
            tokens.set("firstName", user.thirdParty.firstName);
            tokens.set("lastName", user.thirdParty.lastName);
            tokens.set("email", user.thirdParty.mail.data);
            tokens.set("phone", user.thirdParty.mobile.data);
            tokens.set("sendEmail", "false");
            tokens.set("forcedClientFee", "");
            tokens.set("language", "FR");
            tokens.set("amountDeposit", "" + availability.bestDeal.deposit); // caution
            tokens.set("amountPayment", "" + availability.bestDeal.includingTaxAmount); // payment
            tokens.set("amountReservation", "");
            tokens.set("cityTax", "");
            tokens.set("freeText", "");
            tokens.set("redirectURL", "http://localhost:8080/");
            tokens.set("userId", this.USER_ID);
            tokens.set("linkId", this.LINK_ID);
            tokens.set("id", ky);
            tokens.set("ctrlKey", this.getCtrlKey(tokens));

            try {
                let browser: InAppBrowserObject = this.inAppBrowser.create(this.formatUrl(this.SWIKLY_URL, tokens),
                    "_blank",
                    "location=no,zoom=no,hidenavigationbuttons=yes,hideurlbar=yes");

                if (this.deviceHelper.isRunningOnDevice()) {
                    browser.on("loadstart").subscribe((event: InAppBrowserEvent) => {
                        let url = "" + event.url;
                        if (url.startsWith(tokens.get("redirectURL"))) {
                            browser.close();

                            if (url.indexOf("status=ok") >= 0) {
                                resolve(true);
                            } else {
                                resolve(false);
                            }
                        }
                    });
                    browser.on("loadstop").subscribe((event: InAppBrowserEvent) => {
                        this.loadingHelper.hideAll();
                    });
                    browser.on("loaderror").subscribe((event: InAppBrowserEvent) => {
                    });
                }
            } catch (e) {
            }
        });
    }

    private getCtrlKey(tokens: Map<string, string>): string {
        let tempString = ""
                         + tokens.get("forcedClientFee")
                         + tokens.get("amountDeposit")
                         + tokens.get("amountReservation")
                         + tokens.get("amountPayment")
                         + tokens.get("cityTax")
                         + tokens.get("userId")
                         + tokens.get("linkId")
                         + tokens.get("freeText")
                         + this.SECRET;

        return sha256(tempString);
    }

    private formatUrl(urlPattern: string, tokens: Map<string, string>) {
        if (!tokens) {
            return urlPattern;
        }

        let url = urlPattern.slice() + "/?"; // clone
        let i = 0;

        tokens.forEach((value, key) => {
            url += key + "=" + value;

            if (i < (tokens.size - 1)) {
                url += "&";
            }

            i++;
        });

        return url;
    }
}

