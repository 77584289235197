import { Component, Input } from "@angular/core";
import { DateTime } from "luxon";
import { StcDto } from "../../../core/dto/stc.dto";
import { StcStatuses } from "../../../core/dto/stcStatuses";
import { SHOW_DEBUG_INFO } from "../../app.constants";

@Component({
    selector: "stc-line",
    templateUrl: "stc-line.html",
    styleUrls: ["stc-line.scss"],
})
export class StcLineComponent {
    @Input() stc: StcDto;
    StcStatuses = StcStatuses;
    showDebugInfo: boolean = SHOW_DEBUG_INFO;

    getReturnDate(): DateTime {
        if (this.stc.status == StcStatuses.CONTRACT_WAITING_FOR_BILLING
            || this.stc.isClosed) {
            return this.stc.returnDate;
        } else {
            return this.stc.plannedReturnDate;
        }
    }

    getRealMileage() {
        if (this.stc.status == StcStatuses.CONTRACT_WAITING_FOR_BILLING
            || this.stc.isClosed) {
            return this.stc.movement.returnMileage - this.stc.movement.startMileage;
        } else {
            return this.stc.wantedMileage;
        }
    }
}
