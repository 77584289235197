import { registerLocaleData } from "@angular/common";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import localeFr from "@angular/common/locales/fr";
import { LOCALE_ID, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";
import { File } from "@awesome-cordova-plugins/file/ngx";
import { FileOpener } from "@awesome-cordova-plugins/file-opener/ngx";
import { HTTP } from "@awesome-cordova-plugins/http/ngx";
import { InAppBrowser } from "@awesome-cordova-plugins/in-app-browser/ngx";
import { LocationAccuracy } from "@awesome-cordova-plugins/location-accuracy/ngx";
import { Network } from "@awesome-cordova-plugins/network/ngx";
import { PhotoViewer } from "@awesome-cordova-plugins/photo-viewer/ngx";
import { SocialSharing } from "@awesome-cordova-plugins/social-sharing/ngx";
import { SQLite } from "@awesome-cordova-plugins/sqlite/ngx";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { MissingTranslationHandler, MissingTranslationHandlerParams, TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { Logger, LoggingService, LoggingServiceConfiguration } from "ionic-logging-service";
import { DateTime } from "luxon";
import { AddressDbDao } from "../core/dao/db/address.db.dao";
import { AttachmentDbDao } from "../core/dao/db/attachment.db.dao";
import { ContactDetailsDbDao } from "../core/dao/db/contactDetails.db.dao";
import { DaoModule } from "../core/dao/db/dao.module";
import { DriverInfoDbDao } from "../core/dao/db/driverInfo.db.dao";
import { EquipmentDbDao } from "../core/dao/db/equipment.db.dao";
import { GeneralPhotoTypeDbDao } from "../core/dao/db/generalPhotoType.db.dao";
import { DamageCategoryDbDao } from "../core/dao/db/inspection/common/damageCategory.db.dao";
import { DamageValuesDbDao } from "../core/dao/db/inspection/common/damageValues.db.dao";
import { MovementDbDao } from "../core/dao/db/movement.db.dao";
import { ServerDbDao } from "../core/dao/db/server.db.dao";
import { StcDbDao } from "../core/dao/db/stc.db.dao";
import { ThirdPartyDbDao } from "../core/dao/db/thirdParty.db.dao";
import { UserDbDao } from "../core/dao/db/user.db.dao";
import { AttachmentWsDao } from "../core/dao/ws/attachment.ws.dao";
import { CompanyWsDao } from "../core/dao/ws/company.ws.dao";
import { ContactDetailsWsDao } from "../core/dao/ws/contactDetails.ws.dao";
import { EquipmentWsDao } from "../core/dao/ws/equipment.ws.dao";
import { GeneralPhotoTypeWsDao } from "../core/dao/ws/generalPhotoType.ws.dao";
import { DamageCategoryWsDao } from "../core/dao/ws/inspection/common/damageCategory.ws.dao";
import { InspectionWsDaoV2 } from "../core/dao/ws/inspection/v2/inspection.ws.dao";
import { MovementWsDao } from "../core/dao/ws/movement.ws.dao";
import { StcWsDao } from "../core/dao/ws/stc.ws.dao";
import { ThirdPartyWsDao } from "../core/dao/ws/thirdParty.ws.dao";
import { UserWsDao } from "../core/dao/ws/user.ws.dao";
import { WsModule } from "../core/dao/ws/ws.module";
import { HttpErrorHandler } from "../core/http/httpErrorHandler";
import { ServersConnectionsProvider } from "../core/providers/serversConnections.provider";
import { AttachmentService } from "../core/services/attachment.service";
import { AvailabilitiesService } from "../core/services/availabilities.service";
import { SwiklyService } from "../core/services/base/swikly.service";
import { CompanyService } from "../core/services/company.service";
import { ContactDetailsService } from "../core/services/contactDetails.service";
import { EquipmentService } from "../core/services/equipment.service";
import { FileService } from "../core/services/file.service";
import { GeneralPhotoTypeService } from "../core/services/generalPhotoType.service";
import { DamageCategoryService } from "../core/services/inspection/common/damageCategory.service";
import { InspectionServiceV2 } from "../core/services/inspection/v2/inspection.service";
import { OpenFleetService } from "../core/services/openFleet.service";
import { SandBoxSwiklyService } from "../core/services/sandBoxSwikly.service";
import { StcService } from "../core/services/stc.service";
import { ThirdPartyService } from "../core/services/thirdParty.service";
import { UserService } from "../core/services/user.service";
import { AvailabilitiesWs } from "../core/ws/availabilities.ws";
import { BestDealWs } from "../core/ws/bestDeal.ws";
import { DateWs } from "../core/ws/date.ws";
import { OpenFleetWs } from "../core/ws/openFleet.ws";
import { OpenFleetV2Ws } from "../core/ws/openFleetV2.ws";
import { ThirdPartyTypesWs } from "../core/ws/thirdPartyTypes.ws";
import { environment } from "../environments/environment";
import { KeyValueDbDao } from "../gyzmo-commons/dao/db/keyValue.db.dao";
import { VersionDbDao } from "../gyzmo-commons/dao/db/version.db.dao";
import { DirectivesModule } from "../gyzmo-commons/directives/directives.module";
import { GtagModule } from "../gyzmo-commons/gtag/gtag.module";
import { AssetsHelper } from "../gyzmo-commons/helpers/assets.helper";
import { DeviceHelper, PlatformName } from "../gyzmo-commons/helpers/device.helper";
import { TranslatePoHttpLoader } from "../gyzmo-commons/helpers/i18n/translatePoHttpLoader";
import { LoadingHelper } from "../gyzmo-commons/helpers/loading.helper";
import { OpenMapsHelper } from "../gyzmo-commons/helpers/openMaps.helper";
import { PipesModule } from "../gyzmo-commons/helpers/pipes/pipes.module";
import { ToastHelper } from "../gyzmo-commons/helpers/toast.helper";
import { AngularHttpClient } from "../gyzmo-commons/http/angularHttpClient";
import { AngularHttpClientWithMock } from "../gyzmo-commons/http/angularHttpClientWithMock";
import { NativeHttpClient } from "../gyzmo-commons/http/nativeHttpClient";
import { DateProvider } from "../gyzmo-commons/interfaces/dateProvider";
import { DrawModalModule } from "../gyzmo-commons/modals/draw/draw.module";
import { BrowserCameraModalModule } from "../gyzmo-commons/pages/browser-camera/browserCamera.module";
import { AppSqlProvider } from "../gyzmo-commons/persistence/app.sql.provider";
import { Database } from "../gyzmo-commons/persistence/database";
import { HttpClientProvider } from "../gyzmo-commons/providers/httpClientProvider";
import { MockDateProvider } from "../gyzmo-commons/providers/mockDateProvider";
import { RealDateProvider } from "../gyzmo-commons/providers/realDateProvider";
import { AppVersionService } from "../gyzmo-commons/services/appVersion.service";
import { CacheService } from "../gyzmo-commons/services/cache.service";
import { CameraService } from "../gyzmo-commons/services/camera.service";
import { GeocodingService } from "../gyzmo-commons/services/geocoding.service";
import { FrenchGovernmentApiGeocoding } from "../gyzmo-commons/services/geocodings/frenchGovernmentApi.geocoding";
import { OpenStreetMapGeocoding } from "../gyzmo-commons/services/geocodings/openStreetMap.geocoding";
import { GeolocationService } from "../gyzmo-commons/services/geolocation.service";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { APP_OPTIONS, MODULE } from "./app.constants";
import { AvailabilityLineModule } from "./components/availability-line/availability-line.module";
import { ChecklistLineModule } from "./components/cheklist-line/checklist-line.module";
import { MapModule } from "./components/map-openLayers/map.module";
import { StcInfoModule } from "./components/stc-info/stc-info.module";
import { StcLineModule } from "./components/stc-line/stc-line.module";
import { VehicleDetailsEquipmentsModule } from "./components/vehicle-details-equipments/vehicle-details-equipments.module";
import { CreateDamageModalModuleV2 } from "./inspection/V2/modal/create-damage/create-damage.module";
import { ViewDamageModalModuleV2 } from "./inspection/V2/modal/view-damages/view-damages.module";
import { PopoversModule } from "./popovers/popovers.module";

registerLocaleData(localeFr);

export function createTranslateLoader(http: HttpClient) {
    return new TranslatePoHttpLoader(http, "assets/i18n", ".po");
}

export function createLogger(loggingService: LoggingService): Logger {
    let loggerName = "[" + MODULE + "]";
    // logLevel: 'DEBUG' pour voir les requetes SQL
    let config: LoggingServiceConfiguration = { logLevels: [{ loggerName: loggerName, logLevel: environment.production ? "INFO" : "DEBUG" }] };
    loggingService.configure(config);
    return loggingService.getLogger(loggerName);
}

export function createHttpClient(logger: Logger,
                                 httpClient: HttpClient,
                                 assetsHelper: AssetsHelper,
                                 keyValueDbDao: KeyValueDbDao) {
    return environment.testConfig ? new AngularHttpClientWithMock(logger, httpClient, assetsHelper, keyValueDbDao) : new AngularHttpClient(logger, httpClient);
}

function createDateProvider() {
    return environment.testConfig ? new MockDateProvider(DateTime.local(2021, 7, 12, 15, 50)) : new RealDateProvider();
}

function getAppOptions() {
    let appOptions = APP_OPTIONS;

    if (environment.testConfig && !appOptions["mode"]) {
        appOptions.animated = false;

        switch (environment.testConfig.platform) {
            case PlatformName.IOS:
                appOptions.mode = "ios";
                break;
            default:
                appOptions.mode = "md";
        }
    }

    return appOptions;
}

export class DefaultMissingTranslationHandler implements MissingTranslationHandler {
    handle(params: MissingTranslationHandlerParams) {
        return "~" + params.key;
    }
}

@NgModule({
    declarations: [AppComponent],
    entryComponents: [],
    imports: [
        BrowserModule,
        HttpClientModule,
        PipesModule,
        DirectivesModule,
        PopoversModule,
        DaoModule,
        WsModule,
        AppRoutingModule,
        BrowserCameraModalModule,
        DrawModalModule,
        MapModule,
        StcInfoModule,
        StcLineModule,
        AvailabilityLineModule,
        ChecklistLineModule,
        VehicleDetailsEquipmentsModule,
        CreateDamageModalModuleV2,
        ViewDamageModalModuleV2,
        TranslateModule.forRoot({
            missingTranslationHandler: { provide: MissingTranslationHandler, useClass: DefaultMissingTranslationHandler },
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient],
            },
        }),
        GtagModule,
        IonicModule.forRoot(getAppOptions()),
    ],
    providers: [
        AddressDbDao,
        AppVersionService,
        AssetsHelper,
        AttachmentDbDao,
        AttachmentService,
        AttachmentWsDao,
        AvailabilitiesService,
        AvailabilitiesWs,
        CameraService,
        CompanyService,
        CompanyWsDao,
        ContactDetailsDbDao,
        ContactDetailsService,
        ContactDetailsWsDao,
        DamageValuesDbDao,
        DamageCategoryDbDao,
        DamageCategoryWsDao,
        DamageCategoryService,
        Database,
        DateWs,
        DeviceHelper,
        DriverInfoDbDao,
        EquipmentDbDao,
        EquipmentService,
        EquipmentWsDao,
        File,
        FileOpener,
        FileService,
        GeneralPhotoTypeDbDao,
        GeneralPhotoTypeWsDao,
        GeneralPhotoTypeService,
        HttpErrorHandler,
        InAppBrowser,
        InspectionServiceV2,
        InspectionWsDaoV2,
        KeyValueDbDao,
        LoadingHelper,
        LocationAccuracy,
        MovementDbDao,
        MovementWsDao,
        OpenFleetService,
        OpenFleetWs,
        OpenFleetV2Ws,
        OpenMapsHelper,
        PhotoViewer,
        ServerDbDao,
        ServersConnectionsProvider,
        StcDbDao,
        StcService,
        StcService,
        StcWsDao,
        ThirdPartyDbDao,
        ThirdPartyService,
        ThirdPartyTypesWs,
        ThirdPartyWsDao,
        ToastHelper,
        AppSqlProvider,
        UserDbDao,
        UserService,
        UserWsDao,
        VersionDbDao,
        BestDealWs,
        CacheService,
        HttpClientProvider,
        HTTP,
        NativeHttpClient,
        SQLite,
        Network,
        GeocodingService,
        GeolocationService,
        SocialSharing,
        OpenStreetMapGeocoding,
        FrenchGovernmentApiGeocoding,
        { provide: AngularHttpClient, useFactory: createHttpClient, deps: [Logger, HttpClient, AssetsHelper, KeyValueDbDao] },
        { provide: DateProvider, useFactory: createDateProvider },
        { provide: Logger, useFactory: createLogger, deps: [LoggingService] },
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: SwiklyService, useClass: SandBoxSwiklyService },
        { provide: LOCALE_ID, useValue: "fr-FR" },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
}
