import { Injectable } from "@angular/core";
import { Logger } from "ionic-logging-service";
import { DbDaoBase } from "../../../../../gyzmo-commons/dao/db/base/db.dao.base";
import { AppSqlProvider } from "../../../../../gyzmo-commons/persistence/app.sql.provider";
import { GroupCategory } from "../../../../models/inspection/common/groupCategory.model";

@Injectable({
    providedIn: "root",
})
export class GroupCategoryDbDao extends DbDaoBase<GroupCategory> {

    constructor(
        logger: Logger,
        private sqlProvider: AppSqlProvider) {
        super(logger);
    }

    public async createIndexes(): Promise<void> {
        let query = "CREATE INDEX IF NOT EXISTS idx_" + GroupCategory.TABLENAME + "_id"
                    + " ON " + GroupCategory.TABLENAME + "(id);";

        await this.sqlProvider.query(query)
            .catch(reason => {
                this.logSqlError(reason);
            });
    }

    public createTable(): Promise<void> {
        let query = "CREATE TABLE IF NOT EXISTS " + GroupCategory.TABLENAME
                    + " ("
                    + "id TEXT PRIMARY KEY,"
                    + "wording TEXT,"
                    + "_order TEXT"
                    + ");";

        return this.sqlProvider.query(query)
            .then(async () => {
                await this.createIndexes();
                return;
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    delete(id: string): Promise<any> {
        let selectQuery = "DELETE FROM " + GroupCategory.TABLENAME + " WHERE id='" + id + "';";
        return this.sqlProvider.query(selectQuery);
    }

    deleteAll(): Promise<any> {
        let selectQuery = "DELETE FROM " + GroupCategory.TABLENAME + ";";
        return this.sqlProvider.query(selectQuery);
    }

    public get(id: string, hydrate: boolean = false): Promise<GroupCategory> {
        let selectQuery = "SELECT * FROM " + GroupCategory.TABLENAME + " WHERE id = '" + id + "';";

        return this.sqlProvider.query(selectQuery)
            .then(
                data => {
                    if (data.rows.length <= 0) {
                        return null;
                    }

                    let groupCategory: GroupCategory = this.rowToModel(data.rows[0]);

                    let hydratationPromises = [];
                    return Promise.all(hydratationPromises)
                        .then(() => {
                            return groupCategory;
                        });
                })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    public getTableName(): string {
        return GroupCategory.TABLENAME;
    }

    protected rowToModel(row: any): GroupCategory {
        let groupCategory = new GroupCategory();

        groupCategory.id = row.id;
        groupCategory.wording = row.wording;
        groupCategory.order = row._order;

        return groupCategory;
    }

    public save(groupCategory: GroupCategory): Promise<GroupCategory> {
        let promises = [];
        return Promise.all(promises).then(value => {
            let query = "INSERT OR REPLACE INTO " + GroupCategory.TABLENAME + " (id, wording, _order) VALUES ("
                        + this.getValue(groupCategory.id)
                        + this.getValue(groupCategory.wording)
                        + this.getValue(groupCategory.order, true)
                        + ");";

            return this.sqlProvider.query(query)
                .then(response => {
                    return groupCategory;
                })
                .catch(reason => {
                    this.logSqlError(reason);
                    return null;
                });
        });
    }
}
