import { CurrencyHelper } from "../../gyzmo-commons/helpers/currency.helper";
import { NotImplementedError } from "../../gyzmo-commons/helpers/NotImplementedError";
import { BaseDto } from "./base/base.dto";
import { ServerDto } from "./server.dto";

export class BestDealDto extends BaseDto<void> {
    public vatAmount: number = 0;
    public includingTaxAmount: number = 0;
    public excludingTaxAmount: number = 0;
    public discountRate: number = 0;
    public deposit: number = 0;

    public pricingMethod: any = {};
    public pricing: any = {};
    public category: any = {};

    public static fromBody(body: any, serverDto: ServerDto): BestDealDto {
        let bestDealDto = new BestDealDto();

        bestDealDto.deposit = CurrencyHelper.toPriceInCents(body.deposit);

        bestDealDto.vatAmount = CurrencyHelper.toPriceInCents(body.vatAmount);
        bestDealDto.includingTaxAmount = CurrencyHelper.toPriceInCents(body.includingTaxAmount);
        bestDealDto.excludingTaxAmount = CurrencyHelper.toPriceInCents(body.excludingTaxAmount);

        if (body.pricingMethod) {
            bestDealDto.pricingMethod = { id: BaseDto.getId(body.pricingMethod) };
        }

        if (body.pricing) {
            bestDealDto.pricing = { id: BaseDto.getId(body.pricing) };
        }

        if (body.category) {
            bestDealDto.category = { id: BaseDto.getId(body.category) };
        }

        if (body.discountRate) {
            bestDealDto.discountRate = body.discountRate;
        }

        return bestDealDto;
    }

    public toModel(): void {
        throw new NotImplementedError();
    }

    public toBody() {
        throw new NotImplementedError();
    }
}
