import { GeneralPhotoType } from "../models/generalPhotoType.model";
import { BaseDto } from "./base/base.dto";

export class GeneralPhotoTypeDto extends BaseDto<GeneralPhotoType> {
    public id: string = "";
    public wording: string = "";
    public order: string = "";
    public filter: string = "";

    static fromBody(body: any): GeneralPhotoTypeDto {
        let generalPhotoTypeDto = new GeneralPhotoTypeDto();

        generalPhotoTypeDto.id = body.id;
        generalPhotoTypeDto.wording = body.wording;
        generalPhotoTypeDto.order = body.order;
        generalPhotoTypeDto.filter = body.filterA;

        return generalPhotoTypeDto;
    }

    static fromModel(generalPhotoType: GeneralPhotoType) {
        let generalPhotoTypeDto = new GeneralPhotoTypeDto();

        generalPhotoTypeDto.id = generalPhotoType.id;
        generalPhotoTypeDto.wording = generalPhotoType.wording;
        generalPhotoTypeDto.order = generalPhotoType.order;
        generalPhotoTypeDto.filter = generalPhotoType.filter;

        return generalPhotoTypeDto;
    }

    public toModel(): GeneralPhotoType {
        let generalPhotoType = new GeneralPhotoType();

        generalPhotoType.id = this.id;
        generalPhotoType.wording = this.wording;
        generalPhotoType.order = this.order;
        generalPhotoType.filter = this.filter;

        return generalPhotoType;
    }

    public toBody() {
        const clone: any = { ...this };

        return clone;
    }
}
