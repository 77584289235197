import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { OpenFleetEquipmentInformationDao } from "../dao/openFleetEquipmentInformation.dao";
import { EquipmentDto } from "../dto/equipment.dto";
import { ServersConnectionsProvider } from "../providers/serversConnections.provider";
import { OpenFleetWs } from "../ws/openFleet.ws";
import { OpenFleetV2Ws } from "../ws/openFleetV2.ws";

@Injectable({
    providedIn: "root",
})
export class OpenFleetService {

    constructor(private openFleetWs: OpenFleetWs,
                private openFleetV2Ws: OpenFleetV2Ws,
                private serversConnectionsProvider: ServersConnectionsProvider) {
    }

    public getEquipmentInfo(equipment: EquipmentDto): Promise<OpenFleetEquipmentInformationDao> {
        if (equipment.trackerVersion == 2) {
            return this.openFleetV2Ws.getEquipmentInfo(
                this.serversConnectionsProvider.getServerConnection(),
                equipment.id);
        } else {
            return this.openFleetWs.getEquipmentInfo(
                this.serversConnectionsProvider.getServerConnection(),
                equipment.id);
        }
    }

    public setAntiStartup(equipment: EquipmentDto, state: boolean): Promise<void> {
        if (environment.testConfig) {
            return new Promise<void>(resolve => resolve());
        } else {
            if (equipment.trackerVersion == 2) {
                return this.openFleetV2Ws.setAntiStartup(
                    this.serversConnectionsProvider.getServerConnection(),
                    equipment.id,
                    state)
                    .catch(reason => {
                        return;
                    });
            } else {
                return this.openFleetWs.setAntiStartup(
                    this.serversConnectionsProvider.getServerConnection(),
                    equipment.id,
                    state)
                    .catch(reason => {
                        return;
                    });
            }
        }
    }

    public toggleDoorsLocker(equipment: EquipmentDto): Promise<void> {
        if (environment.testConfig) {
            return new Promise<void>(resolve => resolve());
        } else {
            if (equipment.trackerVersion == 2) {
                return this.openFleetV2Ws.toggleDoorsLocker(
                    this.serversConnectionsProvider.getServerConnection(),
                    equipment.id)
                    .catch(reason => {
                        return;
                    });
            } else {
                return this.openFleetWs.toggleDoorsLocker(
                    this.serversConnectionsProvider.getServerConnection(),
                    equipment.id)
                    .catch(reason => {
                        return;
                    });
            }
        }
    }

    public updatePosition(equipment: EquipmentDto): Promise<void> {
        if (environment.testConfig) {
            return new Promise<void>(resolve => resolve());
        } else {
            if (equipment.trackerVersion == 2) {
                return this.openFleetV2Ws.updatePosition(
                    this.serversConnectionsProvider.getServerConnection(),
                    equipment.id)
                    .catch(reason => {
                        return;
                    });
            } else {
                return this.openFleetWs.updatePosition(
                    this.serversConnectionsProvider.getServerConnection(),
                    equipment.id)
                    .catch(reason => {
                        return;
                    });
            }
        }
    }
}
