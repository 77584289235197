import { Injectable } from "@angular/core";
import { InspectionWsDaoV1 } from "src/core/dao/ws/inspection/v1/inspection.ws.dao";
import { environment } from "../../../../environments/environment";
import { KeyValueDbDao } from "../../../../gyzmo-commons/dao/db/keyValue.db.dao";
import { CacheService } from "../../../../gyzmo-commons/services/cache.service";
import { GeneralParametersWs } from "../../../../gyzmo-commons/ws/generalParameters.ws";
import { DamageValuesDbDao } from "../../../dao/db/inspection/common/damageValues.db.dao";
import { InspectionWsDaoV2 } from "../../../dao/ws/inspection/v2/inspection.ws.dao";
import { DamageValueDto } from "../../../dto/inspection/common/damageValue.dto";
import { InspectionDto } from "../../../dto/inspection/v2/inspection.dto";
import { StcDto } from "../../../dto/stc.dto";
import { StcStatuses } from "../../../dto/stcStatuses";
import { ServerConnection } from "../../../http/serverConnection";
import { ServersConnectionsProvider } from "../../../providers/serversConnections.provider";
import { StcService } from "../../stc.service";
import { InspectionServiceBase } from "../common/inspection.service.base";

@Injectable({
    providedIn: "root",
})
export class InspectionServiceV2 extends InspectionServiceBase {
    constructor(generalParametersWs: GeneralParametersWs,
                keyValueDbDao: KeyValueDbDao,
                private inspectionWsDao: InspectionWsDaoV2,
                private imgCacheService: CacheService,
                private stcService: StcService,
                private damageValuesDbDao: DamageValuesDbDao,
                serversConnectionsProvider: ServersConnectionsProvider,
                inspectionWsDaoV1: InspectionWsDaoV1) {
        super(generalParametersWs, keyValueDbDao, inspectionWsDaoV1, serversConnectionsProvider);
    }

    public save(stc: StcDto, inspection: InspectionDto) {
        if (environment.testConfig) {
            switch (stc.status) {
                case StcStatuses.RESERVATION:
                    stc.status = StcStatuses.CONTRACT;
                    break;
                case StcStatuses.CONTRACT:
                    stc.status = StcStatuses.CONTRACT_WAITING_FOR_BILLING;
                    break;
            }

            this.stcService.saveToMockCache(stc);
        }

        return this.inspectionWsDao.save(this.serversConnectionsProvider.getServerConnection(), inspection);
    }

    public async getById(serverConnection: ServerConnection, inspectionId: string): Promise<InspectionDto> {
        let inspectionDto = await this.inspectionWsDao.getById(serverConnection, inspectionId);

        await this.fillChecklistsPossibleValues(inspectionDto);

        for (let face of inspectionDto.faces) {
            face.picture = (await this.imgCacheService.getCached("face/" + face.id)).value;
        }

        return inspectionDto;
    }

    private async fillChecklistsPossibleValues(inspection: InspectionDto) {
        for (const checklist of inspection.checklists) {
            for (const element of checklist.elements) {
                element.possibleValues = await this.damageValuesDbDao.getByCategoryId(element.checklistModelId)
                    .then(damageValues => {
                        let result = [];

                        damageValues.forEach(damageValue => {
                            result.push(DamageValueDto.fromModel(damageValue));
                        });

                        return result;
                    });
            }
        }
    }
}
