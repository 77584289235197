import { Component, ViewChild } from "@angular/core";
import { SplashScreen } from "@capacitor/splash-screen";
import { IonRouterOutlet, Platform } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { Logger } from "ionic-logging-service";
import { Settings } from "luxon";
import { HttpErrorHandler } from "../core/http/httpErrorHandler";
import { DaoProvider } from "../core/persistence/dao.provider";
import { ServersConnectionsProvider } from "../core/providers/serversConnections.provider";
import { DefaultDataDownloaderService } from "../core/services/defaultDataDownloader.service";
import { FileService } from "../core/services/file.service";
import { OfflineModeService } from "../core/services/offlineMode.service";
import { ThirdPartyService } from "../core/services/thirdParty.service";
import { UserService } from "../core/services/user.service";
import { environment } from "../environments/environment";
import { KeyValueDbDao } from "../gyzmo-commons/dao/db/keyValue.db.dao";
import { VersionDto } from "../gyzmo-commons/dtos/version.dto";
import { GtagService } from "../gyzmo-commons/gtag/gtag.service";
import { DeviceHelper } from "../gyzmo-commons/helpers/device.helper";
import { NavControllerExtended } from "../gyzmo-commons/helpers/navControllerExtended";
import { NetworkHelper } from "../gyzmo-commons/helpers/network.helper";
import { isNullOrEmpty } from "../gyzmo-commons/helpers/null.helper";
import { ToastHelper } from "../gyzmo-commons/helpers/toast.helper";
import { VersionHelper } from "../gyzmo-commons/helpers/version.helper";
import { DatabaseLocation } from "../gyzmo-commons/interfaces/databaseLocation";
import { KeyValue } from "../gyzmo-commons/models/keyValue.model";
import { AppSqlProvider } from "../gyzmo-commons/persistence/app.sql.provider";
import { Database } from "../gyzmo-commons/persistence/database";
import { AppVersionService } from "../gyzmo-commons/services/appVersion.service";
import { CacheService } from "../gyzmo-commons/services/cache.service";
import { DatabaseVersionService } from "../gyzmo-commons/services/databaseVersion.service";
import { LanguageService } from "../gyzmo-commons/services/language.service";
import { MODULE } from "./app.constants";

@Component({
    selector: "app-root",
    templateUrl: "app.component.html",
    styleUrls: ["app.component.scss"],
})
export class AppComponent {
    @ViewChild(IonRouterOutlet, { static: true }) routerOutlet: IonRouterOutlet;
    isInitialized = false;
    logout = false;
    login: { user: string; password: string } = null;

    constructor(private platform: Platform,
                private logger: Logger,
                private appSqlProvider: AppSqlProvider,
                private database: Database,
                private navControllerExtended: NavControllerExtended,
                private deviceHelper: DeviceHelper,
                private networkHelper: NetworkHelper,
                private appVersionService: AppVersionService,
                private fileService: FileService,
                private userService: UserService,
                private translateService: TranslateService,
                private toastHelper: ToastHelper,
                private httpErrorHandler: HttpErrorHandler,
                private serversConnectionsProvider: ServersConnectionsProvider,
                private thirdPartyService: ThirdPartyService,
                private languageService: LanguageService,
                private offlineModeService: OfflineModeService,
                private daoProvider: DaoProvider,
                private cacheService: CacheService,
                private keyValueDbDao: KeyValueDbDao,
                private gtagService: GtagService,
                private databaseVersionService: DatabaseVersionService,
                private defaultDataDownloaderService: DefaultDataDownloaderService) {
        this.logout = !isNullOrEmpty(this.getParameterByName("logout"));
        if (!isNullOrEmpty(this.getParameterByName("login"))) {
            let param = this.getParameterByName("login").split(/[ +]/);
            this.login = { user: param[0], password: param[1] };
        }

        this.platform.ready()
            .then(async () => {
                await this.initialize();
                await this.start();
            });
    }

    async initialize() {
        await this.deviceHelper.initialize();
        await this.networkHelper.initialize();

        this.logger.info(this.constructor.name, "App is running on ", this.deviceHelper.getPlatform());

        // noinspection ES6MissingAwait
        this.gtagService.event("platform", { event_label: "" + this.deviceHelper.getPlatform() });

        if (!environment.production) {
            // noinspection ES6MissingAwait
            this.gtagService.event("debug_" + ("" + this.deviceHelper.getPlatform()).toLocaleLowerCase());
        }

        await this.initializeSql();

        this.logger.info(this.constructor.name, "User languages : " + this.languageService.getUserLanguages());

        // this language will be used as a fallback when a translation isn't found in the current language
        this.translateService.setDefaultLang("en");

        // the lang to use, if the lang isn't available, it will use the current loader to get them
        this.changeLanguage(this.languageService.getUserLanguage());

        this.fileService.initialiseFileSystem();

        this.navControllerExtended.initialize(this.routerOutlet);

        await this.serversConnectionsProvider.initialize();

        this.httpErrorHandler.getUserDisconnectedObservable()
            .subscribe(() => {
                this.userService.disconnect(false)
                    .then(userWasConnected => {
                        if (userWasConnected) {
                            this.toastHelper.show(this.translateService.instant("Vous avez été déconnecté, veuillez-vous reconnecter."), false);
                        }
                        this.navControllerExtended.navigateRoot("/connection");
                    });
            });

        this.isInitialized = true;
        this.logger.info(this.constructor.name, "**************************** App is initialized ****************************");
    }

    private async start() {
        this.offlineModeService.startSynchronisationTask();

        if (this.logout) {
            await this.userService.disconnect(true);
            await this.navControllerExtended.navigateRoot("/connection");
        } else if (this.login) {
            await this.userService.disconnect(false);
            let user = await this.userService.connect(this.login.user, this.login.password);
            this.appSqlProvider.enableBulkWriting();
            await this.defaultDataDownloaderService.onConnectionDefaultData(user);
            await this.appSqlProvider.commitBulk();
            await this.keyValueDbDao.save(new KeyValue("LAST_LOGIN", user.username));
            await this.keyValueDbDao.save(new KeyValue("CGU_" + user.username, "1"));
        } else {
            let user = await this.userService.getCurrentUserWithThirdPartyOnly();
            if (user) {
                if (!user.thirdParty.isActive) {
                    this.thirdPartyService.startAccountIsActiveScanning(user.thirdParty.id);
                }

                let cguAccepted = await this.keyValueDbDao.get("CGU_" + user.username);

                if (cguAccepted == null || cguAccepted.value != "1") {
                    await this.navControllerExtended.navigateRoot("/cgu");
                } else {
                    await this.navControllerExtended.navigateRoot("/my-account");
                }

            } else {
                await this.navControllerExtended.navigateRoot("/connection");
            }

            if (this.deviceHelper.isRunningOnDevice()) {
                // Le setTimeout permet d'attendre que le home soit bien affiché et cache les popover et modals affichés par leur instanciations.
                setTimeout(() => {
                    SplashScreen.hide();
                }, 500);
            }
        }
    }

    private changeLanguage(language: string) {
        this.logger.info(this.constructor.name, "Set language to " + language);
        this.translateService.use(language);
        Settings.defaultLocale = language;
    }

    private getParameterByName(name: any) {
        let url = window.location.href;
        name = name.replace(/[[]]/g, "$&");
        let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return "";
        return decodeURIComponent(results[2].replace(/[+]/g, " "));
    }

    private async initializeSql() {
        // Okay, so the platform is ready and our plugins are available.
        // Here you can do any higher level native things you might need.
        await this.appSqlProvider.initialize(MODULE, DatabaseLocation.default, this.deviceHelper.getDeviceSerial());

        this.logger.info(this.constructor.name, "**************************** SQL is initialized ****************************");

        let currentAppVersion = await this.appVersionService.getAppVersion();
        let currentDbVersion: VersionDto = new VersionDto(0, "0");
        if ((await this.appSqlProvider.getExistingTables()).indexOf("Versions") >= 0) {
            currentDbVersion = await this.databaseVersionService.getDatabaseVersion();
        }
        this.logger.info(this.constructor.name, "Database Version=" + currentDbVersion.version);
        this.logger.info(this.constructor.name, "App Version=" + currentAppVersion.version);

        await this.database.initialize(
            this.daoProvider.getAllDaoList(),
            this.daoProvider.getOnDisconnectDaoList(),
            currentAppVersion,
            currentDbVersion);

        if (currentDbVersion.version != "0" && VersionHelper.compareVersions(currentAppVersion.toDigits(), currentDbVersion.toDigits()) > 0) {
            if (await this.userService.isConnected()) {
                await this.userService.disconnect(false);
            }
        }

        await this.cacheService.cleanCache();
    }
}
