import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { isNullOrEmpty } from "../../../gyzmo-commons/helpers/null.helper";
import { VersionHelper } from "../../../gyzmo-commons/helpers/version.helper";
import { XVEGAID, XVEGAMINVERSION } from "../../../gyzmo-commons/http/header.constant";
import { ThirdPartyDto } from "../../dto/thirdParty.dto";
import { UserDto } from "../../dto/user.dto";
import { ServerConnection } from "../../http/serverConnection";
import { WsDao } from "../../http/wsDao";
import { AttachmentKinds } from "../../interfaces/attachmentKinds";
import { AttachmentDbDao } from "../db/attachment.db.dao";
import { AttachmentWsDao } from "./attachment.ws.dao";
import { ThirdPartyWsDao } from "./thirdParty.ws.dao";

@Injectable({
    providedIn: "root",
})
export class UserWsDao extends WsDao<UserDto> {
    static WS = "users";

    constructor(private thirdPartyWsDao: ThirdPartyWsDao,
                private attachmentDbDao: AttachmentDbDao,
                private attachmentWsDao: AttachmentWsDao) {
        super();
    }

    public connect(serverConnection: ServerConnection, login: string, password: string): Promise<{ token: string, minVersion: number[] }> {
        return new Promise<{ token: string, minVersion: number[] }>((resolve, reject) => {
            let tokens = new Map<string, string>();
            let url = UserWsDao.WS + "/login?details=true";
            if (environment.testConfig) {
                url += "&user=" + login + "&password=" + password;
            }

            serverConnection.post(this.constructor.name, url, tokens, {
                username: login,
                password: password,
            }, false)
                .then(response => {
                    let token = response.body.token;
                    let minVersionString = response.headers.get(XVEGAMINVERSION.toLowerCase());
                    let minVersion = VersionHelper.parseVersion(minVersionString);
                    resolve({ token: token, minVersion: minVersion });
                })
                .catch(reason => {
                    if (reason.status != 0) {
                        reject("Erreur de connexion, veuillez vérifier votre login/mot de passe");
                    } else {
                        reject();
                    }
                });
        });
    }

    public disconnect(serverConnection: ServerConnection, remoteLogout: boolean): Promise<void> {
        return new Promise<void>(async (resolve) => {
            if (remoteLogout) {
                let tokens = new Map<string, string>();
                serverConnection.delete(this.constructor.name, UserWsDao.WS + "/me/logout", tokens)
                    .then(async () => {
                        await serverConnection.setAuthentication(serverConnection.getServerDto().defaultToken);
                        resolve();
                    })
                    .catch(async () => {
                        await serverConnection.setAuthentication(serverConnection.getServerDto().defaultToken);
                        resolve();
                    });
            } else {
                await serverConnection.setAuthentication(serverConnection.getServerDto().defaultToken);
                resolve();
            }
        });
    }

    public save(serverConnection: ServerConnection, userDto: UserDto): Promise<UserDto> {
        return new Promise<UserDto>((resolve, reject) => {
            let tokens = new Map<string, string>();

            if (!isNullOrEmpty(userDto.id)) {
                tokens.set("id", userDto.id);

                serverConnection.put(this.constructor.name, UserWsDao.WS + "/:id", tokens, userDto.toBody())
                    .then(response => {
                        // On redemande le user
                        this.getById(serverConnection, userDto.id)
                            .then(updatedUserDto => {
                                resolve(updatedUserDto);
                            });
                    })
                    .catch(reason => {
                        reject(reason);
                    });
            } else {
                delete userDto.id;

                serverConnection.post(this.constructor.name, UserWsDao.WS + "?createCustomer=true", tokens, userDto.toBody())
                    .then(response => {
                        userDto.id = response.headers.get(XVEGAID.toLowerCase());
                        // On redemande le user
                        this.getById(serverConnection, userDto.id)
                            .then(updatedUserDto => {
                                resolve(updatedUserDto);
                            });
                    })
                    .catch(reason => {
                        reject(reason);
                    });
            }
        });
    }

    public isLoginInUse(serverConnection: ServerConnection, login: string): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            serverConnection.get(this.constructor.name, UserWsDao.WS + "/?search=" + login, null)
                .then(response => {
                    let count = 0;
                    if (response.body && response.body.length) {
                        count = response.body.length;
                    }

                    resolve(count > 0);
                })
                .catch(reason => {
                    if (reason.status == 404) {
                        resolve(false);
                    } else {
                        resolve(true);
                    }
                });
        });
    }

    public checkToken(serverConnection: ServerConnection): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            let tokens = new Map<string, string>();

            serverConnection.get(this.constructor.name, "/technical/token-validation", tokens, false)
                .then(response => {
                    resolve(true);
                })
                .catch(reason => {
                    if (reason.status == 401) {
                        resolve(false);
                    } else {
                        resolve(true);
                    }
                });
        });
    }

    public getById(serverConnection: ServerConnection, id: string): Promise<UserDto> {
        return new Promise<UserDto>((resolve, reject) => {
            let tokens = new Map<string, string>();
            tokens.set("id", id);

            serverConnection.get(this.constructor.name, UserWsDao.WS + "/:id", tokens)
                .then(response => {
                    let user = UserDto.fromBody(response.body);

                    this.thirdPartyWsDao.getById(serverConnection, user.thirdParty.id)
                        .then((thirdParty: ThirdPartyDto) => {
                            user.thirdParty = thirdParty;

                            if (!isNullOrEmpty(user.thirdParty.id)) {
                                this.attachmentWsDao.getByThirdPartyId(serverConnection, user.thirdParty.id, AttachmentKinds.PHOTO)
                                    .then((attachments) => {
                                        if (attachments.length > 0) {
                                            let attachment = attachments[0].toModel();

                                            this.attachmentDbDao.save(attachment)
                                                .then(() => {
                                                    resolve(user);
                                                });
                                        } else {
                                            resolve(user);
                                        }
                                    })
                                    .catch(reason => {
                                        reject(reason);
                                    });
                            } else {
                                resolve(user);
                            }
                        })
                        .catch(reason => {
                            reject(reason);
                        });
                })
                .catch(reason => {
                    reject(reason);
                });
        });
    }

    public updatePassword(serverConnection: ServerConnection, actualPassword: string, newPassword: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            let tokens = new Map<string, string>();

            serverConnection.put(this.constructor.name, UserWsDao.WS + "/me/reset-password", tokens, {
                oldPassword: actualPassword,
                newPassword: newPassword,
            })
                .then(response => {
                    resolve();
                })
                .catch(reason => {
                    reject(reason);
                });
        });
    }

    public lostPassword(serverConnection: ServerConnection, username: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            let tokens = new Map<string, string>();

            serverConnection.put(this.constructor.name, UserWsDao.WS + "/lost-password", tokens, { username: username })
                .then(response => {
                    resolve();
                })
                .catch(reason => {
                    reject(reason);
                });
        });
    }

    requestVerificationCode(serverConnection: ServerConnection, phoneNumber: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            let tokens = new Map<string, string>();
            serverConnection.put(this.constructor.name, UserWsDao.WS + "/request-validation", tokens, { receiver: phoneNumber })
                .then(response => {
                    resolve();
                })
                .catch(reason => {
                    reject(reason);
                });
        });
    }

    checkVerificationCode(serverConnection: ServerConnection, phoneNumber: string, verificationCode: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            let tokens = new Map<string, string>();

            serverConnection.put(this.constructor.name, UserWsDao.WS + "/validate-inscription", tokens, {
                receiver: phoneNumber,
                confirmationCode: verificationCode,
            })
                .then(response => {
                    resolve();
                })
                .catch(reason => {
                    reject(reason);
                });
        });
    }

    public getMe(serverConnection: ServerConnection): Promise<UserDto> {
        return new Promise<UserDto>((resolve, reject) => {
            serverConnection.get(this.constructor.name, UserWsDao.WS + "/me", null)
                .then(response => {
                    let user = UserDto.fromBody(response.body);

                    this.thirdPartyWsDao.getById(serverConnection, user.thirdParty.id)
                        .then((thirdParty: ThirdPartyDto) => {
                            user.thirdParty = thirdParty;

                            this.attachmentWsDao.getByThirdPartyId(serverConnection, user.thirdParty.id, AttachmentKinds.PHOTO)
                                .then((attachments) => {
                                    if (attachments.length > 0) {
                                        let attachment = attachments[0].toModel();

                                        this.attachmentDbDao.save(attachment)
                                            .then(() => {
                                                resolve(user);
                                            });
                                    } else {
                                        resolve(user);
                                    }
                                })
                                .catch(reason => {
                                    reject(reason);
                                });
                        })
                        .catch(reason => {
                            reject(reason);
                        });
                })
                .catch(reason => {
                    reject(reason);
                });
        });
    }
}
