export class KyUnicityProvider {
    static instance: KyUnicityProvider = null;
    private unicity: number;

    private constructor() {
        this.unicity = 0;
    }

    get Unicity(): number {
        return this.unicity;
    }

    public static getInstance(): KyUnicityProvider {
        if (KyUnicityProvider.instance == null) {
            KyUnicityProvider.instance = new KyUnicityProvider();
        }

        return KyUnicityProvider.instance;
    }

    public incrementUnicity() {
        this.unicity++;
    }
}
