import { PictureHelper } from "../../gyzmo-commons/helpers/picture.helper";
import { DATE_NODEJS_FORMAT, PICTURE_QUALITY } from "../../gyzmo-commons/interfaces/constants";
import { DateProvider } from "../../gyzmo-commons/interfaces/dateProvider";
import { AttachmentDto } from "../dto/attachment.dto";
import { AttachmentKinds } from "../interfaces/attachmentKinds";

export class AttachmentHelper {
    public static async createPhotoWithLegend(geolocationService,
                                              imageData,
                                              object,
                                              dateProvider: DateProvider) {
        let attachmentDto: AttachmentDto = new AttachmentDto();
        attachmentDto.modified = true;
        attachmentDto.date = dateProvider.now();
        attachmentDto.attachmentKind.id = AttachmentKinds.PHOTO;
        let legend = "Créé le : " + dateProvider.now().toFormat(DATE_NODEJS_FORMAT);
        if (geolocationService) {
            let lastKnownLocation = geolocationService.lastKnownLocation;
            if (lastKnownLocation) {
                legend += " à " + lastKnownLocation.latitude + ", " + lastKnownLocation.longitude;
            }
        }

        attachmentDto.object = object;
        attachmentDto.file = await PictureHelper.addLegend(imageData,
            legend,
            20,
            PICTURE_QUALITY);

        return attachmentDto;
    }
}
