import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { IonicModule } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";
import { PipesModule } from "../../../gyzmo-commons/helpers/pipes/pipes.module";
import { AccountModificationCategoryChoice } from "./account-modification-category-choice.popover";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule.forChild([
            {
                path: "",
                component: AccountModificationCategoryChoice,
            },
        ]),
        PipesModule,
        TranslateModule,
    ],
    declarations: [AccountModificationCategoryChoice],
    providers: [],
})
export class AccountModificationCategoryChoiceModule {
}
