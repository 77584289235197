import { Component } from "@angular/core";
import { PopoverController } from "@ionic/angular";

@Component({
    selector: "popover-account-modification-category-choice",
    templateUrl: "account-modification-category-choice.popover.html",
    styleUrls: ["account-modification-category-choice.popover.scss"],
})
export class AccountModificationCategoryChoice {
    constructor(private viewCtrl: PopoverController) {
    }

    public modifyMyInfo() {
        this.viewCtrl.dismiss("modifyMyInfo");
    }

    public modifyMyPassword() {
        this.viewCtrl.dismiss("modifyMyPassword");
    }

    public modifyMyDriverLicense() {
        this.viewCtrl.dismiss("modifyMyDriverLicense");
    }

    public modifyMyDocuments() {
        this.viewCtrl.dismiss("modifyMyDocuments");
    }

    public modifyMyPhoneNumber() {
        this.viewCtrl.dismiss("modifyMyPhoneNumber");
    }
}
