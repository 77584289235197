import { Component, Input } from "@angular/core";
import { AvailabilityDto } from "../../../core/dto/availability.dto";
import { StcStatuses } from "../../../core/dto/stcStatuses";
import { SHOW_DEBUG_INFO } from "../../app.constants";

@Component({
    selector: "availability-line",
    templateUrl: "availability-line.html",
    styleUrls: ["availability-line.scss"],
})
export class AvailabilityLineComponent {
    @Input() availability: AvailabilityDto;
    StcStatuses = StcStatuses;
    showDebugInfo: boolean = SHOW_DEBUG_INFO;
}
