import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { IonicModule } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";
import { PipesModule } from "../../../gyzmo-commons/helpers/pipes/pipes.module";
import { StcLineComponent } from "./stc-line";

@NgModule(
    {
        declarations: [
            StcLineComponent,
        ],
        imports: [
            CommonModule,
            IonicModule,
            TranslateModule,
            PipesModule,
        ],
        exports: [
            StcLineComponent,
        ],
        entryComponents: [
            StcLineComponent,
        ],
    })

export class StcLineModule {
}
