import { Injectable } from "@angular/core";
import { HttpErrorHandler } from "../http/httpErrorHandler";
import { ServerConnection } from "../http/serverConnection";
import { ThirdPartyType } from "../models/thirdPartyType.model";

@Injectable({
    providedIn: "root",
})
export class ThirdPartyTypesWs {
    static WS = "third-parties/:id/third-party-types";

    constructor(
        private httpErrorHandler: HttpErrorHandler) {
    }

    public addTypeToThirdParty(serverConnection: ServerConnection, thirdPartyId: string, type: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            let tokens = new Map<string, string>();
            tokens.set("id", thirdPartyId);

            serverConnection.post(this.constructor.name, ThirdPartyTypesWs.WS, tokens, { thirdPartyType: { id: type } })
                .then(response => {
                    resolve();
                })
                .catch(reason => {
                    if (!this.httpErrorHandler.handleError(reason)) {
                        resolve();
                    }
                });
        });
    }

    public getListByThirdPartyId(serverConnection: ServerConnection, thirdPartyId: string): Promise<ThirdPartyType[]> {
        return new Promise<ThirdPartyType[]>((resolve, reject) => {
            let tokens = new Map<string, string>();
            tokens.set("id", thirdPartyId);

            serverConnection.get(this.constructor.name, ThirdPartyTypesWs.WS, tokens)
                .then(response => {
                    if (response.status == 204) {
                        resolve([]);
                    } else {
                        let result = [];
                        (response.body as Array<any>).forEach(value => {
                            let thirdPartyType = this.responseBodyToModel(value);
                            result.push(thirdPartyType);
                        });
                        resolve(result);
                    }
                })
                .catch(reason => {
                    reject(reason);
                });
        });
    }

    responseBodyToModel(body: any): ThirdPartyType {
        let thirdPartyType = new ThirdPartyType();
        thirdPartyType.id = body.thirdPartyType.id;

        return thirdPartyType;
    }
}
