import { PlatformName } from "../gyzmo-commons/helpers/device.helper";
import { EnvironmentSpec } from "./environment.model.specif";

export const environment: EnvironmentSpec = {
    production: false,
    customer: "Gyzmo",
    testConfig: { platform: PlatformName.ANDROID },
    defaultLogin: "MFE",
    defaultPassword: "MFE",
    mockCamera: false,
    servers: [
        {
            name: "Hexanet Dev",
            url: "https://api.vega-systems.com/api-dev",
            apiKey: "c4e268c8-9c67-465b-a456-29e830216ec1",
            defaultToken: "KY_MOBILITE",
            token: "KY_MOBILITE",
            context: "",
            inspectionApiVersion: 2,
        },
    ],
    logging: {
        logLevels: [
            { loggerName: "defaultLogger", logLevel: "INFO" },
        ],
        localStorageAppender: {
            localStorageKey: "defaultLogger",
            maxMessages: 2000,
            threshold: "INFO",
        },
    },
};
