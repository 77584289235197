import { DateTime } from "luxon";
import { AssetsHelper } from "../../../../gyzmo-commons/helpers/assets.helper";
import { DateHelper } from "../../../../gyzmo-commons/helpers/date.helper";
import { PictureHelper } from "../../../../gyzmo-commons/helpers/picture.helper";
import { DATE_NODEJS_FORMAT, PICTURE_QUALITY } from "../../../../gyzmo-commons/interfaces/constants";
import { DateProvider } from "../../../../gyzmo-commons/interfaces/dateProvider";
import { AttachmentKinds } from "../../../interfaces/attachmentKinds";
import { INSPECTION_KINDS } from "../../../interfaces/INSPECTION_KINDS";
import { Inspection } from "../../../models/inspection/v2/inspection.model";
import { AttachmentDto } from "../../attachment.dto";
import { BaseDto } from "../../base/base.dto";
import { EquipmentDto } from "../../equipment.dto";
import { MovementDto } from "../../movement.dto";
import { ServerDto } from "../../server.dto";
import { InspectionDtoCommonInterface } from "../common/inspectionDtoCommonInterface";
import { ChecklistDto } from "./checklist.dto";
import { FaceDto } from "./face.dto";

export class InspectionDto extends BaseDto<Inspection> implements InspectionDtoCommonInterface {
    public id: string = "";
    public updateDate: DateTime = null;
    public visitDate: DateTime = null;
    public agentName: string = "";
    public driverName: string = "";
    public driverMail: string = "";
    public linkedObject: string = "";
    public inspectionModel: { id?: string };
    public user: { id?: string };
    public equipment: EquipmentDto;
    public movement: MovementDto;

    public kind: INSPECTION_KINDS;
    public mileage: number = 0;

    public checklists: ChecklistDto[] = [];
    public faces: FaceDto[] = [];

    public attachments: AttachmentDto[] = [];

    public synchronized: boolean = false;

    constructor() {
        super();

        this.inspectionModel = {};
        this.user = {};
        this.equipment = new EquipmentDto();
        this.movement = new MovementDto();
    }

    static fromBody(body: any, serverDto: ServerDto): InspectionDto {
        let inspectionDto = new InspectionDto();

        inspectionDto.id = body.id;
        inspectionDto.updateDate = body.updateDate;
        inspectionDto.visitDate = body.visitDate;
        inspectionDto.linkedObject = body.linkedObject;
        inspectionDto.agentName = body.agentName;
        inspectionDto.driverName = body.driverName;
        inspectionDto.driverMail = body.driverMail;
        inspectionDto.mileage = body.mileage;

        inspectionDto.synchronized = false;

        if (body.checklists) {
            body.checklists.forEach((checklist) => {
                inspectionDto.checklists.push(ChecklistDto.fromBody(checklist, inspectionDto.id));
            });
        }

        if (body.faces) {
            body.faces.forEach((face) => {
                inspectionDto.faces.push(FaceDto.fromBody(face, inspectionDto.id));
            });
        }

        if (body.inspectionModel) {
            inspectionDto.inspectionModel.id = body.inspectionModel.id;
        }

        if (body.user) {
            inspectionDto.user.id = body.user.id;
        }

        if (body.equipment) {
            inspectionDto.equipment = EquipmentDto.fromBody(body.equipment, serverDto);
        }

        if (body.movement) {
            inspectionDto.movement = MovementDto.fromBody(body.movement, serverDto);
        }

        if (body.kind) {
            inspectionDto.kind = body.kind.id;
        }

        if (body.attachments) {
            body.attachments.forEach(attachment => {
                inspectionDto.attachments.push(AttachmentDto.fromBody(attachment, "inspection", inspectionDto.id));
            });
        }

        return inspectionDto;
    }

    static fromModel(inspection: Inspection): InspectionDto {
        let inspectionDto = new InspectionDto();

        inspectionDto.id = inspection.id;
        inspectionDto.updateDate = DateTime.fromISO(inspection.updateDate);
        inspectionDto.visitDate = DateTime.fromISO(inspection.visitDate);
        inspectionDto.linkedObject = inspection.linkedObject;

        inspectionDto.agentName = inspection.agentName;
        inspectionDto.driverName = inspection.driverName;
        inspectionDto.driverMail = inspection.driverMail;
        inspectionDto.mileage = inspection.mileage;

        inspectionDto.inspectionModel = inspection.inspectionModel;
        inspectionDto.user = inspection.user;

        inspectionDto.kind = inspection.kind;
        inspectionDto.synchronized = inspection.synchronized == 1;

        if (inspection.equipment) {
            inspectionDto.equipment = EquipmentDto.fromModel(inspection.equipment);
        }

        if (inspection.movement && inspection.movement.id) {
            inspectionDto.movement = MovementDto.fromModel(inspection.movement);
        }

        if (inspection.checklists) {
            inspection.checklists.forEach((checklist) => {
                inspectionDto.checklists.push(ChecklistDto.fromModel(checklist));
            });
        }

        if (inspection.faces) {
            inspection.faces.forEach((face) => {
                inspectionDto.faces.push(FaceDto.fromModel(face));
            });
        }

        if (inspection.attachments) {
            inspection.attachments.forEach(attachment => {
                inspectionDto.attachments.push(AttachmentDto.fromModel(attachment));
            });
        }

        return inspectionDto;
    }

    public toModel(): Inspection {
        let inspection = new Inspection();

        inspection.id = this.id;
        inspection.updateDate = DateHelper.tryToISO(this.updateDate);
        inspection.visitDate = DateHelper.tryToISO(this.visitDate);
        inspection.agentName = this.agentName;
        inspection.driverName = this.driverName;
        inspection.driverMail = this.driverMail;
        inspection.linkedObject = this.linkedObject;
        inspection.driverMail = this.driverMail;
        inspection.kind = this.kind;
        inspection.mileage = this.mileage;
        inspection.synchronized = this.synchronized ? 1 : 0;
        inspection.inspectionModel = this.inspectionModel;
        inspection.user = this.user;

        if (this.equipment) {
            inspection.equipment = this.equipment.toModel();
        }

        if (this.movement) {
            inspection.movement = this.movement.toModel();
        }

        inspection.checklistsIds = "";
        if (this.checklists) {
            this.checklists.forEach((checklist) => {
                inspection.checklists.push(checklist.toModel());
                inspection.checklistsIds += checklist.id + ";";
            });
        }

        inspection.facesIds = "";
        if (this.faces) {
            this.faces.forEach((face) => {
                inspection.faces.push(face.toModel());
                inspection.facesIds += face.id + ";";
            });
        }

        if (this.attachments) {
            this.attachments.forEach(attachment => {
                inspection.attachments.push(attachment.toModel());
            });
        }

        return inspection;
    }

    public isOnDay(activeDay: DateTime): boolean {
        return DateHelper.isSameDay(this.visitDate, activeDay);
    }

    public async toBody(dateProvider?: DateProvider, assetsHelper?: AssetsHelper) {
        const clone: any = { ...this };

        clone.kind = { id: clone.kind };
        clone.groupingCode = clone.id;
        clone.updateDate = DateHelper.tryToFormat(this.updateDate, DATE_NODEJS_FORMAT);
        clone.visitDate = DateHelper.tryToFormat(this.updateDate, DATE_NODEJS_FORMAT);

        clone.equipment = this.equipment.toBody();
        clone.movement = this.movement.toBody();

        clone.checklists = [];
        this.checklists.forEach(checklist => {
            clone.checklists.push(checklist.toBody());
        });

        clone.faces = [];
        this.faces.forEach(face => {
            clone.faces.push(face.toBody());
        });

        clone.attachments = [];
        this.attachments.forEach(attachment => {
            clone.attachments.push(attachment.toBody());
        });

        // Nettoyer l'objet avant envoi
        delete clone.agentName;
        delete clone.driverMail;

        // Joindre une signature
        const attachmentDto = new AttachmentDto();
        attachmentDto.modified = true;
        attachmentDto.attachmentKind.id = AttachmentKinds.SIGNATURE;
        attachmentDto.date = dateProvider.now();
        attachmentDto.wording = "Signature état parc";
        attachmentDto.object = clone.id;
        attachmentDto.file = await this.generateSignature(PictureHelper.encodeToBase64(await assetsHelper.readBinaryFile("imgs/emptySignature.jpg")), dateProvider);
        clone.attachments.push(attachmentDto);

        return clone;
    }

    generateSignature(image: string, dateProvider: DateProvider): Promise<string> {
        return PictureHelper.addLegend(image,
            "Signé le : "
            + dateProvider.now().toFormat(DATE_NODEJS_FORMAT),
            20,
            PICTURE_QUALITY);
    }
}
