import { Injectable } from "@angular/core";
import { Logger } from "ionic-logging-service";
import { DbDaoBase } from "../../../gyzmo-commons/dao/db/base/db.dao.base";
import { AppSqlProvider } from "../../../gyzmo-commons/persistence/app.sql.provider";
import { Stc } from "../../models/stc.model";
import { LocationDbDao } from "./location.db.dao";
import { MovementDbDao } from "./movement.db.dao";

@Injectable({
    providedIn: "root",
})
export class StcDbDao extends DbDaoBase<Stc> {

    constructor(logger: Logger,
                private sqlProvider: AppSqlProvider,
                private locationDbDao: LocationDbDao,
                private movementDbDao: MovementDbDao) {
        super(logger);
    }

    public getAll(hydrate: boolean = false): Promise<Stc[]> {
        let selectQuery = "SELECT * FROM " + Stc.TABLENAME + ";";

        return this.sqlProvider.query(selectQuery)
            .then(data => {
                if (data.rows.length <= 0) {
                    return [];
                }

                let stcs: Stc[] = [];
                for (let i = 0; i < data.rows.length; i++) {
                    stcs.push(this.rowToModel(data.rows[i]));
                }

                let hydratationPromises = [];

                if (hydrate) {
                    stcs.forEach(stc => {
                        hydratationPromises.push(this.movementDbDao.get(stc.movement.id, hydrate)
                            .then(value => {
                                stc.movement = value;
                            }));

                        hydratationPromises.push(this.locationDbDao.get(stc.startLocation.id, hydrate)
                            .then(value => {
                                stc.startLocation = value;
                            }));
                        hydratationPromises.push(this.locationDbDao.get(stc.plannedReturnLocation.id, hydrate)
                            .then(value => {
                                stc.plannedReturnLocation = value;
                            }));
                        hydratationPromises.push(this.locationDbDao.get(stc.returnLocation.id, hydrate)
                            .then(value => {
                                stc.returnLocation = value;
                            }));
                    });
                }

                return Promise.all(hydratationPromises)
                    .then(() => {
                        return stcs;
                    });
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    public async createIndexes(): Promise<void> {
        let query = "CREATE INDEX IF NOT EXISTS idx_" + Stc.TABLENAME + "_id"
                    + " ON " + Stc.TABLENAME + "(id);";

        await this.sqlProvider.query(query)
            .catch(reason => {
                this.logSqlError(reason);
            });
    }

    public createTable(): Promise<void> {
        let query = "CREATE TABLE IF NOT EXISTS " + Stc.TABLENAME
                    + " ("
                    + "id TEXT PRIMARY KEY,"
                    + "startDate TEXT,"
                    + "plannedReturnDate TEXT,"
                    + "returnDate TEXT,"
                    + "wantedMileage NUMBER,"
                    + "customerInvoice TEXT,"
                    + "contractNumber TEXT,"
                    + "bookingCode TEXT,"
                    + "customer TEXT,"
                    + "driver TEXT,"
                    + "status TEXT,"
                    + "isClosed NUMBER,"
                    + "deposit NUMBER,"
                    + "pricing TEXT,"
                    + "pricingMethod TEXT,"
                    + "movement TEXT,"
                    + "startLocation TEXT,"
                    + "plannedReturnLocation TEXT,"
                    + "returnLocation TEXT,"
                    + "category TEXT"
                    + ");";

        return this.sqlProvider.query(query)
            .then(async () => {
                await this.createIndexes();
                return;
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    public delete(id: string): Promise<any> {
        let selectQuery = "DELETE FROM " + Stc.TABLENAME + " WHERE id = '" + id + "';";
        return this.sqlProvider.query(selectQuery);
    }

    deleteAll(): Promise<any> {
        let selectQuery = "DELETE FROM " + Stc.TABLENAME + ";";
        return this.sqlProvider.query(selectQuery);
    }

    public get(id: string, hydrate: boolean = false): Promise<Stc> {
        let selectQuery = "SELECT * FROM " + Stc.TABLENAME + " WHERE id = '" + id + "';";

        return this.sqlProvider.query(selectQuery)
            .then(
                data => {
                    if (data.rows.length <= 0) {
                        return null;
                    }

                    let stc: Stc = this.rowToModel(data.rows[0]);

                    let hydratationPromises = [];

                    if (hydrate) {
                        hydratationPromises.push(this.movementDbDao.get(stc.movement.id, hydrate)
                            .then(value => {
                                stc.movement = value;
                            }));

                        hydratationPromises.push(this.locationDbDao.get(stc.startLocation.id, hydrate)
                            .then(value => {
                                stc.startLocation = value;
                            }));
                        hydratationPromises.push(this.locationDbDao.get(stc.plannedReturnLocation.id, hydrate)
                            .then(value => {
                                stc.plannedReturnLocation = value;
                            }));
                        hydratationPromises.push(this.locationDbDao.get(stc.returnLocation.id, hydrate)
                            .then(value => {
                                stc.returnLocation = value;
                            }));
                    }

                    return Promise.all(hydratationPromises)
                        .then(() => {
                            return stc;
                        });
                })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    public getTableName(): string {
        return Stc.TABLENAME;
    }

    protected rowToModel(row: any): Stc {
        let stc = new Stc();

        stc.id = row.id;
        stc.startDate = row.startDate;
        stc.plannedReturnDate = row.plannedReturnDate;
        stc.returnDate = row.returnDate;
        stc.wantedMileage = Number(row.wantedMileage);
        stc.contractNumber = row.contractNumber;
        stc.bookingCode = row.bookingCode;

        stc.status = row.status;
        stc.isClosed = row.isClosed;
        stc.deposit = row.deposit;

        stc.customerInvoice = JSON.parse(row.customerInvoice);
        stc.pricing = JSON.parse(row.pricing);
        stc.pricingMethod = JSON.parse(row.pricingMethod);
        stc.category = JSON.parse(row.category);

        // Fks
        stc.customer.id = row.customer;
        stc.driver.id = row.driver;
        stc.movement.id = row.movement;
        stc.startLocation.id = row.startLocation;
        stc.plannedReturnLocation.id = row.plannedReturnLocation;
        stc.returnLocation.id = row.returnLocation;

        return stc;
    }

    public save(stc: Stc): Promise<Stc> {
        let promises = [];
        promises.push(this.movementDbDao.save(stc.movement));
        promises.push(this.locationDbDao.save(stc.startLocation));
        promises.push(this.locationDbDao.save(stc.plannedReturnLocation));
        promises.push(this.locationDbDao.save(stc.returnLocation));

        return Promise.all(promises)
            .then(values => {
                let query = "INSERT OR REPLACE INTO " + Stc.TABLENAME + " ("
                            + "id, startDate, plannedReturnDate, returnDate, wantedMileage, customerInvoice, contractNumber, bookingCode, customer, driver, status, isClosed,"
                            + "deposit, pricing, pricingMethod, movement, startLocation, plannedReturnLocation, returnLocation, category"
                            + ") VALUES ("
                            + this.getValue(stc.id)
                            + this.getValue(stc.startDate)
                            + this.getValue(stc.plannedReturnDate)
                            + this.getValue(stc.returnDate)
                            + this.getValue(stc.wantedMileage)
                            + this.getValueAsJsonString(stc.customerInvoice)
                            + this.getValue(stc.contractNumber)
                            + this.getValue(stc.bookingCode)
                            + this.getFkValue(stc.customer)
                            + this.getFkValue(stc.driver)
                            + this.getValue(stc.status)
                            + this.getValue(stc.isClosed)
                            + this.getValue(stc.deposit)
                            + this.getValueAsJsonString(stc.pricing)
                            + this.getValueAsJsonString(stc.pricingMethod)
                            + this.getFkValue(stc.movement)
                            + this.getFkValue(stc.startLocation)
                            + this.getFkValue(stc.plannedReturnLocation)
                            + this.getFkValue(stc.returnLocation)
                            + this.getValueAsJsonString(stc.category, true)
                            + ");";

                return this.sqlProvider.query(query)
                    .then(response => {
                        return stc;
                    })
                    .catch(reason => {
                        this.logSqlError(reason);
                        return null;
                    });
            });
    }
}
