import { ModelBase } from "../../gyzmo-commons/models/base/model.base";

export class GeneralPhotoType extends ModelBase {
    static TABLENAME = "GeneralPhotoTypes";

    public id: string = "";
    public wording: string = "";
    public order: string = "";
    public filter: string = "";

    constructor() {
        super(GeneralPhotoType.TABLENAME);
    }
}
