import { ModelBase } from "../../../../gyzmo-commons/models/base/model.base";
import { DamageCategory } from "../common/damageCategory.model";

export class ZoneElement extends ModelBase {
    static TABLENAME = "ZoneElements_V2";

    public id: string = "";
    public parentZoneId: string = "";
    public wording: string = "";
    public filter: string = "";
    public filter2: string = "";
    public order: string = "";

    public damageCategory: DamageCategory;

    constructor() {
        super(ZoneElement.TABLENAME);

        this.damageCategory = new DamageCategory();
    }
}
