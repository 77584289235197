import { Injectable } from "@angular/core";
import { KeyValueDbDao } from "../../../../gyzmo-commons/dao/db/keyValue.db.dao";
import { KeyValue } from "../../../../gyzmo-commons/models/keyValue.model";
import { GeneralParametersWs } from "../../../../gyzmo-commons/ws/generalParameters.ws";
import { InspectionWsDaoV1 } from "../../../dao/ws/inspection/v1/inspection.ws.dao";
import { MovementDto } from "../../../dto/movement.dto";
import { INSPECTION_KINDS } from "../../../interfaces/INSPECTION_KINDS";
import { ServersConnectionsProvider } from "../../../providers/serversConnections.provider";

@Injectable({
    providedIn: "root",
})
export abstract class InspectionServiceBase {
    protected constructor(protected generalParametersWs: GeneralParametersWs,
                          protected keyValueDbDao: KeyValueDbDao,
                          protected inspectionWsDaoV1: InspectionWsDaoV1,
                          protected serversConnectionsProvider: ServersConnectionsProvider) {
    }

    public async synchronizeHomeCardContent(): Promise<void> {
        let value = await this.generalParametersWs.getValue(this.serversConnectionsProvider.getServerConnection(), "CHECKINCHECKOUT", "HOME_CARD_CONTENT");
        if (!value) {
            value = "";
        }

        await this.keyValueDbDao.save(new KeyValue("HOME_CARD_CONTENT", value));
    }

    public initializeByMoveId(movement: MovementDto, inspectionType: INSPECTION_KINDS) {
        return this.inspectionWsDaoV1.initializeByMoveId(this.serversConnectionsProvider.getServerConnection(), movement.id, inspectionType);
    }
}
