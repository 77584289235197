import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Logger } from "ionic-logging-service";
import { Feature } from "ol";
import { Extent } from "ol/extent";
import { Point } from "ol/geom";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import Map from "ol/Map";
import { fromLonLat, transformExtent } from "ol/proj";
import { OSM } from "ol/source";
import VectorSource from "ol/source/Vector";
import { Icon, Style } from "ol/style";
import View from "ol/View";
import { AddressDto } from "../../../core/dto/address.dto";
import { AddressHelper } from "../../../core/helpers/address.helper";
import { OpenMapsHelper } from "../../../gyzmo-commons/helpers/openMaps.helper";
import { GeocoderReverseResult } from "../../../gyzmo-commons/services/geocodings/geocoderReverseResult";
import { GeolocationService } from "../../../gyzmo-commons/services/geolocation.service";

export interface MapTarget {
    latitude: number;
    longitude: number;
    targetIcon: string;
    addressMessage: string;
    targetGeocoderReverse?: GeocoderReverseResult;
    targetAddress?: AddressDto;
}

@Component({
    selector: "map",
    templateUrl: "map.html",
    styleUrls: ["map.scss"],
})
export class MapComponent implements OnInit, OnDestroy {
    @ViewChild("map", { static: false }) mapContainer: ElementRef;
    @Input() showGoToButton: boolean;
    @Input() target: MapTarget;

    map: Map;
    markerSource = new VectorSource();

    constructor(private logger: Logger,
                private openMapsHelper: OpenMapsHelper,
                private geolocationService: GeolocationService) {
    }

    ngOnDestroy() {
        if (this.map) {
            this.map = null;
        }
    }

    ngOnInit() {
        this.map = new Map({
            target: "openLayerMap",
            layers: [
                new TileLayer({
                    source: new OSM(),
                }),
                new VectorLayer({
                    source: this.markerSource,
                }),
            ],
            view: new View({
                projection: "EPSG:3857",
                center: this.target ? fromLonLat([this.target.longitude, this.target.latitude]) : fromLonLat([0, 0]),
                zoom: 18,
            }),
        });

        if (this.target) {
            this.addMarker(this.target.longitude, this.target.latitude, this.target.targetIcon);
        }

        setTimeout(() => {
            this.map.updateSize();

            this.geolocationService.getCurrentPosition()
                .then((resp) => {
                    this.addMarker(resp.longitude, resp.latitude, "assets/imgs/pin.svg");

                    let extent;
                    if (this.target) {
                        extent = [this.target.longitude, this.target.latitude, resp.longitude, resp.latitude] as Extent;
                    } else {
                        extent = [resp.longitude, resp.latitude, resp.longitude, resp.latitude] as Extent;
                    }
                    extent = transformExtent(extent, "EPSG:4326", "EPSG:3857");

                    this.map.getView().fit(extent, {
                        //duration: 3000,
                        padding: [60, 60, 60, 60],
                        maxZoom: 18,
                    });
                })
                .catch((error) => {
                    this.logger.error(this.constructor.name, "Error getting location : ", error);
                });
        }, 100);
    }

    onGoToButtonClick() {
        this.openMapsHelper.launchMapsApp(this.target.latitude, this.target.longitude);
    }

    public getTargetAddress() {
        if (this.target.targetAddress && this.target.targetAddress.id) {
            return AddressHelper.formatAddress(this.target.targetAddress);
        }

        return this.target.targetGeocoderReverse.toString();
    }

    private addMarker(lon: number, lat: number, imgSrc: string) {
        this.logger.debug(this.constructor.name, "Adding marker at : [" + lat + " " + lon + "]");

        const marker = new Feature({
            geometry: new Point(fromLonLat([lon, lat])),
        });

        marker.setStyle(new Style({
            image: new Icon(({
                crossOrigin: "anonymous",
                src: imgSrc,
                anchor: [0.5, 1],
                anchorXUnits: "fraction",
                anchorYUnits: "fraction",
                scale: 0.1,
            })),
        }));

        this.markerSource.addFeature(marker);
    }
}
