import { Injectable } from "@angular/core";
import { DoorsStatus, OpenFleetEquipmentInformationDao } from "../dao/openFleetEquipmentInformation.dao";
import { ServerConnection } from "../http/serverConnection";
import { OpenFleetWs } from "./openFleet.ws";

@Injectable({
    providedIn: "root",
})
export class OpenFleetV2Ws extends OpenFleetWs {

    public async lockDoors(serverConnection: ServerConnection, equipmentId: string): Promise<void> {
        let openFleetEquipmentInformationDao: OpenFleetEquipmentInformationDao = await this.getEquipmentInfo(serverConnection, equipmentId);
        if (openFleetEquipmentInformationDao.doorsStatus != DoorsStatus.Locked) {
            await this.toggleDoorsLocker(serverConnection, equipmentId);
        }
    }

    public async unlockDoors(serverConnection: ServerConnection, equipmentId: string): Promise<void> {
        let openFleetEquipmentInformationDao: OpenFleetEquipmentInformationDao = await this.getEquipmentInfo(serverConnection, equipmentId);
        if (openFleetEquipmentInformationDao.doorsStatus != DoorsStatus.Unlocked) {
            await this.toggleDoorsLocker(serverConnection, equipmentId);
        }
    }
}
