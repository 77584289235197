import { Component } from "@angular/core";
import { ModalController, Platform } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { Logger } from "ionic-logging-service";
import { Subscription } from "rxjs";
import { ZoneDto } from "src/core/dto/inspection/v2/zone.dto";
import { AttachmentService } from "src/core/services/attachment.service";
import { ZoneElementDbDaoV2 } from "../../../../../core/dao/db/inspection/v2/zoneElement.db.dao";
import { AttachmentDto } from "../../../../../core/dto/attachment.dto";
import { DamageDto } from "../../../../../core/dto/inspection/v2/damage.dto";
import { AttachmentKinds } from "../../../../../core/interfaces/attachmentKinds";
import { INSPECTION_KINDS } from "../../../../../core/interfaces/INSPECTION_KINDS";
import { DamageValue } from "../../../../../core/models/inspection/common/damageValue.model";
import { ZoneElement } from "../../../../../core/models/inspection/v2/zoneElement.model";
import { LoadingHelper } from "../../../../../gyzmo-commons/helpers/loading.helper";
import { NavControllerExtended } from "../../../../../gyzmo-commons/helpers/navControllerExtended";
import { NetworkHelper } from "../../../../../gyzmo-commons/helpers/network.helper";
import { isNullOrEmpty } from "../../../../../gyzmo-commons/helpers/null.helper";
import { PictureHelper } from "../../../../../gyzmo-commons/helpers/picture.helper";
import { ToastHelper } from "../../../../../gyzmo-commons/helpers/toast.helper";
import { PICTURE_QUALITY } from "../../../../../gyzmo-commons/interfaces/constants";
import { DateProvider } from "../../../../../gyzmo-commons/interfaces/dateProvider";
import { CameraService } from "../../../../../gyzmo-commons/services/camera.service";

@Component({
    selector: "modal-create-damage",
    templateUrl: "create-damage.modal.html",
    styleUrls: ["create-damage.modal.scss"],
})
export class CreateDamageModal {
    subscription: Subscription;
    inspectionKinds = INSPECTION_KINDS;

    zone: ZoneDto;
    damage: DamageDto;
    inspectionKind: string;
    isOld = false;
    isCreation: boolean;

    comment = "";

    isAttachmentDownloaded = false;
    isLoaded: boolean = false;

    zoneElements: ZoneElement[];

    constructor(private navCtrl: NavControllerExtended,
                private platform: Platform,
                private viewCtrl: ModalController,
                private loadingHelper: LoadingHelper,
                private attachmentService: AttachmentService,
                private cameraService: CameraService,
                private logger: Logger,
                private pictureHelper: PictureHelper,
                private networkHelper: NetworkHelper,
                private translateService: TranslateService,
                private toastHelper: ToastHelper,
                private elementDbDao: ZoneElementDbDaoV2,
                private dateProvider: DateProvider) {
        this.damage = <DamageDto>this.navCtrl.params.getParam("damage");
        this.zone = <ZoneDto>this.navCtrl.params.getParam("zone");
        this.isCreation = this.navCtrl.params.getParam("isCreation");
        this.inspectionKind = this.navCtrl.params.getParam("inspectionKind");
    }

    async ionViewWillEnter() {
        let attachment: AttachmentDto = this.getTxtAttachment();
        if (attachment) {
            this.comment = attachment.txt;
        }

        this.subscription = this.platform.backButton.subscribeWithPriority(999, () => {
            this.dismiss();
        });

        this.zoneElements = await this.elementDbDao.getByZoneId(this.zone.id);

        if (this.zoneElements && this.zoneElements.length == 1) {
            this.damage.elementId = this.zoneElements[0].id;
        }

        this.isLoaded = true;
    }

    public dismiss() {
        this.subscription.unsubscribe();
        this.viewCtrl.dismiss({ damage: this.damage, action: "cancel" });
    }

    public getPhotoAttachments(): AttachmentDto[] {
        return this.damage.attachments.filter(value => {
            return value.attachmentKind.id == AttachmentKinds.PHOTO;
        });
    }

    public getTxtAttachment(): AttachmentDto {
        let foundAttachments = this.damage.attachments.filter(value => {
            return value.attachmentKind.id == AttachmentKinds.TEXTE;
        });

        if (foundAttachments.length > 0) {
            return foundAttachments[0];
        }

        return null;
    }

    public saveAndDismiss() {
        if (!isNullOrEmpty(this.comment)) {
            let attachment: AttachmentDto = this.getTxtAttachment();
            if (!attachment) {
                attachment = new AttachmentDto();
                this.damage.attachments.push(attachment);
            }

            attachment.object = this.damage.inspectionId;
            attachment.modified = true;
            attachment.attachmentKind.id = AttachmentKinds.TEXTE;
            attachment.txt = this.comment;
        }

        this.viewCtrl.dismiss({ damage: this.damage, action: "save" });
    }

    public addPicture() {
        this.cameraService.getPicture()
            .then(async (imageData) => {
                if (imageData) {
                    await this.loadingHelper.showLoading();

                    let attachmentDto: AttachmentDto = new AttachmentDto();
                    attachmentDto.modified = true;
                    attachmentDto.date = this.dateProvider.now();
                    attachmentDto.attachmentKind.id = AttachmentKinds.PHOTO;
                    attachmentDto.file = await this.pictureHelper.watermarkPicture(imageData, PICTURE_QUALITY);
                    this.damage.attachments.push(attachmentDto);

                    await this.loadingHelper.hideLoading();
                }
            })
            .catch(reason => {
                this.logger.error(this.constructor.name, reason);
            });
    }

    public deletePicture(attachment: AttachmentDto) {
        if (!this.damage.attachments || this.damage.attachments.length <= 0) {
            return;
        }

        this.damage.attachments = this.damage.attachments.filter((object: AttachmentDto) => {
            return object != attachment;
        });
    }

    public showPicture(attachment: AttachmentDto) {
        this.pictureHelper.viewImage(attachment.file, !this.damage.isOld)
            .then((result: string) => {
                if (result) {
                    attachment.file = result;
                }
            });
    }

    getDamageValue(damageDto: DamageDto): DamageValue {
        if (!this.zoneElements) {
            return null;
        }

        let zoneElement = this.zoneElements.find(element => {
            return element.id == damageDto.elementId;
        });
        if (!zoneElement) {
            return null;
        }

        let damageValue = zoneElement.damageCategory.possibleValues.find(element => {
            return element.categoryId == zoneElement.damageCategory.id && element.id == damageDto.value;
        });

        return damageValue;
    }

    getElementValue(damageDto: DamageDto): ZoneElement {
        if (!this.zoneElements) {
            return null;
        }

        let zoneElement = this.zoneElements.find(element => {
            return element.id == damageDto.elementId;
        });

        return zoneElement;
    }

    getPossibleValues(elementId: string): DamageValue[] {
        /*  if (!this.zone || !this.zone.elements) {
         return [];
         }
         */
        const elementDto = this.zoneElements.find(value => {
            return value.id == elementId;
        });

        /*  if (!elementDto || !elementDto.damageCategory) {
         return [];
         }*/

        return elementDto.damageCategory.possibleValues;
    }

    public async onDownloadAttachmentsClick() {
        if (this.networkHelper.isConnected()) {
            await this.loadingHelper.showLoading();

            this.damage.attachments = await this.attachmentService.getByDamageId(this.damage.id, AttachmentKinds.PHOTO);
            this.isAttachmentDownloaded = true;

            await this.loadingHelper.hideAll();
        } else {
            this.toastHelper.show(this.translateService.instant("Action impossible, vous n'êtes pas sous couverture réseau."));
        }
    }
}
