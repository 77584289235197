import { Injectable } from "@angular/core";
import { Logger } from "ionic-logging-service";
import { DbDaoBase } from "../../../gyzmo-commons/dao/db/base/db.dao.base";
import { AppSqlProvider } from "../../../gyzmo-commons/persistence/app.sql.provider";
import { GeneralPhotoType } from "../../models/generalPhotoType.model";

@Injectable({
    providedIn: "root",
})
export class GeneralPhotoTypeDbDao extends DbDaoBase<GeneralPhotoType> {

    constructor(
        logger: Logger,
        private sqlProvider: AppSqlProvider) {
        super(logger);
    }

    private static sortTypes(generalPhotoTypes: GeneralPhotoType[]): GeneralPhotoType[] {
        generalPhotoTypes = generalPhotoTypes.sort((a, b) => {
            return a.order.localeCompare(b.order);
        });

        return generalPhotoTypes;
    }

    public list(): Promise<GeneralPhotoType[]> {
        let selectQuery = "SELECT * FROM " + GeneralPhotoType.TABLENAME + ";";

        return this.sqlProvider.query(selectQuery)
            .then(data => {
                if (data.rows.length <= 0) {
                    return [];
                }

                let generalPhotoTypes: GeneralPhotoType[] = [];
                for (let i = 0; i < data.rows.length; i++) {
                    generalPhotoTypes.push(this.rowToModel(data.rows[i]));
                }

                let hydratationPromises = [];
                return Promise.all(hydratationPromises)
                    .then(() => {
                        return GeneralPhotoTypeDbDao.sortTypes(generalPhotoTypes);
                    });
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    public listByFilter(filter: string): Promise<GeneralPhotoType[]> {
        let selectQuery = "SELECT * FROM " + GeneralPhotoType.TABLENAME + " WHERE filter LIKE '%" + filter + "%';";

        return this.sqlProvider.query(selectQuery)
            .then(data => {
                if (data.rows.length <= 0) {
                    return [];
                }

                let generalPhotoTypes: GeneralPhotoType[] = [];
                for (let i = 0; i < data.rows.length; i++) {
                    generalPhotoTypes.push(this.rowToModel(data.rows[i]));
                }

                let hydratationPromises = [];
                return Promise.all(hydratationPromises)
                    .then(() => {
                        return GeneralPhotoTypeDbDao.sortTypes(generalPhotoTypes);
                    });
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    public async createIndexes(): Promise<void> {
        let query = "CREATE INDEX IF NOT EXISTS idx_" + GeneralPhotoType.TABLENAME + "_id"
                    + " ON " + GeneralPhotoType.TABLENAME + "(id);";

        await this.sqlProvider.query(query)
            .catch(reason => {
                this.logSqlError(reason);
            });

        query = "CREATE INDEX IF NOT EXISTS idx_" + GeneralPhotoType.TABLENAME + "_filter"
                + " ON " + GeneralPhotoType.TABLENAME + "(filter);";

        await this.sqlProvider.query(query)
            .catch(reason => {
                this.logSqlError(reason);
            });
    }

    public createTable(): Promise<void> {
        let query = "CREATE TABLE IF NOT EXISTS " + GeneralPhotoType.TABLENAME
                    + " ("
                    + "id TEXT PRIMARY KEY, "
                    + "wording TEXT, "
                    + "filter TEXT, "
                    + "_order TEXT"
                    + ");";

        return this.sqlProvider.query(query)
            .then(async () => {
                await this.createIndexes();
                return;
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    delete(id: string): Promise<any> {
        let selectQuery = "DELETE FROM " + GeneralPhotoType.TABLENAME + " WHERE id='" + id + "';";
        return this.sqlProvider.query(selectQuery);
    }

    deleteAll(): Promise<any> {
        let selectQuery = "DELETE FROM " + GeneralPhotoType.TABLENAME + ";";
        return this.sqlProvider.query(selectQuery);
    }

    public get(id: string, hydrate: boolean = false): Promise<GeneralPhotoType> {
        let selectQuery = "SELECT * FROM " + GeneralPhotoType.TABLENAME + " WHERE id = '" + id + "';";

        return this.sqlProvider.query(selectQuery)
            .then(
                data => {
                    if (data.rows.length <= 0) {
                        return null;
                    }

                    let generalPhotoType = this.rowToModel(data.rows[0]);

                    let hydratationPromises = [];

                    if (hydrate) {
                    }

                    return Promise.all(hydratationPromises)
                        .then(() => {
                            return generalPhotoType;
                        });
                })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    public getTableName(): string {
        return GeneralPhotoType.TABLENAME;
    }

    protected rowToModel(row: any): GeneralPhotoType {
        let generalPhotoType = new GeneralPhotoType();

        generalPhotoType.id = row.id;
        generalPhotoType.wording = row.wording;
        generalPhotoType.filter = row.filter;
        generalPhotoType.order = row._order;

        return generalPhotoType;
    }

    public save(generalPhotoType: GeneralPhotoType): Promise<GeneralPhotoType> {
        let promises = [];

        return Promise.all(promises).then(value => {
            let query = "INSERT OR REPLACE INTO " + GeneralPhotoType.TABLENAME + " ("
                        + "id, "
                        + "wording, "
                        + "filter, "
                        + "_order"
                        + ") VALUES ("
                        + this.getValue(generalPhotoType.id)
                        + this.getValue(generalPhotoType.wording)
                        + this.getValue(generalPhotoType.filter)
                        + this.getValue(generalPhotoType.order, true)
                        + ");";

            return this.sqlProvider.query(query)
                .then(response => {
                    return generalPhotoType;
                })
                .catch(reason => {
                    this.logSqlError(reason);
                    return null;
                });
        });
    }
}
