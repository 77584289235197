import { Injectable } from "@angular/core";
import { Logger } from "ionic-logging-service";
import { OpenFleetEquipmentInformationDao } from "../dao/openFleetEquipmentInformation.dao";
import { EquipmentWsDao } from "../dao/ws/equipment.ws.dao";
import { HttpErrorHandler } from "../http/httpErrorHandler";
import { ServerConnection } from "../http/serverConnection";

@Injectable({
    providedIn: "root",
})
export class OpenFleetWs {
    static WS = EquipmentWsDao.WS + "/:id/remote-control";

    constructor(
        private logger: Logger,
        private httpErrorHandler: HttpErrorHandler) {
    }

    public getEquipmentInfo(serverConnection: ServerConnection, equipmentId: string): Promise<OpenFleetEquipmentInformationDao> {
        return new Promise<OpenFleetEquipmentInformationDao>(resolve => {
            let tokens = new Map<string, string>();
            tokens.set("id", equipmentId);

            serverConnection.get(this.constructor.name, OpenFleetWs.WS + "/informations", tokens)
                .then(value => {
                    resolve(OpenFleetEquipmentInformationDao.fromBody(value.body));
                })
                .catch(reason => {
                    this.logger.error(this.constructor.name, reason);
                    resolve(null);
                });
        });
    }

    public setAntiStartup(serverConnection: ServerConnection, equipmentId: string, state: boolean): Promise<void> {
        return new Promise<void>(resolve => {
            let tokens = new Map<string, string>();
            tokens.set("id", equipmentId);

            serverConnection.put(this.constructor.name, OpenFleetWs.WS + "/set-anti-startup", tokens, { state: state })
                .then(value => {
                    resolve();
                })
                .catch(reason => {
                    // Masquer les erreurs sur l'antidémarrage
                    resolve();
                });
        });
    }

    public updatePosition(serverConnection: ServerConnection, equipmentId: string): Promise<void> {
        return new Promise<void>(resolve => {
            let tokens = new Map<string, string>();
            tokens.set("id", equipmentId);

            serverConnection.put(this.constructor.name, OpenFleetWs.WS + "/update-position", tokens, {})
                .then(value => {
                    resolve();
                })
                .catch(reason => {
                    // Masquer les erreurs sur l'update position
                    resolve();
                });
        });
    }

    public lockDoors(serverConnection: ServerConnection, equipmentId: string): Promise<void> {
        return this.toggleDoorsLocker(serverConnection, equipmentId);
    }

    public unlockDoors(serverConnection: ServerConnection, equipmentId: string): Promise<void> {
        return this.toggleDoorsLocker(serverConnection, equipmentId);
    }

    public toggleDoorsLocker(serverConnection: ServerConnection, equipmentId: string): Promise<void> {
        return new Promise<void>(resolve => {
            let tokens = new Map<string, string>();
            tokens.set("id", equipmentId);

            serverConnection.put(this.constructor.name, OpenFleetWs.WS + "/toggle-doors-locker", tokens, {})
                .then(value => {
                    resolve();
                })
                .catch(reason => {
                    if (!this.httpErrorHandler.handleError(reason)) {
                        resolve();
                    }
                });
        });
    }
}
