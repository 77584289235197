import { Injectable } from "@angular/core";
import { GeneralPhotoTypeDbDao } from "../dao/db/generalPhotoType.db.dao";
import { GeneralPhotoTypeWsDao } from "../dao/ws/generalPhotoType.ws.dao";
import { ServersConnectionsProvider } from "../providers/serversConnections.provider";

@Injectable({
    providedIn: "root",
})
export class GeneralPhotoTypeService {
    constructor(private generalPhotoTypeWsDao: GeneralPhotoTypeWsDao,
                private generalPhotoTypeDbDao: GeneralPhotoTypeDbDao,
                private serversConnectionsProvider: ServersConnectionsProvider) {
    }

    public synchronize(): Promise<void> {
        return this.generalPhotoTypeWsDao.getList(this.serversConnectionsProvider.getServerConnection())
            .then(generalPhotoTypeDtos => {
                let promises = [];

                generalPhotoTypeDtos.forEach(generalPhotoTypeDto => {
                    promises.push(this.generalPhotoTypeDbDao.save(generalPhotoTypeDto.toModel()));
                });

                return Promise.all(promises)
                    .then(value => {
                        return;
                    });
            });
    }
}
