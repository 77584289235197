import { Injectable } from "@angular/core";
import { DateTime } from "luxon";
import { DATE_NODEJS_FORMAT } from "../../gyzmo-commons/interfaces/constants";
import { AvailabilityDto } from "../dto/availability.dto";
import { HttpErrorHandler } from "../http/httpErrorHandler";
import { ServerConnection } from "../http/serverConnection";

@Injectable({
    providedIn: "root",
})
export class AvailabilitiesWs {
    static WS = "equipments/geolocation-availabilities";

    constructor(
        private httpErrorHandler: HttpErrorHandler) {
    }

    public list(serverConnection: ServerConnection,
                startDate: DateTime,
                returnDate: DateTime,
                latitude: number,
                longitude: number,
                kilometers: number,
                maxDistance: number): Promise<AvailabilityDto[]> {
        return new Promise<AvailabilityDto[]>((resolve) => {
            let tokens = new Map<string, string>();
            tokens.set("startDate", startDate.toFormat(DATE_NODEJS_FORMAT));
            tokens.set("returnDate", returnDate.toFormat(DATE_NODEJS_FORMAT));
            tokens.set("latitude", "" + latitude);
            tokens.set("longitude", "" + longitude);
            tokens.set("kilometers", "" + kilometers);
            tokens.set("distance", "" + maxDistance);

            serverConnection.get(this.constructor.name,
                AvailabilitiesWs.WS + "?startDate=:startDate&returnDate=:returnDate&latitude=:latitude&longitude=:longitude&kilometers=:kilometers&distance=:distance",
                tokens)
                .then(response => {
                    let availabilities: AvailabilityDto[] = [];
                    if (response.body) {
                        (response.body as Array<any>).forEach(value => {
                            availabilities.push(AvailabilityDto.fromBody(value, serverConnection.getServerDto()));
                        });
                    }

                    resolve(availabilities);
                })
                .catch(reason => {
                    if (!this.httpErrorHandler.handleError(reason)) {
                        resolve([]);
                    }
                });
        });
    }
}
