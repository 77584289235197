import { Injectable } from "@angular/core";
import { UserDto } from "../dto/user.dto";
import { AttachmentService } from "./attachment.service";
import { GeneralPhotoTypeService } from "./generalPhotoType.service";
import { DamageCategoryService } from "./inspection/common/damageCategory.service";
import { InspectionFacesServiceV2 } from "./inspection/v2/inspectionFaces.service";

@Injectable({ providedIn: "root" })
export class DefaultDataDownloaderService {
    constructor(private damageCategoryService: DamageCategoryService,
                private generalPhotoTypeService: GeneralPhotoTypeService,
                private inspectionFacesService: InspectionFacesServiceV2,
                private attachmentService: AttachmentService) {
    }

    public async onConnectionDefaultData(user: UserDto): Promise<void> {
        let promises = [];

        promises.push(this.generalPhotoTypeService.synchronize());
        promises.push(this.damageCategoryService.synchronize());
        promises.push(this.inspectionFacesService.synchronizeAttachments());
        promises.push(this.attachmentService.getCGL(user.company.id));

        await Promise.all(promises);
    }
}
