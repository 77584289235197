import { Injectable } from "@angular/core";
import { DamageCategoryDbDao } from "../../../dao/db/inspection/common/damageCategory.db.dao";
import { DamageCategoryWsDao } from "../../../dao/ws/inspection/common/damageCategory.ws.dao";
import { ServersConnectionsProvider } from "../../../providers/serversConnections.provider";

@Injectable({
    providedIn: "root",
})
export class DamageCategoryService {
    constructor(private damageCategoryWsDao: DamageCategoryWsDao,
                private damageCategoryDbDao: DamageCategoryDbDao,
                private serversConnectionsProvider: ServersConnectionsProvider) {
    }

    public synchronize(): Promise<void> {
        return this.damageCategoryWsDao.getList(this.serversConnectionsProvider.getServerConnection())
            .then(damageCategoryDtos => {
                let promises = [];

                damageCategoryDtos.forEach(damageCategoryDto => {
                    promises.push(this.damageCategoryDbDao.save(damageCategoryDto.toModel()));
                });

                return Promise.all(promises)
                    .then(value => {
                        return;
                    });
            });
    }
}
