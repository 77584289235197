import { ModelBase } from "../../../../gyzmo-commons/models/base/model.base";
import { Damage } from "./damage.model";

export class Zone extends ModelBase {
    static TABLENAME = "Zones_V2";

    public id: string = "";
    public faceId: string = "";
    public shapeType: string = "";
    public coordinates: string = "";
    public zoneClick: string = "";

    public damages: Damage[] = [];

    constructor() {
        super(Zone.TABLENAME);
    }
}
