import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { isNullOrEmpty } from "../../../gyzmo-commons/helpers/null.helper";
import { ToastHelper } from "../../../gyzmo-commons/helpers/toast.helper";
import { XVEGAID } from "../../../gyzmo-commons/http/header.constant";
import { EquipmentDto } from "../../dto/equipment.dto";
import { LocationDto } from "../../dto/location.dto";
import { StcDto } from "../../dto/stc.dto";
import { HttpErrorHandler } from "../../http/httpErrorHandler";
import { ServerConnection } from "../../http/serverConnection";
import { WsDao } from "../../http/wsDao";
import { OpenFleetService } from "../../services/openFleet.service";
import { EquipmentWsDao } from "./equipment.ws.dao";
import { LocationWsDao } from "./location.ws.dao";

@Injectable({
    providedIn: "root",
})
export class StcWsDao extends WsDao<StcDto> {
    static WS = "short-term-contracts";

    constructor(private equipmentWsDao: EquipmentWsDao,
                private locationWsDao: LocationWsDao,
                private httpErrorHandler: HttpErrorHandler,
                private translateService: TranslateService,
                private openFleetService: OpenFleetService,
                private toastHelper: ToastHelper) {
        super();
    }

    public getById(serverConnection: ServerConnection, id: string): Promise<StcDto> {
        return new Promise<StcDto>((resolve, reject) => {
            let tokens = new Map<string, string>();

            serverConnection.get(this.constructor.name, StcWsDao.WS + "/" + id, tokens)
                .then(response => {
                    if (response.status == 204) {
                        resolve(new StcDto());
                    } else {
                        let stcDto = StcDto.fromBody(response.body, serverConnection.getServerDto());

                        let promises = [];

                        if (stcDto.movement && stcDto.movement.equipment) {
                            promises.push(this.equipmentWsDao.getById(serverConnection, stcDto.movement.equipment.id)
                                .then(async (equipment: EquipmentDto) => {
                                    stcDto.movement.equipment = equipment;

                                    await this.openFleetService.getEquipmentInfo(stcDto.movement.equipment)
                                        .then(value => {
                                            if (value) {
                                                stcDto.movement.equipment.trackerVersion = value.version;
                                            }
                                        });
                                }));
                        }

                        promises.push(this.locationWsDao.getById(serverConnection, stcDto.startLocation.id)
                            .then(async (location: LocationDto) => {
                                stcDto.startLocation = location;
                            }));
                        promises.push(this.locationWsDao.getById(serverConnection, stcDto.returnLocation.id)
                            .then(async (location: LocationDto) => {
                                stcDto.returnLocation = location;
                            }));
                        promises.push(this.locationWsDao.getById(serverConnection, stcDto.plannedReturnLocation.id)
                            .then(async (location: LocationDto) => {
                                stcDto.plannedReturnLocation = location;
                            }));

                        Promise.all(promises)
                            .then(() => {
                                resolve(stcDto);
                            });
                    }
                })
                .catch(reason => {
                    if (!this.httpErrorHandler.handleError(reason)) {
                        reject();
                    }
                });
        });
    }

    public getListByThirdPartyId(serverConnection: ServerConnection, thirdPartyId: string): Promise<StcDto[]> {
        return new Promise<StcDto[]>((resolve, reject) => {
            let tokens = new Map<string, string>();

            serverConnection.get(this.constructor.name, StcWsDao.WS + "/?_limit=NOLIMIT&customer=" + thirdPartyId, tokens)
                .then(response => {
                    if (response.status == 204) {
                        resolve([]);
                    } else {
                        let result = [];
                        (response.body as Array<any>).forEach(element => {
                            let stcDto = StcDto.fromBody(element, serverConnection.getServerDto());
                            result.push(stcDto);
                        });

                        resolve(result);
                    }
                })
                .catch(reason => {
                    if (reason.status == 0) {
                        this.toastHelper.show(
                            this.translateService.instant("Serveur injoignable, veuillez vérifiez votre connexion internet. Faîtes attention, la liste de vos contrats n'est peut être pas à jour."),
                            false);
                        resolve([]);
                    } else if (!this.httpErrorHandler.handleError(reason)) {
                        resolve([]);
                    }
                });
        });
    }

    public save(serverConnection: ServerConnection, stcDto: StcDto): Promise<StcDto> {
        return new Promise<StcDto>((resolve, reject) => {
            let tokens = new Map<string, string>();

            if (!isNullOrEmpty(stcDto.id)) {
                tokens.set("id", stcDto.id);

                serverConnection.put(this.constructor.name, StcWsDao.WS + "/:id?checkEquipment=false", tokens, stcDto.toBody())
                    .then(response => {
                        // On redemande le stcDto
                        this.getById(serverConnection, stcDto.id)
                            .then(updatedStcDto => {
                                resolve(updatedStcDto);
                            });
                    })
                    .catch(reason => {
                        if (!this.httpErrorHandler.handleError(reason)) {
                            reject();
                        }
                    });
            } else {
                delete stcDto.id;
                tokens.set("equipment", stcDto.movement.equipment.id);

                serverConnection.post(this.constructor.name, StcWsDao.WS + "?checkEquipment=false&equipment=:equipment", tokens, stcDto.toBody())
                    .then(response => {
                        stcDto.id = response.headers.get(XVEGAID.toLowerCase());
                        // On redemande le stcDto
                        this.getById(serverConnection, stcDto.id)
                            .then(updatedStcDto => {
                                resolve(updatedStcDto);
                            });
                    })
                    .catch(reason => {
                        if (!this.httpErrorHandler.handleError(reason)) {
                            reject();
                        }
                    });
            }
        });
    }
}
