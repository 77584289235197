import { DateTime } from "luxon";
import { KyUnicityProvider } from "./kyUnicity.provider";

export class KyHelper {
    private static str36 = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9",
                            "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];

    // Copie du code delphi UMath.pas
    public static intToStr36(value: number): string {
        let remain: number;
        let result = "";

        do {
            remain = value % 36;
            result = this.str36[remain] + result;
            value = Math.trunc(value / 36);
        } while (value >= 36);

        if (value > 0) {
            result = this.str36[value] + result;
        }

        return result;
    }

    public static leftPad(value: string, size: number): string {
        let s = value + "";
        while (s.length < size) {
            s = "0" + s;
        }
        return s;
    }

    // Copie du code delphi UMath.pas
    public static getNewKy(userId: number, connectionId: number, date: DateTime): string {
        const zYear = date.year % 1000;
        const zDay = date.ordinal;
        const zTimeStamp = date.valueOf();
        const fCurrVal = this.leftPad(this.intToStr36(zYear), 1) + this.leftPad(this.intToStr36(zDay), 2) +
                         this.leftPad(this.intToStr36(zTimeStamp), 6) + this.leftPad(this.intToStr36(userId), 3) +
                         this.leftPad(this.intToStr36(connectionId), 4) + this.leftPad(this.intToStr36(KyUnicityProvider.getInstance().Unicity), 4);
        KyUnicityProvider.getInstance().incrementUnicity();

        return fCurrVal;
    }
}
