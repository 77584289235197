import { ModelBase } from "../../gyzmo-commons/models/base/model.base";

export class Server extends ModelBase {
    static TABLENAME = "Servers";
    id: number = 0;
    name: string = "";
    url: string = "";
    apiKey: string = "";
    defaultToken: string = "";
    token: string = "";
    context: string = "";
    inspectionApiVersion: number = 1;

    constructor() {
        super(Server.TABLENAME);
    }
}
