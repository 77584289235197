import { Injectable } from "@angular/core";
import { Logger } from "ionic-logging-service";
import { DbDaoBase } from "../../../gyzmo-commons/dao/db/base/db.dao.base";
import { AppSqlProvider } from "../../../gyzmo-commons/persistence/app.sql.provider";
import { Location } from "../../models/location.model";
import { AddressDbDao } from "./address.db.dao";
import { CompanyDbDao } from "./company.db.dao";

@Injectable({
    providedIn: "root",
})
export class LocationDbDao extends DbDaoBase<Location> {

    constructor(logger: Logger,
                private sqlProvider: AppSqlProvider,
                private addressDbDao: AddressDbDao,
                private companyDbDao: CompanyDbDao) {
        super(logger);
    }

    public async createIndexes(): Promise<void> {
        let query = "CREATE INDEX IF NOT EXISTS idx_" + Location.TABLENAME + "_id"
                    + " ON " + Location.TABLENAME + "(id);";

        await this.sqlProvider.query(query)
            .catch(reason => {
                this.logSqlError(reason);
            });
    }

    public createTable(): Promise<void> {
        let query = "CREATE TABLE IF NOT EXISTS " + Location.TABLENAME
                    + " ("
                    + "id TEXT PRIMARY KEY,"
                    + "wording TEXT, "
                    + "latitude NUMBER, "
                    + "longitude NUMBER, "
                    + "mainAddress TEXT, "
                    + "company TEXT"
                    + ");";

        return this.sqlProvider.query(query)
            .then(async () => {
                await this.createIndexes();
                return;
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    delete(id: string): Promise<any> {
        let selectQuery = "DELETE FROM " + Location.TABLENAME + " WHERE id='" + id + "';";
        return this.sqlProvider.query(selectQuery);
    }

    deleteAll(): Promise<any> {
        let selectQuery = "DELETE FROM " + Location.TABLENAME + ";";
        return this.sqlProvider.query(selectQuery);
    }

    public get(id: string, hydrate: boolean = false): Promise<Location> {
        let selectQuery = "SELECT * FROM " + Location.TABLENAME + " WHERE id = '" + id + "';";

        return this.sqlProvider.query(selectQuery)
            .then(
                data => {
                    if (data.rows.length <= 0) {
                        return null;
                    }

                    let location: Location = this.rowToModel(data.rows[0]);

                    let hydratationPromises = [];
                    hydratationPromises.push(this.addressDbDao.get(location.mainAddress.id, hydrate)
                        .then(value => {
                            location.mainAddress = value;
                        }));

                    if (hydrate) {
                        hydratationPromises.push(this.companyDbDao.get(location.company.id, hydrate)
                            .then(value => {
                                location.company = value;
                            }));
                    }

                    return Promise.all(hydratationPromises)
                        .then(() => {
                            return location;
                        });
                })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    public getTableName(): string {
        return Location.TABLENAME;
    }

    protected rowToModel(row: any): Location {
        let location = new Location();
        location.id = row.id;
        location.wording = row.wording;
        location.latitude = row.latitude;
        location.longitude = row.longitude;

        location.mainAddress.id = row.mainAddress;
        location.company.id = row.company;

        return location;
    }

    public save(location: Location): Promise<Location> {
        let promises = [];

        promises.push(this.addressDbDao.save(location.mainAddress));
        promises.push(this.companyDbDao.save(location.company));

        return Promise.all(promises).then(value => {
            let query = "INSERT OR REPLACE INTO " + Location.TABLENAME + " (id, wording, latitude, longitude, mainAddress, company) VALUES ("
                        + this.getValue(location.id)
                        + this.getValue(location.wording)
                        + this.getValue(location.latitude)
                        + this.getValue(location.longitude)
                        + this.getFkValue(location.mainAddress)
                        + this.getFkValue(location.company, true)
                        + ");";

            return this.sqlProvider.query(query)
                .then(response => {
                    return location;
                })
                .catch(reason => {
                    this.logSqlError(reason);
                    return null;
                });
        });
    }
}
