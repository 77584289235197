import { DateTime } from "luxon";
import { DateHelper } from "../../gyzmo-commons/helpers/date.helper";
import { DATE_NODEJS_FORMAT } from "../../gyzmo-commons/interfaces/constants";
import { DriverInfo } from "../models/driverInfo.model";
import { BaseDto } from "./base/base.dto";

export class DriverInfoDto extends BaseDto<void> {
    public id: string = "";
    public birthDate: DateTime = null;
    public driverLicenseNumber: string = "";
    public driverLicenseIssuingDate: DateTime = null;
    public driverLicenseIssuingPlace: string = "";

    public static fromModel(model: DriverInfo): DriverInfoDto {
        let driverInfoDto = new DriverInfoDto();

        driverInfoDto.id = model.id;
        driverInfoDto.birthDate = DateTime.fromISO(model.birthDate);
        driverInfoDto.driverLicenseNumber = model.driverLicenseNumber;
        driverInfoDto.driverLicenseIssuingDate = DateTime.fromISO(model.driverLicenseIssuingDate);
        driverInfoDto.driverLicenseIssuingPlace = model.driverLicenseIssuingPlace;

        return driverInfoDto;
    }

    public static fromBody(body: any, thirdPartyId: string): DriverInfoDto {
        let driverInfoDto = new DriverInfoDto();

        driverInfoDto.id = thirdPartyId;
        driverInfoDto.birthDate = DateHelper.tryFromFormat(body.birthDate, DATE_NODEJS_FORMAT);
        driverInfoDto.driverLicenseNumber = body.driverLicenseNumber;
        driverInfoDto.driverLicenseIssuingDate = DateHelper.tryFromFormat(body.driverLicenseIssuingDate, DATE_NODEJS_FORMAT);
        driverInfoDto.driverLicenseIssuingPlace = body.driverLicenseIssuingPlace;

        return driverInfoDto;
    }

    toModel(): DriverInfo {
        let driverInfo = new DriverInfo();

        driverInfo.id = this.id;
        driverInfo.birthDate = DateHelper.tryToISO(this.birthDate);
        driverInfo.driverLicenseNumber = this.driverLicenseNumber;
        driverInfo.driverLicenseIssuingDate = DateHelper.tryToISO(this.driverLicenseIssuingDate);
        driverInfo.driverLicenseIssuingPlace = this.driverLicenseIssuingPlace;

        return driverInfo;
    }

    public toBody() {
        const clone: any = { ...this };

        clone.birthDate = DateHelper.tryToFormat(this.birthDate, DATE_NODEJS_FORMAT);
        clone.driverLicenseIssuingDate = DateHelper.tryToFormat(this.driverLicenseIssuingDate, DATE_NODEJS_FORMAT);

        return clone;
    }
}
