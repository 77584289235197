import { Injectable } from "@angular/core";
import { OfflineModeRequestDto } from "../../dto/offlineModeRequest.dto";
import { DirectConnectionHttpClient } from "../../http/directConnectionHttpClient";
import { RequestMode } from "../../interfaces/requestMode";

@Injectable({
    providedIn: "root",
})
export class OfflineModeWsDao {
    serverlessWsDao: DirectConnectionHttpClient;

    public setServerlessWsDao(serverlessWsDao: DirectConnectionHttpClient) {
        this.serverlessWsDao = serverlessWsDao;
    }

    public async replayRequest(request: OfflineModeRequestDto): Promise<boolean> {
        let result = false;

        switch (request.mode) {
            case RequestMode.POST:
                result = await this.serverlessWsDao.post(request.url, request.headers, request.data)
                    .catch(reason => {
                        return false;
                    });
                break;
            case RequestMode.PUT:
                result = await this.serverlessWsDao.put(request.url, request.headers, request.data)
                    .catch(reason => {
                        return false;
                    });
                break;
            case RequestMode.DELETE:
                result = await this.serverlessWsDao.delete(request.url, request.headers)
                    .catch(reason => {
                        return false;
                    });
                break;
            default:
                result = true;
                break;
        }

        return result;
    }
}
