import { DateTime } from "luxon";
import { DateHelper } from "../../gyzmo-commons/helpers/date.helper";
import { DATE_NODEJS_FORMAT } from "../../gyzmo-commons/interfaces/constants";
import { Movement } from "../models/movement.model";
import { BaseDto } from "./base/base.dto";
import { EquipmentDto } from "./equipment.dto";
import { ServerDto } from "./server.dto";

export class MovementDto extends BaseDto<Movement> {
    id: string = "";
    documentNumber: string = "";
    equipment: EquipmentDto = null;
    startDate: DateTime = null;
    returnDate: DateTime = null;
    returnFuel: number = 0;
    returnFuel2: number = 0;
    returnMileage: number = 0;
    startFuel: number = 0;
    startFuel2: number = 0;
    startMileage: number = 0;

    constructor() {
        super();

        this.equipment = new EquipmentDto();
    }

    public static fromBody(body: any, serverDto: ServerDto): MovementDto {
        let movementDto = new MovementDto();

        movementDto.id = body.id;
        movementDto.documentNumber = body.documentNumber;
        movementDto.startDate = DateHelper.tryFromFormat(body.startDate, DATE_NODEJS_FORMAT);
        movementDto.returnDate = DateHelper.tryFromFormat(body.returnDate, DATE_NODEJS_FORMAT);
        movementDto.returnFuel = body.returnFuel;
        movementDto.returnFuel2 = body.returnFuel2;
        movementDto.returnMileage = body.returnMileage;
        movementDto.startFuel = body.startFuel;
        movementDto.startFuel2 = body.startFuel2;
        movementDto.startMileage = body.startMileage;

        if (body.equipment) {
            movementDto.equipment = EquipmentDto.fromBody(body.equipment, serverDto);
        }

        return movementDto;
    }

    static fromModel(movement: Movement): MovementDto {
        let movementDto = new MovementDto();

        movementDto.id = movement.id;
        movementDto.documentNumber = movement.documentNumber;
        movementDto.startDate = DateTime.fromISO(movement.startDate);
        movementDto.returnDate = DateTime.fromISO(movement.returnDate);
        movementDto.returnFuel = movement.returnFuel;
        movementDto.returnFuel2 = movement.returnFuel2;
        movementDto.returnMileage = movement.returnMileage;
        movementDto.startFuel = movement.startFuel;
        movementDto.startFuel2 = movement.startFuel2;
        movementDto.startMileage = movement.startMileage;

        if (movement.equipment) {
            movementDto.equipment = EquipmentDto.fromModel(movement.equipment);
        }

        return movementDto;
    }

    toModel() {
        let movement = new Movement();

        movement.id = this.id;
        movement.documentNumber = this.documentNumber;
        movement.startDate = DateHelper.tryToISO(this.startDate);
        movement.returnDate = DateHelper.tryToISO(this.returnDate);
        movement.returnFuel = this.returnFuel;
        movement.returnFuel2 = this.returnFuel2;
        movement.returnMileage = this.returnMileage;
        movement.startFuel = this.startFuel;
        movement.startFuel2 = this.startFuel2;
        movement.startMileage = this.startMileage;

        if (this.equipment) {
            movement.equipment = this.equipment.toModel();
        }

        return movement;
    }

    public toBody() {
        const clone: any = { ...this };

        clone.equipment = this.equipment.toBody();

        clone.startDate = DateHelper.tryToFormat(this.startDate, DATE_NODEJS_FORMAT);
        clone.returnDate = DateHelper.tryToFormat(this.returnDate, DATE_NODEJS_FORMAT);

        // Nettoyer l'object avant envoi à lpservice

        return clone;
    }
}

