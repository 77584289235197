import { ModelBase } from "../../../../gyzmo-commons/models/base/model.base";
import { INSPECTION_KINDS } from "../../../interfaces/INSPECTION_KINDS";
import { Attachment } from "../../attachment.model";
import { Equipment } from "../../equipment.model";
import { Movement } from "../../movement.model";
import { Checklist } from "./checklist.model";
import { Face } from "./face.model";

export class Inspection extends ModelBase {
    static TABLENAME = "Inspections_V2";

    public id: string = "";
    public updateDate: string = "";
    public visitDate: string = "";
    public agentName: string = "";
    public driverName: string = "";
    public driverMail: string = "";
    public linkedObject: string = "";
    public inspectionModel: { id?: string };
    public user: { id?: string };
    public equipment: Equipment;
    public movement: Movement;
    public kind: INSPECTION_KINDS;
    public mileage: number = 0;

    public checklists: Checklist[] = [];
    public faces: Face[] = [];

    public attachments: Attachment[] = [];

    public synchronized: number = 0;

    // internal
    public checklistsIds: string = "";
    public facesIds: string = "";

    constructor() {
        super(Inspection.TABLENAME);

        this.inspectionModel = {};
        this.user = {};
        this.equipment = new Equipment();
        this.movement = new Movement();
    }
}
