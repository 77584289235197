import { DateTime } from "luxon";
import { DEFAULT_MAX_TANK_CAPACITY } from "../../app/app.constants";
import { DateHelper } from "../../gyzmo-commons/helpers/date.helper";
import { isNullOrEmpty } from "../../gyzmo-commons/helpers/null.helper";
import { DATE_NODEJS_FORMAT } from "../../gyzmo-commons/interfaces/constants";
import { Equipment } from "../models/equipment.model";
import { BaseDto } from "./base/base.dto";
import { LocationDto } from "./location.dto";
import { ServerDto } from "./server.dto";

export class EquipmentDto extends BaseDto<Equipment> {
    public id: string = "";
    public title: string = "";
    public options: string = "";
    public description: string = "";
    public model: string = "";
    public doors: number = 0;
    public seatings: number = 0;
    public motorization: string = "";
    public color: string = "";
    public latitude: number = 0;
    public longitude: number = 0;
    public picture: string = "";
    public category: { id?: string, wording?: string } = {};
    public trackerVersion: number = 0;
    public registrationNumber: string = "";
    public maxFuel: number = 0;
    public maxFuel2: number = 0;
    public inspectionModel: { id?: string } = null;
    public serialNumber: string;
    public location: LocationDto = null;

    // volatile
    public mileage: number = 0;
    public mileageDate: DateTime = null;

    constructor() {
        super();

        this.location = new LocationDto();
        this.inspectionModel = {};
    }

    public static fromBody(body: any, serverDto: ServerDto): EquipmentDto {
        let equipmentDto = new EquipmentDto();

        equipmentDto.trackerVersion = 1;
        equipmentDto.id = body.id;
        equipmentDto.latitude = Number(body.latitude);
        equipmentDto.longitude = Number(body.longitude);
        equipmentDto.title = body.wording.trim();
        equipmentDto.color = body.color;
        equipmentDto.serialNumber = body.serialNumber;

        equipmentDto.registrationNumber = body.registrationNumber;
        if (body.registration && isNullOrEmpty(equipmentDto.registrationNumber)) {
            equipmentDto.registrationNumber = body.registration.registration;
        }

        if (body.personalModel) {
            equipmentDto.doors = body.personalModel.doors;
            equipmentDto.seatings = body.personalModel.registrationS1;

            if (body.personalModel.energy) {
                equipmentDto.motorization = body.personalModel.energy.wording;
            }

            if (body.personalModel.category) {
                equipmentDto.category = body.personalModel.category;
            }

            if (body.personalModel.gaugeKind) {
                if (body.personalModel.gaugeKind.fraction) {
                    equipmentDto.maxFuel = body.personalModel.gaugeKind.fraction;
                } else {
                    equipmentDto.maxFuel = DEFAULT_MAX_TANK_CAPACITY;
                }
            } else {
                if (body.personalModel.energy) {
                    equipmentDto.maxFuel = DEFAULT_MAX_TANK_CAPACITY;
                } else {
                    equipmentDto.maxFuel = 0;
                }
            }

            if (body.personalModel.gaugeKind2) {
                if (body.personalModel.gaugeKind2.fraction) {
                    equipmentDto.maxFuel2 = body.personalModel.gaugeKind2.fraction;
                } else {
                    equipmentDto.maxFuel2 = DEFAULT_MAX_TANK_CAPACITY;
                }
            } else {
                if (body.personalModel.energy2) {
                    equipmentDto.maxFuel2 = DEFAULT_MAX_TANK_CAPACITY;
                } else {
                    equipmentDto.maxFuel2 = 0;
                }
            }
        }

        if (body.model) {
            equipmentDto.model = body.model.id;
        }

        if (body.inspectionModel) {
            equipmentDto.inspectionModel = body.inspectionModel;
        }

        if (body.location) {
            equipmentDto.location = LocationDto.fromBody(body.location);
        }

        equipmentDto.mileage = body.mileageValue;
        equipmentDto.mileageDate = DateHelper.tryFromFormat(body.mileageDate, DATE_NODEJS_FORMAT);

        return equipmentDto;
    }

    static fromModel(equipment: Equipment): EquipmentDto {
        let equipmentDto = new EquipmentDto();

        equipmentDto.id = equipment.id;
        equipmentDto.latitude = equipment.latitude;
        equipmentDto.longitude = equipment.longitude;
        equipmentDto.title = equipment.title;
        equipmentDto.doors = equipment.doors;
        equipmentDto.seatings = equipment.seatings;
        equipmentDto.motorization = equipment.motorization;
        equipmentDto.color = equipment.color;
        equipmentDto.category = equipment.category;
        equipmentDto.model = equipment.model;
        equipmentDto.trackerVersion = equipment.trackerVersion;
        equipmentDto.registrationNumber = equipment.registrationNumber;
        equipmentDto.serialNumber = equipment.serialNumber;

        equipmentDto.options = equipment.options;
        equipmentDto.description = equipment.description;
        equipmentDto.picture = equipment.picture;

        equipmentDto.mileage = equipment.mileage;
        equipmentDto.mileageDate = DateTime.fromISO(equipment.mileageDate);

        equipmentDto.maxFuel = equipment.maxFuel;
        equipmentDto.maxFuel2 = equipment.maxFuel2;

        equipmentDto.inspectionModel = equipment.inspectionModel;

        equipmentDto.location = LocationDto.fromModel(equipment.location);

        return equipmentDto;
    }

    toModel() {
        let equipment = new Equipment();

        equipment.id = this.id;
        equipment.latitude = this.latitude;
        equipment.longitude = this.longitude;
        equipment.title = this.title;
        equipment.doors = this.doors;
        equipment.seatings = this.seatings;
        equipment.motorization = this.motorization;
        equipment.color = this.color;
        equipment.category = this.category;
        equipment.model = this.model;
        equipment.trackerVersion = this.trackerVersion;
        equipment.registrationNumber = this.registrationNumber;
        equipment.serialNumber = this.serialNumber;

        equipment.options = this.options;
        equipment.description = this.description;
        equipment.picture = this.picture;

        equipment.inspectionModel = this.inspectionModel;

        equipment.mileage = this.mileage;
        equipment.mileageDate = DateHelper.tryToISO(this.mileageDate);

        equipment.maxFuel = this.maxFuel;
        equipment.maxFuel2 = this.maxFuel2;

        if (this.location) {
            equipment.location = this.location.toModel();
        }

        return equipment;
    }

    public toBody(): any {
        const clone: any = { ...this };

        // Nettoyer l'object avant envoi à lpservice
        delete clone.picture;
        delete clone.mileageDate;

        clone.location = this.location.toBody();

        return clone;
    }
}

