import { Injectable } from "@angular/core";
import { Logger } from "ionic-logging-service";
import { DbDaoBase } from "../../../gyzmo-commons/dao/db/base/db.dao.base";
import { AppSqlProvider } from "../../../gyzmo-commons/persistence/app.sql.provider";
import { CacheService } from "../../../gyzmo-commons/services/cache.service";
import { Equipment } from "../../models/equipment.model";
import { LocationDbDao } from "./location.db.dao";

@Injectable({
    providedIn: "root",
})
export class EquipmentDbDao extends DbDaoBase<Equipment> {

    constructor(logger: Logger,
                private sqlProvider: AppSqlProvider,
                private cacheService: CacheService,
                private locationDbDao: LocationDbDao) {
        super(logger);
    }

    public async createIndexes(): Promise<void> {
        let query = "CREATE INDEX IF NOT EXISTS idx_" + Equipment.TABLENAME + "_id"
                    + " ON " + Equipment.TABLENAME + "(id);";

        await this.sqlProvider.query(query)
            .catch(reason => {
                this.logSqlError(reason);
            });
    }

    public createTable(): Promise<void> {
        let query = "CREATE TABLE IF NOT EXISTS " + Equipment.TABLENAME
                    + " ("
                    + "id TEXT PRIMARY KEY,"
                    + "title TEXT, "
                    + "category TEXT, "
                    + "options TEXT, "
                    + "description TEXT, "
                    + "registrationNumber TEXT, "
                    + "doors NUMBER, "
                    + "seatings NUMBER, "
                    + "motorization TEXT, "
                    + "color TEXT, "
                    + "latitude NUMBER, "
                    + "longitude NUMBER,"
                    + "model TEXT, "
                    + "trackerVersion TEXT, "
                    + "mileage NUMBER,"
                    + "mileageDate TEXT,"
                    + "maxFuel NUMBER,"
                    + "maxFuel2 NUMBER,"
                    + "serialNumber TEXT,"
                    + "location TEXT"
                    + ");";

        return this.sqlProvider.query(query)
            .then(async () => {
                await this.createIndexes();
                return;
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    public delete(id: string): Promise<any> {
        let selectQuery = "DELETE FROM " + Equipment.TABLENAME + " WHERE id = '" + id + "';";
        return this.sqlProvider.query(selectQuery);
    }

    deleteAll(): Promise<any> {
        let selectQuery = "DELETE FROM " + Equipment.TABLENAME + ";";
        return this.sqlProvider.query(selectQuery);
    }

    public get(id: string, hydrate: boolean = false): Promise<Equipment> {
        let selectQuery = "SELECT * FROM " + Equipment.TABLENAME + " WHERE id = '" + id + "';";

        return this.sqlProvider.query(selectQuery)
            .then(
                data => {
                    if (data.rows.length <= 0) {
                        return null;
                    }

                    let equipment: Equipment = this.rowToModel(data.rows[0]);

                    let hydratationPromises = [];

                    if (hydrate) {
                        hydratationPromises.push(this.cacheService.getCached("equipment/" + equipment.id)
                            .then(async value => {
                                if (value) {
                                    equipment.picture = value.value;
                                } else {
                                    await this.cacheService.getCached("model/" + equipment.model)
                                        .then(value => {
                                            if (value) {
                                                equipment.picture = value.value;
                                            } else {
                                                equipment.picture = "";
                                            }
                                        });
                                }
                            }));
                    }

                    return Promise.all(hydratationPromises)
                        .then(() => {
                            return equipment;
                        });
                })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    public getTableName(): string {
        return Equipment.TABLENAME;
    }

    protected rowToModel(row: any): Equipment {
        let equipment = new Equipment();

        equipment.id = row.id;
        equipment.title = row.title;
        equipment.options = row.options;
        equipment.description = row.description;
        equipment.registrationNumber = row.registrationNumber;
        equipment.doors = row.doors;
        equipment.seatings = row.seatings;
        equipment.motorization = row.motorization;
        equipment.color = row.color;
        equipment.latitude = row.latitude;
        equipment.longitude = row.longitude;
        equipment.model = row.model;
        equipment.trackerVersion = row.trackerVersion;

        equipment.mileage = row.mileage;
        equipment.mileageDate = row.mileageDate;

        equipment.maxFuel = row.maxFuel;
        equipment.maxFuel2 = row.maxFuel2;
        equipment.serialNumber = row.serialNumber;

        equipment.category = JSON.parse(row.category);

        equipment.location.id = row.location;

        return equipment;
    }

    public save(equipment: Equipment): Promise<Equipment> {
        let promises = [];
        promises.push(this.locationDbDao.save(equipment.location));

        return Promise.all(promises)
            .then(value => {
                let query = "INSERT OR REPLACE INTO " + Equipment.TABLENAME + " (id, title, category, options, " +
                            "description, registrationNumber, doors, seatings, motorization, color, latitude, longitude, model, trackerVersion, mileage, " +
                            "mileageDate, maxFuel, maxFuel2, serialNumber, location) VALUES ("
                            + this.getValue(equipment.id)
                            + this.getValue(equipment.title)
                            + this.getValueAsJsonString(equipment.category)
                            + this.getValue(equipment.options)
                            + this.getValue(equipment.description)
                            + this.getValue(equipment.registrationNumber)
                            + this.getValue(equipment.doors)
                            + this.getValue(equipment.seatings)
                            + this.getValue(equipment.motorization)
                            + this.getValue(equipment.color)
                            + this.getValue(equipment.latitude)
                            + this.getValue(equipment.longitude)
                            + this.getValue(equipment.model)
                            + this.getValue(equipment.trackerVersion)
                            + this.getValue(equipment.mileage)
                            + this.getValue(equipment.mileageDate)
                            + this.getValue(equipment.maxFuel)
                            + this.getValue(equipment.maxFuel2)
                            + this.getValue(equipment.serialNumber)
                            + this.getFkValue(equipment.location, true)
                            + ");";

                return this.sqlProvider.query(query)
                    .then(response => {
                        return equipment;
                    })
                    .catch(reason => {
                        this.logSqlError(reason);
                        return null;
                    });
            });
    }
}
