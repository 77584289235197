export enum DoorsStatus {
    Locked,
    Unlocked,
    Unknown,
}

export enum FuelLevelUnit {
    Liters,
    Percentage,
    Unknown,
}

export class OpenFleetEquipmentInformationDao {
    public version: number;
    public antiStartupState: boolean = false;
    public keysLocked: boolean = false;
    public latitude: string;
    public license_plate: string;
    public longitude: string;

    public doorsStatus: DoorsStatus;
    public fuelLevel: number;
    public fuelLevelUnit: FuelLevelUnit;
    public mileage: number;

    public static fromBody(body: any): OpenFleetEquipmentInformationDao {
        let openFleetEquipmentInformationDao = new OpenFleetEquipmentInformationDao();

        if (body.box_type) {
            switch (body.box_type.toLowerCase()) {
                case "noden":
                    openFleetEquipmentInformationDao.version = 2;
                    break;
                case "logicio":
                    openFleetEquipmentInformationDao.version = 1;
                    break;
            }
        }

        openFleetEquipmentInformationDao.antiStartupState = body.immobilizer;
        openFleetEquipmentInformationDao.keysLocked = body.box_fixkey;
        openFleetEquipmentInformationDao.latitude = "" + body.latitude;
        openFleetEquipmentInformationDao.longitude = "" + body.longitude;
        openFleetEquipmentInformationDao.license_plate = body.license_plate;

        // V2
        openFleetEquipmentInformationDao.doorsStatus = this.getDoorsStatus(body.box_doors_status);
        openFleetEquipmentInformationDao.fuelLevel = body.box_fuel_level;
        openFleetEquipmentInformationDao.fuelLevelUnit = this.getFuelLevelUnit(body.box_fuel_level_unit);
        openFleetEquipmentInformationDao.mileage = Math.floor(Number(body.box_meterage) / 1000);

        return openFleetEquipmentInformationDao;
    }

    private static getDoorsStatus(box_doors_status: string): DoorsStatus {
        switch (box_doors_status) {
            case "locked" :
                return DoorsStatus.Locked;
            case "unlocked":
                return DoorsStatus.Unlocked;
            default:
                return DoorsStatus.Unknown;
        }
    }

    private static getFuelLevelUnit(box_fuel_level_unit: string): FuelLevelUnit {
        switch (box_fuel_level_unit) {
            case "litre":
                return FuelLevelUnit.Liters;
            case "percent" :
                return FuelLevelUnit.Percentage;
            default:
                return FuelLevelUnit.Unknown;
        }
    }
}
