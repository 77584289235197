import { IonicConfig } from "@ionic/core";

export const API_VERSION: number = 1;
export const MODULE: string = "10007";

export const DEFAULT_COMPANY: string = "GYZMO";
export const DEFAULT_LOCATION: string = "GYZMO01";

export const DEFAULT_USER_LEVEL: string = "9";
export const DEFAULT_PROFILE: string = "AUTRES";

export const REFRESHER_MAX: number = 60 * 1000;

export const LIMIT_SEARCH_TO_X_DAYS: number = 14;

export const MAIL_PHONE_REGEX = /^\+33[1-9]\d{8}$|^\d{10}$/;

export const SHOW_DEBUG_INFO: boolean = false;

export const DEFAULT_MAX_TANK_CAPACITY: number = 8;

export const GENERAL_PHOTOS = true;

export const MAX_RENT_DURATION = 6;

export const TESTS_ROOT_URL = "http://localhost:4201";

export const APP_OPTIONS: IonicConfig =
    {
        // mode: 'ios', // Pour forcer en mode ios
        scrollPadding: false,
        scrollAssist: false,
    };
