import { Injectable } from "@angular/core";
import { FileOpener } from "@awesome-cordova-plugins/file-opener/ngx";
import { File } from "@awesome-cordova-plugins/file/ngx";
import { PhotoViewer } from "@awesome-cordova-plugins/photo-viewer/ngx";
import { Logger } from "ionic-logging-service";
import { DeviceHelper } from "../../gyzmo-commons/helpers/device.helper";
import { LoadingHelper } from "../../gyzmo-commons/helpers/loading.helper";

declare var window;

@Injectable({
    providedIn: "root",
})
export class FileService {
    constructor(private logger: Logger,
                private file: File,
                private opener: FileOpener,
                private loadingHelper: LoadingHelper,
                private deviceHelper: DeviceHelper,
                private photoViewer: PhotoViewer) {
    }

    private static convertBase64ToBlob(b64Data, contentType): Blob {
        contentType = contentType || "";
        const sliceSize = 512;
        b64Data = b64Data.replace(/^[^,]+,/, "");
        b64Data = b64Data.replace(/\s/g, "");
        const byteCharacters = window.atob(b64Data);
        const byteArrays = [];
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        return new Blob(byteArrays, { type: contentType });
    }

    public initialiseFileSystem() {
        this.logger.entry("initialiseFileSystem");
    }

    public async saveAndOpenPdf(pdf: string, filename: string) {
        if (this.deviceHelper.isRunningOnDevice()) {
            await this.loadingHelper.showLoading();

            let fileResult = await this.file.writeFile(this.file.dataDirectory, filename, FileService.convertBase64ToBlob(pdf, "data:application/pdf;base64"), { replace: true })
                .catch((reason) => {
                    this.logger.error(this.constructor.name, "Error writing pdf file (" + JSON.stringify(reason) + ")");
                });

            await this.opener.open(fileResult.nativeURL, "application/pdf")
                .catch((reason) => {
                    this.logger.error(this.constructor.name, "Error opening pdf file (" + JSON.stringify(reason) + ")");
                });

            await this.loadingHelper.hideAll();
        } else {
            let newWindow = window.open("");
            newWindow.document.write("<body style='margin:0;overflow:hidden'><iframe width='100%' height='100%' src='" + pdf + "'></iframe></body>");
            newWindow.document.close();
        }
    }

    public async saveAndOpenImage(imageData: string, filename: string) {
        if (this.deviceHelper.isRunningOnDevice()) {
            await this.loadingHelper.showLoading();

            let fileResult = await this.file.writeFile(this.file.dataDirectory, filename, FileService.convertBase64ToBlob(imageData, imageData.slice(0, imageData.indexOf(","))), { replace: true })
                .catch((reason) => {
                    this.logger.error(this.constructor.name, "Error writing image file (" + JSON.stringify(reason) + ")");
                });

            const options = {
                share: false, // default is false
                closeButton: true, // default is true
                copyToReference: false, // default is false
            };

            await this.loadingHelper.hideAll();
            this.photoViewer.show(fileResult.nativeURL, "", options);
        } else {
            let newWindow = window.open("");
            newWindow.document.write("<body style='height: 100%; width: 100%'><img src='" + imageData + "' style='max-height: 100%; max-width: 100%' /></body>");
            newWindow.document.close();
        }
    }
}
