import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { IonicModule } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";

import { MapComponent } from "./map";

@NgModule(
    {
        declarations: [
            MapComponent,
        ],
        imports: [
            CommonModule,
            IonicModule,
            TranslateModule,
        ],
        exports: [
            MapComponent,
        ],
        entryComponents: [
            MapComponent,
        ],
        providers: [],
    })

export class MapModule {
}
