import { Injectable } from "@angular/core";
import { Logger } from "ionic-logging-service";
import { DbDaoBase } from "../../../gyzmo-commons/dao/db/base/db.dao.base";
import { AppSqlProvider } from "../../../gyzmo-commons/persistence/app.sql.provider";
import { Movement } from "../../models/movement.model";
import { EquipmentDbDao } from "./equipment.db.dao";

@Injectable({
    providedIn: "root",
})
export class MovementDbDao extends DbDaoBase<Movement> {

    constructor(logger: Logger,
                private sqlProvider: AppSqlProvider,
                private equipmentDbDao: EquipmentDbDao) {
        super(logger);
    }

    public async createIndexes(): Promise<void> {
        let query = "CREATE INDEX IF NOT EXISTS idx_" + Movement.TABLENAME + "_id"
                    + " ON " + Movement.TABLENAME + "(id);";

        await this.sqlProvider.query(query)
            .catch(reason => {
                this.logSqlError(reason);
            });
    }

    public createTable(): Promise<void> {
        let query = "CREATE TABLE IF NOT EXISTS " + Movement.TABLENAME
                    + " ("
                    + "id TEXT PRIMARY KEY,"
                    + "documentNumber TEXT, "
                    + "startDate DATE, "
                    + "startFuel NUMBER, "
                    + "startFuel2 NUMBER, "
                    + "startMileage NUMBER, "
                    + "returnDate DATE, "
                    + "returnFuel NUMBER, "
                    + "returnFuel2 NUMBER, "
                    + "returnMileage NUMBER, "
                    + "equipment TEXT"
                    + ");";

        return this.sqlProvider.query(query)
            .then(async () => {
                await this.createIndexes();
                return;
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    public delete(id: string): Promise<any> {
        let selectQuery = "DELETE FROM " + Movement.TABLENAME + " WHERE id = '" + id + "';";
        return this.sqlProvider.query(selectQuery);
    }

    deleteAll(): Promise<any> {
        let selectQuery = "DELETE FROM " + Movement.TABLENAME + ";";
        return this.sqlProvider.query(selectQuery);
    }

    public get(id: string, hydrate: boolean = false): Promise<Movement> {
        let selectQuery = "SELECT * FROM " + Movement.TABLENAME + " WHERE id = '" + id + "';";

        return this.sqlProvider.query(selectQuery)
            .then(
                data => {
                    if (data.rows.length <= 0) {
                        return null;
                    }

                    let movement: Movement = this.rowToModel(data.rows[0]);

                    let hydratationPromises = [];

                    if (hydrate) {
                        hydratationPromises.push(this.equipmentDbDao.get(movement.equipment.id, hydrate)
                            .then(value => {
                                movement.equipment = value;
                            }));
                    }

                    return Promise.all(hydratationPromises)
                        .then(() => {
                            return movement;
                        });
                })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    public getTableName(): string {
        return Movement.TABLENAME;
    }

    protected rowToModel(row: any): Movement {
        let movement = new Movement();

        movement.id = row.id;
        movement.documentNumber = row.documentNumber;
        movement.startDate = row.startDate;
        movement.startFuel = row.startFuel;
        movement.startFuel2 = row.startFuel2;
        movement.startMileage = row.startMileage;
        movement.returnDate = row.returnDate;
        movement.returnFuel = row.returnFuel;
        movement.returnFuel2 = row.returnFuel2;
        movement.returnMileage = row.returnMileage;

        movement.equipment.id = row.equipment;

        return movement;
    }

    public save(movement: Movement): Promise<Movement> {
        let promises = [];
        promises.push(this.equipmentDbDao.save(movement.equipment));

        return Promise.all(promises)
            .then(value => {
                let query = "INSERT OR REPLACE INTO " + Movement.TABLENAME + " (id, documentNumber, startDate, startFuel, startFuel2, startMileage, "
                            + "returnDate, returnFuel, returnFuel2, returnMileage, equipment) VALUES ("
                            + this.getValue(movement.id)
                            + this.getValue(movement.documentNumber)
                            + this.getValue(movement.startDate)
                            + this.getValue(movement.startFuel)
                            + this.getValue(movement.startFuel2)
                            + this.getValue(movement.startMileage)
                            + this.getValue(movement.returnDate)
                            + this.getValue(movement.returnFuel)
                            + this.getValue(movement.returnFuel2)
                            + this.getValue(movement.returnMileage)
                            + this.getFkValue(movement.equipment, true)
                            + ");";

                return this.sqlProvider.query(query)
                    .then(response => {
                        return movement;
                    })
                    .catch(reason => {
                        this.logSqlError(reason);
                        return null;
                    });
            });
    }
}
