import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";
import { GtagModule } from "../../../../../gyzmo-commons/gtag/gtag.module";
import { PipesModule } from "../../../../../gyzmo-commons/helpers/pipes/pipes.module";
import { ViewDamagesModal } from "./view-damages.modal";

@NgModule(
    {
        declarations: [
            ViewDamagesModal,
        ],
        imports: [
            CommonModule,
            FormsModule,
            IonicModule,
            TranslateModule,
            PipesModule,
            GtagModule,
        ],
        entryComponents: [
            ViewDamagesModal,
        ],
        providers: [],
    })

export class ViewDamageModalModuleV2 {
}
