import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";
import { AttachmentService } from "../../../../../core/services/attachment.service";
import { GyzmoButtonModule } from "../../../../../gyzmo-commons/components/gyzmo-button/gyzmo-button.module";
import { GtagModule } from "../../../../../gyzmo-commons/gtag/gtag.module";
import { CreateDamageModal } from "./create-damage.modal";

@NgModule(
    {
        declarations: [
            CreateDamageModal,
        ],
        imports: [
            CommonModule,
            FormsModule,
            IonicModule,
            TranslateModule,
            GyzmoButtonModule,
            GtagModule,
        ],
        entryComponents: [
            CreateDamageModal,
        ],
        providers: [
            AttachmentService,
        ],
    })

export class CreateDamageModalModuleV2 {
}
