import { NotImplementedError } from "../../gyzmo-commons/helpers/NotImplementedError";
import { BaseDto } from "./base/base.dto";
import { BestDealDto } from "./bestDeal.dto";
import { EquipmentDto } from "./equipment.dto";
import { ServerDto } from "./server.dto";

export class AvailabilityDto extends BaseDto<AvailabilityDto> {
    public equipment: EquipmentDto = null;
    public bestDeal: BestDealDto = null;
    public distance: number = 0;
    public category: any = {};

    constructor() {
        super();
        this.equipment = new EquipmentDto();
        this.bestDeal = new BestDealDto();
    }

    public static fromBody(body: any, serverDto: ServerDto): AvailabilityDto {
        let availabilityDto = new AvailabilityDto();

        if (body.equipment) {
            availabilityDto.equipment = EquipmentDto.fromBody(body.equipment, serverDto);
        }
        if (body.bestDeal) {
            availabilityDto.bestDeal = BestDealDto.fromBody(body.bestDeal, serverDto);
        }

        availabilityDto.distance = body.distance;

        if (body.category) {
            availabilityDto.category = { id: BaseDto.getId(body.category) };
        }

        return availabilityDto;
    }

    public toModel(): AvailabilityDto {
        return undefined;
    }

    public toBody(): any {
        throw new NotImplementedError();
    }
}

