import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { IonicModule } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";
import { PipesModule } from "../../../gyzmo-commons/helpers/pipes/pipes.module";
import { VehicleDetailsEquipmentsModule } from "../vehicle-details-equipments/vehicle-details-equipments.module";
import { StcInfoComponent } from "./stc-info";

@NgModule(
    {
        declarations: [
            StcInfoComponent,
        ],
        imports: [
            CommonModule,
            IonicModule,
            TranslateModule,
            PipesModule,
            VehicleDetailsEquipmentsModule,
        ],
        exports: [
            StcInfoComponent,
        ],
        entryComponents: [
            StcInfoComponent,
        ],
        providers: [],
    })
export class StcInfoModule {
}
